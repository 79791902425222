import React from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

const NavItemNoMenu = (props) => {
  return (
    <li>
      <NavLink
        to={props.path}
        className={useLocation().pathname == props.path ? "activeLink" : ""}
      >
        <button className="annuaire__button">{props.label}</button>
      </NavLink>
    </li>
  );
};

NavItemNoMenu.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
};

export default NavItemNoMenu;

import React, { useEffect, useState } from "react";
import "./ChargementImage.scss";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import Loader from "Components/Loader/loader";
import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { useFetchGet } from "Services/api";
import { showError, showSuccess, shuffleArray } from "Services/functions";
import { connect } from "react-redux";

const ChargementImage = (props) => {
  // ------- VARIABLES CHARGEMENT IMAGES ----------
  // Récupération des images par défaut du serveur (en utilisant le path custom si il existe)
  var imageStocksData = useFetchGet("/image_stocks/" + props.path);
  // Index de l'image choisie de la photothèque
  const [activeIndex, setActiveIndex] = useState(0);
  // La photothèque doit-elle être affichée ?
  const [visiblePhototheque, setVisiblePhototheque] = useState(false);
  // Responsive de la photothèque
  const responsiveOptions = [
    {
      breakpoint: "768px",
      numVisible: 5,
    },
    {
      breakpoint: "560px",
      numVisible: 2,
    },
  ];

  // Options pour boutons upload / annuler d'upload d'image
  const uploadOptions = {
    label: " ",
    icon: "pi pi-check",
    className: props.imageFile ? "is_uploaded " : "bouton_valider",
  };
  const cancelOptions = {
    label: " ",
    icon: "pi pi-times",
    className: "bouton_supprimer",
  };

  // Une fois les images de la photothèque chargées, on les mélange aléatoirement:
  const [isShuffled, setIsShuffled] = useState(false);
  useEffect(() => {
    if (imageStocksData.loaded) {
      imageStocksData.data = shuffleArray(imageStocksData.data);
      props.setImageId(imageStocksData?.data[activeIndex]?.id);
      setIsShuffled(true);
    }
  }, [imageStocksData.loaded]);

  /**
   * Fonction s'occuppant de l'upload d'une image
   * @param {Event} event
   * @param {Object} event[].files Liste des fichiers sélectionnés
   */
  const uploadHandler = ({ files }) => {
    const file = files[0];
    const fileReader = new FileReader();

    if (!file.type.match("image.*")) {
      showError("Le fichier sélectionné n'est pas une image");
      return;
    }
    // Ajout évènement : dès que le fichier est chargé, on l'affiche dans le formulaire
    fileReader.onload = () => {
      props.setImageFile(file);
      props.setImage(file.objectURL);
      showSuccess("Le fichier " + file.name + " a bien été téléchargé");
      props.setPhototheque(false);
    };
    // Démarrage du chargement du fichier
    fileReader.readAsDataURL(file);
  };

  /**
   * Permet d'afficher du JSX pour chaque image de la photothèque (cover)
   * @param {Object} item Object contenant une image de la photothèque
   * @returns {JSX.Element} image en cover
   */
  const imageTemplate = (item) => {
    return (
      <img
        src={item.resourceUrl}
        alt={item.filename}
        className={props.long ? "cover-long" : "cover-standard"}
      />
    );
  };

  /**
   * Permet d'afficher du JSX pour chaque image de la photothèque (miniature)
   * @param {Object} item Object contenant une image de la photothèque
   * @returns {JSX.Element} image en miniature
   */
  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.resourceUrl}
        alt={item.filename}
        className={props.long ? "thumbnail-long" : "thumbnail-standard"}
      />
    );
  };

  return (
    <div className="chargement_image">
      <h2>{props.label}</h2>
      {props?.image && props.imageFile && (
        <Image
          src={props.image}
          alt="Image de couverture"
          className={
            props.long
              ? "chargement_image-image-long"
              : "chargement_image-image-standard"
          }
        />
      )}
      {props.currentImage && !props.imageFile && !props.phototheque ? (
        <Image
          src={props.currentImage}
          alt="Image de couverture"
          className={
            props.long
              ? "chargement_image-image-long"
              : "chargement_image-image-standard"
          }
        />
      ) : (
        <>
          {!props.imageFile &&
            isShuffled &&
            imageStocksData.data.length > 0 && (
              <Image
                src={imageStocksData.data.at(activeIndex).resourceUrl}
                alt="Image de couverture"
                className={
                  props.long
                    ? "chargement_image-image-long"
                    : "chargement_image-image-standard"
                }
              />
            )}
        </>
      )}
      <div className="chargement_image__container">
        <div className="chargement_image__container__item">
          <FileUpload
            name={props.image}
            customUpload={true}
            uploadHandler={uploadHandler}
            chooseLabel="Modifier l'image depuis mon PC"
            onClear={() => {
              props.setImage(null);
              props.setImageFile(null);
              props.currentImage
                ? props.setPhototheque(false)
                : props.setPhototheque(true);

              showError("Le fichier a bien été supprimé", "Suppression : ");
            }}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            accept="image/*"
            maxFileSize={1000000}
          ></FileUpload>
        </div>
        <div className="chargement_image__container__item">
          <Button
            name={props.image}
            id={
              props.currentImage
                ? "chargement_image__container__item-button"
                : "chargement_image__container__item-button_last"
            }
            label="Modifier l'image depuis la photothèque"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setVisiblePhototheque(true);
              props.setPhototheque(true);
            }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          ></Button>
        </div>
      </div>
      <div className="chargement_image__phototheque">
        <div className="chargement_image__phototheque__container">
          <Dialog
            appendTo={document.getElementById("app")}
            header="Photothèque - Choisissez une image et fermer la fenêtre pour valider votre choix"
            visible={visiblePhototheque}
            onHide={() => setVisiblePhototheque(false)}
            className="chargement_image__phototheque-card"
          >
            {imageStocksData.loaded && imageStocksData.data.length > 0 ? (
              <Galleria
                value={imageStocksData.data}
                item={imageTemplate}
                activeIndex={activeIndex}
                thumbnail={thumbnailTemplate}
                className="phototheque_dialog-galeria"
                numVisible={5}
                responsiveOptions={responsiveOptions}
                onItemChange={(e) => {
                  props.setImageId(imageStocksData.data[e.index].id);
                  setActiveIndex(e.index);
                  props.setImageFile(null);
                  props.setPhototheque(true);
                }}
              ></Galleria>
            ) : (
              <Loader />
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
};
ChargementImage.propTypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  setImage: PropTypes.func,
  setImageFile: PropTypes.func,
  setPhototheque: PropTypes.func,
  phototheque: PropTypes.bool,
  imageFile: PropTypes.any,
  setImageId: PropTypes.func,
  path: PropTypes.string,
  long: PropTypes.bool,
  auth: PropTypes.object,
  currentImage: PropTypes.string,
};

ChargementImage.defaultProps = {
  label: "Image de couverture",
  long: false,
  currentImage: null,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ChargementImage);

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Evenement.scss";
import event1 from "assets/event1.webp";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaEllipsisH, FaUsers } from "react-icons/fa";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { formatDate } from "Services/functions";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import SizedImage from "Components/SizedImage/SizedImage";

const API_LINK = `${process.env.REACT_APP_BASE_URL_API}`.replace("/api", "");

const Evenement = (props) => {
  const [cardImage] = useState(props.url == "event1" ? event1 : props.url);
  const menu = useRef(null);
  const items = [
    { label: "Modifier" },
    { separator: true },
    { label: "Supprimer" },
  ];
  return (
    <div
      className="evenement_container cardHoverEvenement"
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="evenement_container__top">
        <div className="evenement_container__top__categorie">
          <span className="etiquette"> {props.categorie?.label} </span>
        </div>
        <div className="evenement_container__image">
          <SizedImage
            image={cardImage}
            alt="Fond event"
            defaultImage={event1}
            height="100%"
          />
        </div>
      </div>
      <div className="evenement_container__corps">
        <div className="evenement_container__corps__debutdate">
          Du : {props.debutDate}
          {props.groupe && (
            <>
              <Menu
                model={items}
                popup
                ref={menu}
                style={{ textAlign: "center" }}
              />
              <div className="btn-point">
                <BtnBlanc
                  btnAction={() => (event) => menu.current.toggle(event)}
                >
                  <FaEllipsisH />
                </BtnBlanc>
              </div>
            </>
          )}
        </div>
        <div className="evenement_container__corps__findate">
          Au : {props.finDate}
        </div>
        {props.groupe ? (
          <div className="event_groupe">
            <FaUsers /> {props.groupe_name}
          </div>
        ) : (
          <div className="evenement_container__corps__inscription">
            {props.inscription
              ? "Complet"
              : `Inscriptions ouvertes ${
                  props?.registerEnd != undefined
                    ? `jusqu'au ${formatDate(props.registerEnd)}`
                    : ""
                }`}
          </div>
        )}

        <div className="evenement_container__corps__title" title={props.title}>
          {props.title && props.title}
        </div>
        <div
          className="evenement_container__corps__chapeau"
          title={props.chapeau}
        >
          <span>
            {props.chapeau && parse(props?.chapeau, HTMLParseOptions)}
          </span>
        </div>
      </div>
      <div className="evenement_container__bottom">
        <div className="evenement_container__bottom__lien">En savoir plus</div>
        <div className="evenement_container__bottom__logo">
          {props.eventDetails?.university?.imageTransparentAndColorUrl && (
            <img
              src={
                API_LINK +
                props.eventDetails.university.imageTransparentAndColorUrl
              }
              alt={"Logo Université"}
              title={"Logo Université"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Evenement.propTypes = {
  categorie: PropTypes.object,
  debutDate: PropTypes.string,
  finDate: PropTypes.string,
  title: PropTypes.string,
  inscription: PropTypes.bool,
  chapeau: PropTypes.any,
  logo: PropTypes.any,
  image: PropTypes.any,
  groupe: PropTypes.bool,
  groupe_name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  eventDetails: PropTypes.object,
  registerEnd: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Evenement);

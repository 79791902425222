import React from "react";
import "./loader.scss";
// import { ProgressSpinner } from "primereact/progressspinner";

const Loader = () => {
  return (
    <div className="loaderContainer">
      <span className="loaderContainer__loader"></span>
      {/* <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        className="loaderContainer__loader"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      /> */}
    </div>
  );
};

export default Loader;

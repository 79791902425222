import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Actualite.scss";
import event2 from "assets/event2.webp";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import SizedImage from "Components/SizedImage/SizedImage";

const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];
/**
 * Permet d'afficher la card d'une actualité
 * Elle est affichée dans les pages actus et detail-actu
 */
const Actualite = (props) => {
  const [cardImage] = useState(props.url == "event1" ? event2 : props.url);

  return (
    <div
      className="actualite_container cardHoverActualite"
      style={{ minHeight: props?.random && "95.5%" }}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="actualite_container__top__categorie">
        <span className="etiquette"> {props.categorie} </span>
      </div>
      <div className="actualite_container__image">
        <SizedImage
          image={cardImage}
          alt="Fond news"
          defaultImage={event2}
          height="100%"
        />
      </div>
      <div className="actualite_container__corps">
        <div className="actualite_container__corps__debutdate">
          {props.publicationDate}
        </div>
        <div className="actualite_container__corps__title" title={props.title}>
          {props.title && parse(props.title, 60)}
        </div>
        <div
          className="actualite_container__corps__chapeau"
          title={props.chapeau}
        >
          <span>{props.chapeau && parse(props.chapeau, HTMLParseOptions)}</span>
        </div>
      </div>
      <div className="actualite_container__bottom">
        <div className="actualite_container__bottom__lien">Lire la suite</div>
        <div className="actualite_container__bottom__logo">
          {props.newsDetails?.university?.imageTransparentAndColorUrl && (
            <img
              src={
                API_LINK +
                props.newsDetails.university.imageTransparentAndColorUrl
              }
              alt={"Logo Université"}
              title={"Logo Université"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Actualite.propTypes = {
  categorie: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  chapeau: PropTypes.any,
  lien: PropTypes.string,
  logo: PropTypes.string,
  publicationDate: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  newsDetails: PropTypes.object,
  random: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actualite);

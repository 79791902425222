import Loader from "Components/Loader/loader";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchPost } from "Services/api";
import {
  displayCompanyImage,
  formatContrat,
  formatDate,
  showError,
  showSuccess,
  truncate,
} from "Services/functions";
import offre1 from "assets/offre1.webp";
import recruteurs from "assets/recruteurs.png";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  FaBookmark,
  FaMapMarkerAlt,
  FaRegBookmark,
  FaSuitcase,
  FaUniversity,
  FaUsers,
  FaWheelchair,
} from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./Offre.scss";
import SizedImage from "Components/SizedImage/SizedImage";

const Offre = (props) => {
  var bookmark = props.auth.userConnected?.savedOffers?.some(
    (offer) => offer.id === props.offreDetails.id
  );
  const [isLoader, setIsLoader] = useState(false);
  const addOfferToSaveList = async () => {
    setIsLoader(true);
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };

    const response = await fetchPost(
      `/offers/${props.offreDetails.id}/save`,
      data
    );
    setIsLoader(false);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }
    let user = props.auth.userConnected;
    user.savedOffers = user.savedOffers.concat(response.data);
    props.handleAuth({ userConnected: user });
    showSuccess("L'offre a bien été ajoutée aux offres sauvegardées");
  };
  const removeOfferFromSaveList = async () => {
    setIsLoader(true);
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };

    const response = await fetchPost(
      `/offers/${props.offreDetails.id}/erase`,
      data
    );
    setIsLoader(false);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }
    let user = props.auth.userConnected;
    (user.savedOffers = user.savedOffers.filter(
      (saved) => saved.id !== response.data.id
    )),
      props.handleAuth({ userConnected: user });
    showError("L'offre a bien été retirée des offres sauvegardées");
  };

  return (
    <div
      className={
        useLocation().pathname === "/" ||
        useLocation().pathname === "/profil" ||
        useLocation().pathname === "/offres" ||
        useLocation().pathname === "/depot-offre" ||
        useLocation().pathname === "/gestion-offre"
          ? "offre cardHover"
          : "offre"
      }
      onClick={() => props.onClick && props.onClick()}
    >
      <Link
        style={{
          pointerEvents: props.offreDetails.id ? "auto" : "none",
        }}
        to={{
          pathname: props.auth.isConnected
            ? `/offre-detail/${props.offreDetails.id}`
            : "/",
          params: props.offreDetails.id ? props.offreDetails.id : "",
        }}
      >
        <div className="offre__image">
          <div className="offre__image__header">
            <SizedImage
              image={props?.url}
              alt="Fond offre"
              defaultImage={offre1}
              height="100%"
            />
          </div>

          <div className="offre__image__logo">
            <img
              src={
                displayCompanyImage(props.offreDetails.company) || recruteurs
              }
              alt="Logo entreprise"
              title="Logo entreprise"
            />
          </div>
        </div>
      </Link>

      <Link
        style={{
          pointerEvents: props.offreDetails.id ? "auto" : "none",
        }}
        to={{
          pathname: props.auth.isConnected
            ? `/offre-detail/${props.offreDetails.id}`
            : "/",
          params: props.offreDetails.id ? props.offreDetails.id : "",
        }}
      >
        <div className="offre__container">
          <div className="offre__container__description">
            <div
              className="offre__container__description__title"
              title={props.offreDetails?.title}
            >
              <span>{props.offreDetails?.title}</span>
            </div>
            <div className="offre__container__description__statut">
              <FaSuitcase />
              {props.offreDetails.typeOfContract ? (
                <span>
                  {formatContrat(props.offreDetails.typeOfContract.label)}
                </span>
              ) : (
                <span>{props.offreDetails.offerCategory?.label}</span>
              )}
            </div>
            <div className="offre__container__description__statut">
              <FaMapMarkerAlt />
              <span>
                {props.offreDetails.city} (
                {props.offreDetails.country &&
                  truncate(props.offreDetails.country, 3)}
                )
              </span>
            </div>
            <div className="offre__container__description__statut">
              <FaUsers />
              {props.employes ? (
                <span>{props.employes} salariés</span>
              ) : (
                <span>Non renseigné</span>
              )}
            </div>
            <div className="offre__container__description__statut">
              <FaUniversity />
              {props.offreDetails?.university?.name ? (
                <span>{props.offreDetails.university.name}</span>
              ) : (
                <span>Non renseigné</span>
              )}
            </div>
            <div className="offre__container__description__handicap">
              {props.accessibleForDisabled && (
                <div className="offre__container__description__handicap__icon">
                  <FaWheelchair />
                  <span>Handi-accueillant</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="offre__date">
        {formatDate(props.offreDetails.createdAt, ".")}{" "}
        {!isLoader ? (
          bookmark ? (
            <div className="tooltip">
              <FaBookmark
                color="#4190ee"
                onClick={() => {
                  !props.disableBookmark && removeOfferFromSaveList();
                }}
              />
            </div>
          ) : (
            <div className="tooltip">
              <FaRegBookmark
                color="#4190ee"
                onClick={() => {
                  !props.disableBookmark && addOfferToSaveList();
                }}
              />
            </div>
          )
        ) : (
          <Loader></Loader>
        )}
      </div>
    </div>
  );
};

Offre.propTypes = {
  date: PropTypes.string,
  bookmark: PropTypes.bool,
  offreDetails: PropTypes.any,
  test: PropTypes.string,
  image: PropTypes.number,
  categorie: PropTypes.number,
  logoEntreprise: PropTypes.any,
  employes: PropTypes.string,
  accessibleForDisabled: PropTypes.any,
  url: PropTypes.string,
  onClick: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  offers: PropTypes.object,
  companies: PropTypes.object,
  companyUrl: PropTypes.string,
  disableBookmark: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  offers: state.offers,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Offre);

import { UPDATE_NEWS } from "Redux/Actions/newsActions";

const INITIAL_STATE = {
  // motsClés: "",
  // date: "",
  // category: [],
  // newsData: [],
  // newsFiltered: [],
  // isSearched: false,
  etats: [],
  randomNews: [],
  dataFromPagination: {
    data: [],
    loaded: false,
    page: 1,
    totalItems: 0,
  },
};

const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NEWS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default newsReducer;

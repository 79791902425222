import React from "react";
import PropTypes from "prop-types";
import Reseauprologo from "assets/Reseauprologo.webp";
import { connect } from "react-redux";
import { SlideMenu } from "primereact/slidemenu";
import { updateAuth } from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";

const NavBarMobile = (props) => {
  const labels = {
    accueil: "Accueil",
    evenements: "Événements",
    actus: "Actus",
    offres: "Offres d'emploi",
    cvtheque: "Cvthèque",
    university: "Nos universités",
  };

  const items = [
    {
      label: labels.accueil,
      command: () => (window.location.href = "/"),
    },
    { separator: true },
    {
      label: labels.evenements,
      items: [
        {
          label: "Tous les événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Tous les événements"
              ),
            });
          },
        },
        {
          label: "Proposer un événement",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Proposer un événement"
              ),
            });
          },
        },
        {
          label: "Gérer mes événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Gérer mes événements"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: labels.actus,
      items: [
        {
          label: "Toutes les actus",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Toutes les actus"
              ),
            });
          },
        },
        {
          label: "Proposer une actu",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Proposer une actu"
              ),
            });
          },
        },
        {
          label: "Gérer mes actus",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Gérer mes actus"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: labels.offres,
      items: [
        {
          label: "Déposer une offre",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Déposer une offre"
              ),
            });
          },
        },
        {
          label: "Gérer mes offres",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Gérer mes offres"
              ),
            });
          },
        },
        {
          label: "Candidatures reçues",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Candidatures reçues"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: labels.cvtheque,
      items: [
        {
          label: "Cvthèque",
          command: () => {
            if (window.location.pathname != "/cvtheque") {
              window.location.pathname = "/cvtheque";
            }
            props.handleMenu({
              activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                (v) => v.label == "Cvthèque"
              ),
            });
          },
        },
        {
          label: "Cv sauvegardés",
          command: () => {
            if (window.location.pathname != "/cvtheque") {
              window.location.pathname = "/cvtheque";
            }
            props.handleMenu({
              activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                (v) => v.label == "Cv sauvegardés"
              ),
            });
          },
        },
        {
          label: "Paniers de cv",
          command: () => {
            if (window.location.pathname != "/cvtheque") {
              window.location.pathname = "/cvtheque";
            }
            props.handleMenu({
              activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                (v) => v.label == "Paniers de cv"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: labels.university,
      command: () => (window.location.href = "/universites"),
    },
    { separator: true },
    {
      label: "Déconnexion",
      command: () => {
        (window.location.pathname = "/"),
          props.handleAuth({
            isConnected: !props.auth.isConnected,
          });
      },
    },
    { separator: true },
    {
      image: { Reseauprologo },
      className: "logoRP_burger",
    },
  ];

  return (
    <div>
      <SlideMenu
        model={items}
        viewportHeight={220}
        menuWidth={175}
        popup
        ref={props.menu}
        style={{ textAlign: "start", left: "0px", width: "200px" }}
      />
    </div>
  );
};

NavBarMobile.propTypes = {
  menu: PropTypes.any,
  handleMenu: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarMobile);

import React from "react";
import "./Footer.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { Link } from "react-router-dom";
import unif from "assets/logo_unif.webp";
import reseau_pro_blanc from "assets/reseau_pro_blanc.png";
import { FaRegCopyright } from "react-icons/fa";
import logo_ministere from "assets/logo_ministere.jpg";
import logo_service_public from "assets/logo_service_public.webp";

const Footer = (props) => {
  return (
    <div className="footer">
      {props.auth.isConnected ? (
        <div className="footer__top">
          <div className="footer__top__li">
            <img
              src={reseau_pro_blanc}
              alt="Logo Réseau Pro"
              title="Logo Réseau Pro"
            />
          </div>
          <div className="footer__top__text">
            <div className="footer__top__li">
              <h6>
                <Link to={{ pathname: "/profil" }}>MON PROFIL</Link>
                <div className="trait_dessus"></div>
              </h6>
              <ul>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemProfil: props.items.itemsProfil.findIndex(
                        (v) => v.label == "Informations personnelles"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/profil" }}>Modifier mon profil</Link>
                </li>
                {/* <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemProfil: props.items.itemsProfil.findIndex(
                        (v) => v.label == "Statistiques"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/profil" }}>Statistiques</Link>
                </li> */}
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemProfil: props.items.itemsProfil.findIndex(
                        (v) => v.label == "Paramètres de confidentialité"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/profil" }}>
                    Paramètres de confidentialité
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__top__li">
              <h6 className="h1_trait_dessous">
                <Link to={{ pathname: "/offres" }}>OFFRES D&apos;EMPLOI</Link>
              </h6>
              <br />
              <ul>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemOffre: props.items.itemsOffre.findIndex(
                        (v) => v.label == "Déposer une offre"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/offres" }}>Déposer une offre</Link>
                </li>

                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemOffre: props.items.itemsOffre.findIndex(
                        (v) => v.label == "Gérer mes offres"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/offres" }}>Gérer mes offres</Link>
                </li>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemOffre: props.items.itemsOffre.findIndex(
                        (v) => v.label == "Candidatures reçues"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/offres" }}>Candidatures reçues</Link>
                </li>
              </ul>
            </div>
            <div className="footer__top__li">
              <h6 className="h1_trait_dessous">
                <Link to={{ pathname: "/cvtheque" }}>CVTHÈQUE</Link>
              </h6>
              <br />
              <ul>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                        (v) => v.label == "Cvthèque"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/cvtheque" }}>Cvthèque</Link>
                </li>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                        (v) => v.label == "Cv sauvegardés"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/cvtheque" }}>Cv sauvegardés</Link>
                </li>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                        (v) => v.label == "Paniers de cv"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/cvtheque" }}>Paniers de Cv</Link>
                </li>
              </ul>
            </div>
            <div className="footer__top__li">
              <h6>
                ÉVÉNEMENTS/ACTUS<div className="trait_dessus"></div>
              </h6>
              <ul>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemActu: props.items.itemsActu.findIndex(
                        (v) => v.label == "Toutes les actus"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/actualites" }}>Toutes les actus</Link>
                </li>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemActu: props.items.itemsActu.findIndex(
                        (v) => v.label == "Proposer une actu"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/actualites" }}>
                    Proposer une actu
                  </Link>
                </li>
                <li
                  onClick={() =>
                    props.handleMenu({
                      activeItemActu: props.items.itemsActu.findIndex(
                        (v) => v.label == "Gérer mes actus"
                      ),
                    })
                  }
                >
                  <Link to={{ pathname: "/actualites" }}>Gérer mes actus</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="footer__top">
          <div className="footer__top__li">
            <img
              src={reseau_pro_blanc}
              alt="Logo Réseau Pro"
              title="Logo Réseau Pro"
            />
          </div>
          <div className="footer__top__text">
            <div className="footer__column">
              <div className="footer__top__li">
                <h6>
                  À PROPOS<div className="trait_dessus"></div>
                </h6>
                <ul>
                  <Link
                    to={{ pathname: "/" }}
                    state={{ toFocus: "presentation_rp" }}
                  >
                    <li>Présentation de Réseau Pro</li>
                  </Link>
                  <li>
                    <Link
                      to={{ pathname: "/" }}
                      // On renseigne l'id de l'élément sur lequel on veut scroller dans l'accueil
                      state={{ toFocus: "chiffresCles" }}
                    >
                      Les chiffres clés
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6>
                  {
                    //? On affiches les offres uniquement si l'utilisateur est connecté
                    !props.auth.isConnected
                      ? "ÉVÉNEMENTS/ACTUS"
                      : "ÉVÉNEMENTS/ACTUS/OFFRES"
                  }
                  <div className="trait_dessus"></div>
                </h6>
                <ul>
                  {props.auth.isConnected && (
                    <Link to={{ pathname: "/offres" }}>
                      <li>Dernières offres</li>
                    </Link>
                  )}
                  <Link to={{ pathname: "/actualites" }}>
                    <li>Dernières actus</li>
                  </Link>
                  <Link to={{ pathname: "/evenements" }}>
                    <li>Prochains événements</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__top__li">
                <h6>
                  NOS UNIVERSITÉS<div className="trait_dessus"></div>
                </h6>
                <ul>
                  <Link
                    to={{ pathname: "/universites" }}
                    state={{ toFocus: "fairePartieRP" }}
                  >
                    <li>Comment faire partie de Réseau Pro ?</li>
                  </Link>
                  <Link
                    to={{ pathname: "/universites" }}
                    state={{ toFocus: "presentationUnivs" }}
                  >
                    <li>Découvrir nos universités</li>
                  </Link>
                </ul>
              </div>
              <div className="footer__top__li">
                <h6>
                  ENTREPRISES ET RECRUTEURS<div className="trait_dessus"></div>
                </h6>
                <ul>
                  <Link
                    to={{ pathname: "/espace-recruteurs" }}
                    state={{ toFocus: "rejoindreRP" }}
                  >
                    <li>Comment rejoindre Réseau Pro ?</li>
                  </Link>
                  <Link
                    to={{ pathname: "/espace-recruteurs" }}
                    state={{ toFocus: "partenairesRP" }}
                  >
                    <li>Les partenaires Réseau Pro</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="footer__bottom">
        <div className="footer__bottom__left">
          <h6>
            <Link to={{ pathname: "/contact" }}>Contactez-Nous</Link>
          </h6>
          <h6>
            <Link to={{ pathname: "/mentions-legales" }}>Mentions légales</Link>
          </h6>
          <h6>
            <Link to={{ pathname: "/plan-site" }}>Plan du site</Link>
          </h6>
          {/* Mise en commentaire car aucune info à ce sujet */}
          {/* <h6>
            <Link to={{ pathname: "/aide" }}>Aide</Link>
          </h6>
          <h6>
            <Link to={{ pathname: "/acccessibilite" }}>Accessibilité</Link>
          </h6>
          <h6>
            <Link to={{ pathname: "/gestion-cookie" }}>
              Gestion des cookies
            </Link>
          </h6> */}
        </div>
        <a
          href="https://www.transformation.gouv.fr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={logo_ministere}
            alt="Logo du ministère de la transformation et de la fonction publiques"
            title="Logo du ministère de la transformation et de la fonction publiques"
            className="footer_mini"
            loading="lazy"
          />
        </a>
        <a
          href="https://choisirleservicepublic.gouv.fr"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={logo_service_public}
            alt="Logo Choisir le service public"
            title="Logo Choisir le service public"
            className="footer_pep"
            loading="lazy"
          />
        </a>
        <a href="https://unif.fr/" target="_blank" rel="noreferrer">
          <img
            src={unif}
            alt="Logo Université Numérique Île-de-France"
            title="Logo Université Numérique Île-de-France"
            className="footer_unif"
            loading="lazy"
          />
        </a>
        <div className="footer__bottom__right">
          <h6>
            Copyright <FaRegCopyright /> Réseau Pro 2022-2024
          </h6>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import React from "react";
import logo_paris1 from "assets/logo_paris1.webp";
import logo_assas from "assets/logo_assas.webp";
import logo_antilles from "assets/logo_antilles.webp";
import logo_sorbonne_nouvelle from "assets/logo_sorbonne_nouvelle.webp";
import logo_upec from "assets/logo_upec.webp";
import logo_uspn from "assets/logo_uspn.webp";
import "./PresentationRP.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Carousel } from "react-bootstrap";
import { GrPrevious, GrNext } from "react-icons/gr";

const PresentationRP = () => {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  let i = getRandomInt(2);
  return (
    <div className="article_presentation">
      <div className="presentation_title">
        <Accordion>
          <AccordionTab
            header={
              <h1 className="h1_trait_dessus" id="presentation_rp">
                Présentation Réseau Pro
              </h1>
            }
          >
            <p>
              Bienvenue sur RéseauPro, le portail qui met en relation les
              étudiants avec les professionnels, permet la rencontre directe de
              l&apos;offre recruteur avec les étudiants, propose offres et
              événements professionnels pour les étudiants.
            </p>
          </AccordionTab>
        </Accordion>
      </div>
      <div className="logo_container">
        <div className="logos_univ">
          <Carousel
            style={{ width: "100%" }}
            prevIcon={<GrPrevious></GrPrevious>}
            nextIcon={<GrNext></GrNext>}
          >
            <Carousel.Item>
              {i == 0 ? (
                <div className="item-carousel">
                  <img
                    src={logo_assas}
                    alt="logo de l'université Panthéon ASSAS"
                    title="Logo de l'université Panthéon ASSAS"
                  />

                  <img
                    src={logo_antilles}
                    alt="logo de l'uinversité des Antilles"
                    title="Logo de l'université des Antilles"
                  />

                  <img
                    src={logo_sorbonne_nouvelle}
                    alt="logo de l'université Sorbonne Nouvelle"
                    title="Logo de l'université Sorbonne Nouvelle"
                  />
                </div>
              ) : (
                <div className="item-carousel">
                  <img
                    src={logo_upec}
                    alt="logo de l'université UPEC"
                    title="Logo de l'université UPEC"
                  />
                  <img
                    src={logo_uspn}
                    alt="logo de l'université Sorbonne Paris Nord"
                    title="Logo de l'université Sorbonne Paris Nord"
                  />
                  <img
                    src={logo_paris1}
                    alt="logo de l'uinversité Paris 1"
                    title="Logo de l'université Paris 1"
                  />
                </div>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {i == 1 ? (
                <div className="item-carousel">
                  <img
                    src={logo_assas}
                    alt="logo de l'université Panthéon ASSAS"
                    title="Logo de l'université Panthéon ASSAS"
                  />

                  <img
                    src={logo_antilles}
                    alt="logo de l'uinversité des Antilles"
                    title="Logo de l'université des Antilles"
                  />

                  <img
                    src={logo_sorbonne_nouvelle}
                    alt="logo de l'université Sorbonne Nouvelle"
                    title="Logo de l'université Sorbonne Nouvelle"
                  />
                </div>
              ) : (
                <div className="item-carousel">
                  <img
                    src={logo_upec}
                    alt="logo de l'université UPEC"
                    title="Logo de l'université UPEC"
                  />
                  <img
                    src={logo_uspn}
                    alt="logo de l'université Sorbonne Paris Nord"
                    title="Logo de l'université Sorbonne Paris Nord"
                  />
                  <img
                    src={logo_paris1}
                    alt="logo de l'uinversité Paris 1"
                    title="Logo de l'université Paris 1"
                  />
                </div>
              )}
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PresentationRP;

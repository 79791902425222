import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Loader from "Components/Loader/loader";
import Modal from "Components/Modal/Modal";
import ModalForgotPassword from "Components/Modal/ModalForgotPassword/ModalForgotPassword";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchPost } from "Services/api";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./HeaderUserLogin.scss";
// import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";

const HeaderUserLogin = (props) => {
  const [visibleForgotPassword, setVisibleForgotPassword] = useState(false);
  const defaultValues = {
    username: "",
    password: "",
  };
  const navigate = useNavigate();

  useEffect(() => {
    !props.loginVisible && setIsLoging(false);
  }, [props.loginVisible]);
  // variables du formulaire
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues });

  const getUser = async (url, token) => {
    var data = undefined;
    try {
      let response = await axios.get(url, {
        headers: token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
      });
      data = response.data;
    } catch (error) {
      data = error;
    }
    return data;
  };

  const setUser = async (token) => {
    if (token) {
      let url = new URL(
        `${process.env.REACT_APP_BASE_URL_API}/recruiters/connected`
      );
      const user = await getUser(url, token);
      //? Comme la variable user peut être une erreur, on vérifie le contenu de l'objet
      if (user && user?.id) {
        props.handleAuth({
          userConnected: user,
          isConnected: true,
          token: token,
        });
        setIsLoging(false);
        props.setLoginVisible(false);
        navigate("/");
        // Si l'utilisateur n'est pas trouvé, on reste déconnecté
      } else {
        setIsLoging(false);
        props.handleAuth({
          userConnected: null,
          isConnected: false,
        });
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Une erreur est survenue, veuillez réessayer",
        });
      }
      // S'il n'y a pas de token, on supprime le token du store
    } else {
      props.handleAuth({
        token: null,
      });
    }

    return;
  };

  const loginSubmission = async (data) => {
    const response = await fetchPost(`/login_check`, data);
    if (response.error) {
      setError("password", {
        type: "manual",
        message: "Identifiants incorrects",
      });
      setIsLoging(false);
      return;
    }
    if (response?.data?.token) {
      setUser(response.data.token);
    }
  };
  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const [isloging, setIsLoging] = useState(false);
  return (
    <>
      <Modal
        header="Connexion"
        visible={props.loginVisible}
        setVisible={props.setLoginVisible}
        width="30svw"
      >
        <form className="login__form" onSubmit={handleSubmit(loginSubmission)}>
          <div className="login__form__field">
            <h4 htmlFor="email">Adresse email</h4>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "L'email est obligatoire",
              }}
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder="Adresse email"
                  className="login__form__field-email"
                  type="email"
                />
              )}
            />
            <div className="login__form__field__error">
              {getFormErrorMessage("email")}
            </div>
          </div>
          <div className="login__form__field">
            <h4 htmlFor="password">Mot de passe</h4>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Le mot de passe est obligatoire",
              }}
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={"Mot de passe"}
                  className="login__form__field-password"
                  feedback={false}
                />
              )}
            />
            <div className="login__form__field__error">
              {getFormErrorMessage("password")}
            </div>{" "}
          </div>
          <span
            className="login__form__forgot__password"
            onClick={() => setVisibleForgotPassword(true)}
          >
            Mot de passe oublié ?
          </span>
          <div className="login__form__button">
            <BtnBleu
              btnAction={() => {
                setIsLoging(true);
              }}
            >
              {isloging ? <Loader /> : "Se connecter"}
            </BtnBleu>
          </div>
        </form>
        {visibleForgotPassword && (
          <ModalForgotPassword
            visible={visibleForgotPassword}
            setVisible={setVisibleForgotPassword}
          ></ModalForgotPassword>
        )}
      </Modal>
    </>
  );
};

HeaderUserLogin.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  loginVisible: PropTypes.bool,
  setLoginVisible: PropTypes.func,
  redirect: PropTypes.string,
  universityId: PropTypes.number,
  setGlobalModalConnexion: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserLogin);

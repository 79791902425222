import { UPDATE_AUTH } from "../Actions/authActions.js";

const INITIAL_STATE = {
  isConnected: false,
  token: null,
  userConnected: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default authReducer;

// id: 11,
// email: "eleazar.schumm@gmail.com",
// roles: ["ROLE_RECRUTEUR", "ROLE_USER"],
// cV: "/api/cvs/2",
// notifications: [],
// savedOfferSearches: [],
// emailNotifications: [],
// frequency: 26,
// charteSigned: false,
// profilTitle: "Cherche un stage",
// firstname: "Kaela",
// useFirstname: "James",
// surname: "Boyle",
// useSurname: "Bond",
// username: "eleazar.schumm@gmail.com",
// profilDescription: "Une super description",
// birthdayIsPublic: false,
// address: "5 boulevard de Trèves",
// telephone: "0606060660",
// city: "Metz",
// country: "Martinique",
// cityAndCountryIsPublic: false,
// candidatures: [],
// groupsMemberOf: [],
// userIdentifier: "eleazar.schumm@gmail.com",
// mailIsPublic: true,
// telephoneIsPublic: true,
// addressIsPublic: true,
// datasVisibleForAllMembers: true,
// datasVisibleForAnnuaire: true,
// datasPublic: true,
// datasAllPrivate: true,
// newsLetterNotification: true,
// rejectedCharte: true,
// availableToWork: true,
// companyCreator: true,
// linkedinAccount: "",
// facebookAccount: "",
// instagramAccount: "",
// twitterAccount: "https://www.mozilla.com",
// mentorAccept: true,
// birthday: "06/06/2022",
// company: "BNP Paribas",

import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";

const Accessibilite = () => {
  return (
    <div>
      <Header />
      <h1>Déclaration d&apos;accessibilité et page d&apos;accessibilité</h1>
      <Footer />
    </div>
  );
};

export default Accessibilite;

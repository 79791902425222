import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./PrevisualisationOffre.scss";
import Offre from "Components/Offres/Offre/Offre";
import { FaRegBuilding, FaWheelchair } from "react-icons/fa";
import { BsHouseDoor, BsPeople } from "react-icons/bs";
import { IoMdDesktop, IoMdHome } from "react-icons/io";
import parse from "html-react-parser";
import { useFetchGet } from "Services/api";
import { useEffect, useState } from "react";

const PrevisualisationOffre = (props) => {
  const [image, setImage] = useState(null);
  const imageStocksData = useFetchGet("/image_stocks/offers");
  useEffect(() => {
    if (imageStocksData.loaded) {
      typeof props.image === "number"
        ? setImage(
            imageStocksData.data.filter(
              (imageData) => imageData.id === props.image
            )[0].resourceUrl
          )
        : setImage(props.image.objectURL);
    }
  }, [imageStocksData.loaded, props.image]);

  return (
    <div className="prev_offre">
      <Offre
        categorie={props.index}
        url={image}
        offreDetails={props.offreDetails}
        disableBookmark
      />
      <div className="offre_detail_container__offre__description">
        <p className="offre_detail_container__offre__description__title--main">
          {props.offreDetails.companyName && props.offreDetails.companyName}

          <span className="offre_detail_container__offre__description__title--main__offre">{` - ${
            props.offreDetails.title && props.offreDetails.title
          }`}</span>
        </p>
        <div className="offre_detail_container__offre__description__infoEntreprise">
          <ul className="offre_detail_container__offre__description__infoEntreprise__list">
            <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
              <FaRegBuilding /> Indisponible
            </li>
            <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
              <BsPeople /> Indisponible
            </li>
            <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
              <BsHouseDoor /> {props.offreDetails.sector?.label}
            </li>
          </ul>
        </div>
        <p className="offre_detail_container__offre__description__title">
          <React.Fragment>
            {props.offreDetails.domains?.length > 1 ? "Domaines" : "Domaine"} de
            l&apos;offre : <br />
            <span>
              {props.offreDetails.domains?.map((domain) => (
                <>
                  {domain.label}
                  {props.offreDetails.domains.indexOf(domain) !==
                  props.offreDetails.domains.length - 1
                    ? ", "
                    : ""}
                </>
              ))}{" "}
            </span>
          </React.Fragment>
        </p>
        <p className="offre_detail_container__offre__description__title">
          Catégorie de contrat de l&apos;offre :{" "}
          <span>{props.offreDetails.offerCategory?.label}</span>
        </p>
        <p className="offre_detail_container__offre__description__title">
          Type de contrat de l&apos;offre :{" "}
          <span>{props.offreDetails.typeOfContract?.label}</span>
        </p>
        <p className="offre_detail_container__offre__description__title">
          <React.Fragment>
            {props.offreDetails.levelOfEducations?.length > 1
              ? "Niveaux"
              : "Niveau"}{" "}
            d&apos;études : <br />
            <span>
              {props.offreDetails.levelOfEducations?.map((level) => (
                <>
                  {level.label}
                  {props.offreDetails.levelOfEducations.indexOf(level) !==
                  props.offreDetails.levelOfEducations.length - 1
                    ? ", "
                    : ""}
                </>
              ))}{" "}
            </span>
          </React.Fragment>
        </p>
        <p className="offre_detail_container__offre__description__title">
          Lieu de l&apos;offre :{" "}
          <span>
            {props.offreDetails.country && props.offreDetails.country} -{" "}
            {props.offreDetails.department && props.offreDetails.department} -{" "}
            {props.offreDetails.city && props.offreDetails.city}
          </span>
        </p>
        <p className="offre_detail_container__offre__description__title">
          Tranche de salaire :{" "}
          <span>
            {props.offreDetails.salary[0] !== -5 &&
              props.offreDetails.salary[0]}{" "}
            € -{" "}
            {props.offreDetails.salary[1] !== -5 &&
              props.offreDetails.salary[1]}{" "}
            €
          </span>
        </p>
        <p className="offre_detail_container__offre__description__titleWithMarge">
          Description de l&apos;entreprise ou du service
        </p>
        <p className="offre_detail_container__offre__description__text">
          {props.offreDetails.companyDescription &&
            parse(props.offreDetails.companyDescription)}
        </p>
        <p className="offre_detail_container__offre__description__titleWithMarge">
          Description de l&apos;offre
        </p>
        <p className="offre_detail_container__offre__description__text">
          {props.offreDetails.description &&
            parse(props.offreDetails.description)}
        </p>
        {props.offreDetails.experience && (
          <>
            <p className="offre_detail_container__offre__description__titleWithMarge">
              Description du profil recherché
            </p>
            <p className="offre_detail_container__offre__description__text">
              {parse(props.offreDetails.experience)}
            </p>
          </>
        )}
      </div>
      <div className="offre_detail_container__offre__description__bottom">
        <div className="offre_detail_container__offre__description__bottom--left">
          <p className="offre_detail_container__offre__description__title">
            CONTACT :
          </p>
          {props.offreDetails.companyUrl && (
            <p className="offre_detail_container__offre__description__title">
              Site internet de l&apos;entreprise :{" "}
              <span>{props.offreDetails.companyUrl}</span>
            </p>
          )}
          {props.offreDetails.urlCandidature && (
            <p className="offre_detail_container__offre__description__title">
              Lien de candidature :{" "}
              <span>{props.offreDetails.urlCandidature}</span>
            </p>
          )}
          {props.contact.email !== "" && (
            <p className="offre_detail_container__offre__description__title">
              Email : <span>{props.contact.email}</span>
            </p>
          )}
          {props.contact.phone !== "" && (
            <p className="offre_detail_container__offre__description__title">
              Téléphone : <span>{props.contact.phone}</span>
            </p>
          )}
        </div>
        <div className="offre_detail_container__offre__description__bottom--right">
          <p className="offre_detail_container__offre__description__title">
            INFORMATIONS COMPLEMENTAIRES :
          </p>
          {(!props.offreDetails.accessibleForDisabled ||
            !props.offreDetails.remoteWorking ||
            !props.offreDetails.accommodation) && (
            <p className="offre_detail_container__offre__description__title">
              Non renseigné
            </p>
          )}
          {props.offreDetails.accessibleForDisabled && (
            <p className="offre_detail_container__offre__description__title">
              <FaWheelchair /> Poste handi-accueillant
            </p>
          )}
          {props.offreDetails.remoteWorking && (
            <p className="offre_detail_container__offre__description__title">
              <IoMdDesktop /> Télétravail possible
            </p>
          )}
          {props.offreDetails.accommodation && (
            <p className="offre_detail_container__offre__description__title">
              <IoMdHome /> Hébergement possible
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

PrevisualisationOffre.propTypes = {
  offreDetails: PropTypes.object,
  image: PropTypes.object,
  contact: PropTypes.object,
  index: PropTypes.number,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrevisualisationOffre);

import React, { useState } from "react";
import "./ParamConfidentialite.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Modal from "Components/Modal/Modal";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { updateAuth } from "Redux/Actions/authActions";
import { showError, showSuccess } from "Services/functions";
import { fetchPut, useFetchGet } from "Services/api";
import { useNavigate } from "react-router-dom";

const ParamConfidentialite = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  // constantes de l'utilisateur
  let defaultValues = {
    profileAccessibility: props.auth.userConnected.profileAccessibility,
    contactsFromStudents: props.auth.userConnected.contactsFromStudents,
    partnerUniversityNewsletter:
      props.auth.userConnected.partnerUniversityNewsletter,
    charteSigned: props.auth.userConnected.charteSigned,
  };

  const charter = useFetchGet("/chartes", props.auth.token);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    const response = await fetchPut(
      `/recruiters/${props.auth.userConnected.id}`,
      data
    );
    if (response.error) {
      showError(
        "Une erreur s'est produite lors de la modification de vos paramètres de confidentialité"
      );
      return;
    }
    showSuccess("Vos paramètres de confidentialité ont bien été modifiés");
    let tempObject = { ...props.auth.userConnected };
    for (let key in data) tempObject[key] = data[key];
    props.handleUpdateAuth({ userConnected: tempObject });
  };

  const navigate = useNavigate();

  /**
   * Déconnexion de l'utilisateur
   * - Met à jour le state auth
   * - Supprime les cookies
   * - Redirige vers la page d'accueil
   * @returns {void}
   */
  function logout() {
    props.handleUpdateAuth({
      isConnected: false,
      token: false,
      userConnected: null,
    });
    // suppression de tous les cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    setVisibleModal(false);
    showError("Vous avez refusé la charte, vous avez été déconnecté");
    navigate("/");
  }

  /**
   * Gestion du refus de la charte
   * - Requête PUT pour mettre à jour le champ charteSigned du recruteur
   * - Déconnexion de l'utilisateur
   * @returns {Promise<void>}
   */
  async function declineCharter() {
    const response = await fetchPut(
      `/recruiters/${props.auth.userConnected.id}`,
      {
        charteSigned: false,
      }
    );
    if (response.error) {
      showError("Une erreur s'est produite lors du refus de la charte");
      return;
    }
    logout();
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div>
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            select="profil"
            activeIndex={props.activeIndex}
            items={props.items}
          />

          <div className="actus_title">
            <h1 className="h1_trait_dessus">
              Modifier mes {props.items[props.activeIndex].label}
            </h1>
          </div>
          <div className="form-demo form_param_confid">
            <div className="flex justify-content-center">
              <div className="card">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <Divider>
                    <span className="p-tag">Visibilité du profil</span>
                  </Divider>
                  <div className="field-checkbox">
                    <Controller
                      name="profileAccessibility"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="profileAccessibility"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      Je rends visible mon profil dans l&apos;annuaire
                      Entreprises à destination des étudiants
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <Divider>
                    <span className="p-tag">Contact</span>
                  </Divider>
                  <div className="field-checkbox">
                    <Controller
                      name="contactsFromStudents"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="contactsFromStudents"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      J&apos;accepte d&apos;être contacté(e) par les étudiants
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <Divider>
                    <span className="p-tag">Newsletter</span>
                  </Divider>
                  <div className="field-checkbox">
                    <Controller
                      name="partnerUniversityNewsletter"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={() => field.onChange(true)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="partnerUniversityNewsletter"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      Je souhaite recevoir les newsletters des universités
                      partenaires
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <Divider>
                    <span className="p-tag">Charte du site</span>
                  </Divider>
                  <div className="charter__view">
                    {charter.loaded && charter.data[0] && (
                      <a
                        href={charter.data[0]?.filepath}
                        target="_blank"
                        rel="noreferrer"
                        title="Consulter la charte Réseau Pro au format PDF"
                      >
                        Consulter la charte Réseau Pro
                      </a>
                    )}
                  </div>
                  <div className="field-checkbox">
                    <Controller
                      name="charteSigned"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => {
                            field.onChange(!e.checked);
                            if (e.checked) setVisibleModal(true);
                          }}
                          checked={!field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="charteSigned"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      Je ne souhaite plus faire partie de Réseau Pro
                    </label>
                    {getFormErrorMessage("name")}
                  </div>

                  {visibleModal && (
                    <Modal
                      visible={visibleModal}
                      setVisible={setVisibleModal}
                      header="Êtes-vous sûr ?"
                    >
                      <div className="modal_description_charte">
                        <form action="/action_page.php" className="form_charte">
                          <label className="charte_modal">
                            Vous ne pourrez plus revenir en arrière
                          </label>
                        </form>
                      </div>

                      <div className="boutons_modal">
                        <BtnBlanc
                          btnAction={() => declineCharter()}
                          btnTexte="Continuer"
                        />
                        <BtnBlanc
                          btnTexte="Annuler"
                          btnAction={() => {
                            setVisibleModal(false);
                            setValue("charteSigned", true);
                          }}
                        />
                      </div>
                    </Modal>
                  )}

                  <div className="form_columns">
                    <BtnBleu btnTexte="Enregistrer ">
                      <FaCheck />
                    </BtnBleu>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ParamConfidentialite.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParamConfidentialite);

import React from "react";
import "./NavItemWithMenu.scss";
import { Link, useLocation } from "react-router-dom";
import MenuDepliant from "../MenuDepliant/MenuDepliant";
import PropTypes from "prop-types";

const NavItemWithMenu = (props) => {
  return (
    <li>
      <Link
        to={props.path}
        className={useLocation().pathname == props.path ? "activeLink" : ""}
      >
        <button
          onMouseEnter={() => props.setter(true)}
          onMouseLeave={() => props.setter(false)}
          className="annuaire__button"
        >
          {props.label}
          {props.getter && (
            <div
              className="annuaireInterface"
              onMouseEnter={() => props.setter(true)}
              onMouseLeave={() => props.setter(false)}
            >
              <MenuDepliant item={props.item} />
            </div>
          )}
        </button>
      </Link>
    </li>
  );
};

NavItemWithMenu.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  item: PropTypes.string,
  getter: PropTypes.object,
  setter: PropTypes.func,
};

export default NavItemWithMenu;

import React from "react";
import "./CVSauvegardes.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CVSearchBar from "../CVSearchBar/CVSearchBar";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useRef } from "react";
import { useState } from "react";
import CV from "../CV/CV";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";

const CVSauvegardes = (props) => {
  const [url, setUrl] = useState(null);
  const saveRef = useRef(null);

  const cvsData = useFetchGetPagination(url);

  return (
    <div>
      <div className="CVs__container">
        <div className="CVs__container__title">
          <h1 className="h1_trait_dessus">
            {props.items[props.activeIndex].label}
          </h1>
        </div>
        <div className="offre_search_bar__filtres">
          <CVSearchBar url={url} setUrl={setUrl} saved />
        </div>
        <div className="CVs__container__cvs">
          {!cvsData.loaded ? (
            <span>Vous n&apos;avez actuellement aucun CV sauvegardé.</span>
          ) : cvsData.data && cvsData.totalItems > 0 ? (
            cvsData.data.map((cv) => (
              <CV
                key={cv.id}
                firstname={cv.firstname}
                document={cv.document || cv.cvReseauPro || null}
                surname={cv.surname}
                titre={cv.profileTitle}
                cvId={cv.userId}
                //educations={cv.educations}
                // roles={cv.user.userRoles}
                resumeId={cv.resumeId}
                imageUrl={cv.imageUrl ? cv.imageUrl : null}
                certified={cv.atLeastOneCertified}
                university={cv.univId}
              />
            ))
          ) : (
            <span>
              Vous n&apos;avez aucun CV sauvegardé pour cette université.
            </span>
          )}
        </div>
        <TempPagination result={cvsData} refItemParent={saveRef} rows={50} />
      </div>
    </div>
  );
};

CVSauvegardes.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CVSauvegardes);

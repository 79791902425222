import { updateAuth } from "Redux/Actions/authActions";
import facebook_logo from "assets/logo_facebook.webp";
import instagram_logo from "assets/logo_instagram.webp";
import linkedin_logo from "assets/logo_linkedin.webp";
import twitter_logo from "assets/logo_twitter.webp";
import neutralUser from "assets/neutralUser.png";
import PropTypes from "prop-types";
import React from "react";
import {
  FaHandsHelping,
  FaMapMarkerAlt,
  FaRegAddressBook,
  FaRegCalendar,
  FaRegEnvelope,
} from "react-icons/fa";
import { connect } from "react-redux";
import { formatDate, truncate } from "../../../Services/functions";
import "./ProfilBarShow.scss";

const ProfilBarShow = (props) => {
  return (
    <>
      <div className="profilBarShow">
        <div className="profilBarShow__image">
          <img
            src={
              props.user.imageUrl != null ? props.user.imageUrl : neutralUser
            }
          />
        </div>
        <div className="profilBarShow__middle">
          <h2>
            {props.user?.firstname} {props.user?.surname}
          </h2>
          <h3>{props.user?.profilTitle}</h3>
          <p>
            {props.user.profilDescription && (
              <>{truncate(props.user?.profilDescription, 350)}</>
            )}
          </p>
          {props.user.mentorAccept && (
            <div className="profilBarShow__middle__parrain">
              <p>
                <FaHandsHelping /> Parrain/Marraine
              </p>
            </div>
          )}
        </div>
        <div className="profilBarShow__right">
          <div className="profilBarShow__right__item">
            <label>
              <FaRegCalendar />
              Date de naissance :
            </label>
            <p>
              {props.user.birthday
                ? formatDate(props.user.birthday)
                : "Non renseignée"}
            </p>
          </div>
          <div className="profilBarShow__right__item">
            <label>
              <FaMapMarkerAlt />
              Adresse postale :
            </label>
            <p>{props.user.address}</p>
          </div>
          <div className="profilBarShow__right__item">
            <label>
              <FaRegEnvelope />
              Adresse mail :
            </label>
            <p>
              {props.user.email ? (
                <a href={`mailto:${props.user.email && props.user.email}`}>
                  {" "}
                  {props.user.email}
                </a>
              ) : (
                "Non renseignée"
              )}
            </p>
          </div>
          <div className="profilBarShow__right__item">
            <label>
              <FaRegAddressBook />
              Tel :
            </label>
            <p>
              {props.user.telephone ? props.user.telephone : "Non renseigné"}
            </p>
          </div>
          <div className="profilBarShow__right__logos">
            {props.user.facebookAccount && (
              <a
                href={
                  // On vérifie si le lien commence par http ou https
                  props.user.facebookAccount.startsWith("http")
                    ? props.user.facebookAccount
                    : "https://" + props.user.facebookAccount
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook_logo} />
              </a>
            )}
            {props.user.linkedinAccount && (
              <a
                href={
                  // On vérifie si le lien commence par http ou https
                  props.user.linkedinAccount.startsWith("http")
                    ? props.user.linkedinAccount
                    : "https://" + props.user.linkedinAccount
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin_logo} />
              </a>
            )}
            {props.user.twitterAccount && (
              <a
                href={
                  // On vérifie si le lien commence par http ou https
                  props.user.twitterAccount.startsWith("http")
                    ? props.user.twitterAccount
                    : "https://" + props.user.twitterAccount
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter_logo} />
              </a>
            )}
            {props.user.instagramAccount && (
              <a
                href={
                  // On vérifie si le lien commence par http ou https
                  props.user.instagramAccount.startsWith("http")
                    ? props.user.instagramAccount
                    : "https://" + props.user.instagramAccount
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram_logo} />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ProfilBarShow.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  handleUpdateAuth: PropTypes.func,
  setModified: PropTypes.func,
  image: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilBarShow);

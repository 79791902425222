import { React, useEffect, useState } from "react";
import "./SizedImage.scss";
import PropTypes from "prop-types";

const SizedImage = (props) => {
  const [cardImage, setCardImage] = useState(null);

  useEffect(() => {
    setCardImage(props.image);
  }, [props.image]);

  return (
    <div
      className={`sized__image ${props.className || ""} ${
        props.rounded ? "rounded_image" : "normal"
      }`}
      style={{ height: props.height, width: props.rounded && props.height }}
    >
      <img
        className={`sized__image__image ${props.imageClassName || ""}`}
        src={cardImage}
        alt={props.alt}
        loading="lazy"
        onError={() => {
          setCardImage(props.defaultImage);
        }}
        style={{ maxHeight: props.height }}
      />
      <div
        className={`sized__image__bg ${props.bgClassName || ""}`}
        style={{ backgroundImage: `url(${cardImage})` }}
      ></div>
    </div>
  );
};

SizedImage.propTypes = {
  image: PropTypes.string.isRequired,
  defaultImage: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  bgClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  alt: PropTypes.string,
  rounded: PropTypes.bool,
};

export default SizedImage;

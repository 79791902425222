import React, { useEffect, useRef, useState } from "react";
import "./Entreprises.scss";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import EntreprisesCards from "./EntreprisesCards/EntreprisesCards";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import Modal from "Components/Modal/Modal";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import ModalCreationCompte from "Components/Modal/ModalCreationCompte/ModalCreationCompte";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import { useLocation, useNavigate } from "react-router-dom";

const Entreprises = (props) => {
  const itemsPerPage = 12;
  const refCompany = useRef(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCreationCompte, setVisibleModalCreationCompte] =
    useState(false);

  const companiesQuery = useFetchGetPagination(
    `${process.env.REACT_APP_BASE_URL_API}/companies?isPartner=1?itemsPerPage=${itemsPerPage}`
  );

  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  /**
   * Permet de gérer les différents state de location
   * - toFocus : Permet de scroller jusqu'à un élément (via son id)
   */
  const handleLocationState = () => {
    // Gestion du cas où on a une locationState.toFocus
    if (locationState?.toFocus) {
      // Récupération de l'élément à focus
      const element = document.getElementById(locationState.toFocus);
      // Scroll vers l'élément
      element &&
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      // Suppression de l'élément toFocus de la locationState pour éviter de scroller à chaque changement de locationState
      navigate(location.pathname, { replace: true });
    }
  };

  useEffect(() => {
    handleLocationState();
  }, [locationState]);

  return (
    <div className="universites">
      <Header />
      {!props.auth.isConnected && (
        <div className="universites_container" id="rejoindreRP">
          <div className="universites_container__top">
            <div className="universites_container__top__title">
              <h1 className="h1_trait_dessus">
                Comment rejoindre Réseau Pro ?
              </h1>
            </div>
            <div>
              <p>
                Vous êtes recruteur et vous souhaitez trouver le profil idéal
                pour compléter votre équipe, le portail Réseau Pro vous permet
                de poster tout type d’offres d’emploi et de consulter un
                ensemble de Cv. Rejoignez le réseau et diffusez vos actus, vos
                événements et vos offres à toutes nos universités ou à quelques
                unes seulement pour mieux cibler vos besoins. Déjà plus de 500
                entreprises nous font confiance et ont déjà embauchés des
                étudiants ou des jeunes diplômés de nos nuniversités. Le
                processus est simple :
              </p>
              <ul>
                <li>Accédez au bouton “créer un compte”</li>
                <li>Remplissez le formulaire de création de compte</li>
                <li>Envoyez votre demande de validation</li>
                <li>Faites partie du Réseau</li>
              </ul>
            </div>
            <div className="universites_container__top__boutons">
              <BtnBleu
                btnTexte="Se connecter"
                btnAction={() => setVisibleModal(true)}
              />
              <BtnBlanc
                btnTexte="Créer un compte"
                btnAction={() => setVisibleModalCreationCompte(true)}
              />
            </div>
          </div>
          <div className="universites_container__bottom" id="partenairesRP">
            <div className="universites_container__bottom__title">
              <h1 className="h1_trait_dessus">Les partenaires Réseau Pro</h1>
            </div>
            <div
              className="universites_container__bottom__entreprises"
              ref={refCompany}
            >
              {companiesQuery.loaded ? (
                companiesQuery.data?.length > 0 ? (
                  companiesQuery.data.map((company) => (
                    <div key={company.id} onClick={() => setVisibleModal(true)}>
                      <EntreprisesCards
                        nomEntreprise={company?.name}
                        type={company?.structure?.label ?? company?.type?.label}
                        imageLogo={company.logo || "entreprise2"}
                        imageFond={"entrepriseFond2"}
                        taille={company?.totalStaff?.label ?? "Non renseigné"}
                        offresDisponibles
                      />
                    </div>
                  ))
                ) : (
                  <span>Aucune entreprise n&apos;a été trouvée</span>
                )
              ) : (
                <Loader></Loader>
              )}
            </div>
            <TempPagination
              result={companiesQuery.data}
              refItemParent={refCompany}
              rows={itemsPerPage}
            />
          </div>
        </div>
      )}
      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        ></HeaderUserLogin>
      )}

      {visibleModalCreationCompte && (
        <Modal
          visible={visibleModalCreationCompte}
          setVisible={setVisibleModalCreationCompte}
          header="Création de compte"
          className="modal-register"
        >
          <div className="description">
            <ModalCreationCompte
              setAuthEntrepriseVisible={setVisibleModalCreationCompte}
            />
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
};

Entreprises.propTypes = {
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Entreprises);

import crown from "assets/crown.png";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaBookmark, FaPaperclip, FaRegBookmark } from "react-icons/fa";
import { connect } from "react-redux";
import "./CV.scss";
// import laurier_alumnis from "assets/laurier.webp";
import Loader from "Components/Loader/loader";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchDelete, fetchPost } from "Services/api";
import { downloadRPResume, showError, showSuccess } from "Services/functions";
import neutralUser from "assets/neutralUser.png";
import { Link } from "react-router-dom";

const CV = (props) => {
  const [bookmark, setBookmark] = useState(
    props.auth.userConnected.savedResumes.some(
      (savedResume) =>
        savedResume.resume === props.resumeId &&
        savedResume.university.split("/")[3] == props.university
    )
  );

  const [isLoader, setIsLoader] = useState(false);
  const addResumeToSaveList = async () => {
    const data = {
      cv: props.resumeId,
      univ: props.university,
    };
    setIsLoader(true);

    const response = await fetchPost(`/cvs`, data);
    setIsLoader(false);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }

    setBookmark(!bookmark);
    let user = props.auth.userConnected;
    user.savedResumes = user.savedResumes.concat([response.data]);
    props.handleAuth({ userConnected: user });
    showSuccess("Le CV a bien été ajouté aux CV sauvegardés");
  };

  const removeResumeFromSaveList = async () => {
    setIsLoader(true);

    const savedResume = props.auth.userConnected.savedResumes.find(
      (x) => x.resume === props.resumeId
    );

    const response = await fetchDelete(`/saved_resumes/${savedResume.id}`);
    setIsLoader(false);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }

    setBookmark(!bookmark);
    let user = props.auth.userConnected;
    user.savedResumes = user.savedResumes.filter(
      (saved) => saved.id !== savedResume.id
    );
    props.handleAuth({ userConnected: user });
    showSuccess("Le CV a bien été retiré de la liste des CV sauvegardés");
  };

  return (
    <div className={"CV__container cardHover from_cv"}>
      <div className="CV">
        <div className="CV__image">
          <div className="user__alumnis">
            {/* {props.roles.some((role) => role.roleName == "ROLE_ALUMNI") && (
              <img
                src={laurier_alumnis}
                alt="Laurier alumni"
                title="Laurier alumni"
              />
            )} */}
          </div>
          <img
            src={props.imageUrl ? props.imageUrl : neutralUser}
            alt="Mon avatar"
            title="Mon avatar"
          />
        </div>
        <div className="details">
          <div className="left">
            <h2 className="h2_name">
              {`${props.firstname} ${props.surname}`}
              {props.certified === 1 && (
                <img
                  src={crown}
                  alt="crown"
                  title="Certifié"
                  className="crown"
                />
              )}
            </h2>
            <h3 className="h3_poste" title={props.titre}>
              {props.titre}
            </h3>
            <div className="bottom">
              <Link
                to={{
                  pathname: `/profil-show/${props.university}-${props.cvId}`,
                }}
                state={{
                  university: props.university,
                }}
                title={`Voir le profil de l'étudiant ${props.firstname} ${props.surname}`}
              >
                <a href="" className="show_profil">
                  {" "}
                  Voir le profil
                </a>
              </Link>
              {props.document ? (
                <div
                  className="dwnld_cv"
                  onClick={() => {
                    downloadRPResume(
                      props.document,
                      `CV_ReseauPro_${props.firstname}_${props.surname}`
                    );
                  }}
                >
                  {" "}
                  <FaPaperclip />
                  Télécharger le CV
                </div>
              ) : null}
            </div>
          </div>
          <div className="right">
            <div className="flag_save">
              {!isLoader ? (
                bookmark ? (
                  <div className="tooltip">
                    <FaBookmark
                      color="#4190ee"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeResumeFromSaveList();
                      }}
                    />
                    <span className="tooltiptext">
                      Enlever ce CV des CV sauvegardés
                    </span>
                  </div>
                ) : (
                  <div className="tooltip">
                    <span className="tooltiptext">Sauvegarder ce CV</span>
                    <FaRegBookmark
                      color="#4190ee"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addResumeToSaveList();
                      }}
                    />
                  </div>
                )
              ) : (
                <Loader></Loader>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CV.propTypes = {
  firstname: PropTypes.string,
  surname: PropTypes.string,
  prenom: PropTypes.string,
  titre: PropTypes.string,
  document: PropTypes.string || null,
  bookmark: PropTypes.bool,
  date: PropTypes.string,
  items: PropTypes.object,
  key: PropTypes.number,
  cvId: PropTypes.number,
  auth: PropTypes.object,
  educations: PropTypes.array,
  roles: PropTypes.array,
  resumeId: PropTypes.number,
  handleAuth: PropTypes.func,
  savedResumes: PropTypes.array,
  imageUrl: PropTypes.string,
  certified: PropTypes.bool,
  university: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CV);

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";
import "../NavBar/Navbar.scss";
import Reseauprologo from "assets/Reseauprologo.webp";
import logo_unif from "assets/logo_unif.webp";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { SlideMenu } from "primereact/slidemenu";
import { FaRegUserCircle } from "react-icons/fa";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import NavItemNoMenu from "./NavItemNoMenu/NavItemNoMenu";
import NavItemWithMenu from "./NavItemWithMenu/NavItemWithMenu";
import NavBarMobile from "./NavBarMobile/NavBarMobile";
import neutral from "assets/neutralUser.png";

const NavBar = (props) => {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const menu = useRef(null);
  const [openEvenementInterface, setOpenEvenementInterface] = useState(false);
  const [openActuInterface, setOpenActuInterface] = useState(false);
  const [openOffreInterface, setOpenOffreInterface] = useState(false);
  const [openCVInterface, setOpenCVInterface] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const menuNotConnected = useRef(null);

  // items du menu burger non connecté---------------------------TODO
  let itemsNotConnected = [
    {
      label: "Accueil",
      command: () => (window.location.href = "/"),
    },
    { separator: true },
    {
      label: "Événements",
      command: () => (window.location.href = "/evenements"),
    },
    { separator: true },
    {
      label: "Actus",
      command: () => (window.location.href = "/actualites"),
    },
    { separator: true },
    {
      label: "Nos universités",
      command: () => (window.location.href = "/universites"),
    },
    { separator: true },
    {
      label: "Espace recruteurs",
      command: () => (window.location.href = "/espace-recruteurs"),
    },
    { separator: true },
    {
      label: "Connexion",
      command: () => {
        setVisibleModal(!visibleModal);
      },
    },
    { separator: true },
    {
      image: { logo_unif },
      className: "logoUnif_burger",
    },
  ];

  return (
    <div>
      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        />
      )}
      <nav className="navigation">
        {/* menu mobile */}
        {props.auth.isConnected ? (
          <>
            <div className="navigation__hamburger">
              <div>
                <NavBarMobile menu={menu} />
                <Button
                  icon="pi pi-bars"
                  onClick={(event) => menu.current.toggle(event)}
                />
              </div>
            </div>
            <img
              src={logo_unif}
              className="brand-name"
              alt="Logo Unif"
              title="Logo Unif"
            />
            <Link to="/profil">
              <img
                src={props.auth.userConnected.imageUrl || neutral}
                onError={() => {
                  props.auth.userConnected.imageUrl = neutral;
                }}
                className="img-profil"
                alt="Mon avatar"
                title="Mon avatar"
              />
            </Link>
          </>
        ) : (
          <>
            <img
              src={Reseauprologo}
              className="brand-name"
              alt="Logo de Réseau Pro"
              title="Logo de Réseau Pro"
            />
            <FaRegUserCircle className="img-profil__disconnected" />
            <div className="navigation__hamburger">
              <div>
                <SlideMenu
                  model={itemsNotConnected}
                  viewportHeight={220}
                  menuWidth={175}
                  popup
                  ref={menuNotConnected}
                  style={{ textAlign: "start", left: "0", width: "175px" }}
                />
                <Button
                  icon="pi pi-bars"
                  onClick={(event) => (
                    menuNotConnected.current.toggle(event),
                    setNavBarOpen(!navBarOpen)
                  )}
                />
              </div>
            </div>
          </>
        )}

        {/* ----------------------------------------- barre de navigation  */}
        <div className="navigation-menu">
          <ul>
            <NavItemNoMenu path="/" label="Accueil"></NavItemNoMenu>
            {/* hors connexion */}
            {!props.auth.isConnected && (
              <>
                <NavItemNoMenu
                  path="/evenements"
                  label="Événements"
                ></NavItemNoMenu>
                <NavItemNoMenu path="/actualites" label="Actus"></NavItemNoMenu>
                <NavItemNoMenu
                  path="/universites"
                  label="Nos universités"
                ></NavItemNoMenu>
                <NavItemNoMenu
                  path="/espace-recruteurs"
                  label="Espace recruteurs"
                ></NavItemNoMenu>
              </>
            )}
            {/* après connexion */}
            {props.auth.isConnected && (
              <>
                <NavItemWithMenu
                  path="/evenements"
                  label="Événements"
                  getter={openEvenementInterface}
                  setter={setOpenEvenementInterface}
                  item="event"
                ></NavItemWithMenu>
                <NavItemWithMenu
                  path="/actualites"
                  label="Actus"
                  getter={openActuInterface}
                  setter={setOpenActuInterface}
                  item="actu"
                ></NavItemWithMenu>
                <NavItemWithMenu
                  path="/offres"
                  label="Offres d'emploi"
                  getter={openOffreInterface}
                  setter={setOpenOffreInterface}
                  item="offres"
                ></NavItemWithMenu>
                <NavItemWithMenu
                  path="/cvtheque"
                  label="Cvthèque"
                  getter={openCVInterface}
                  setter={setOpenCVInterface}
                  item="cvtheque"
                ></NavItemWithMenu>
                <NavItemNoMenu
                  path="/universites"
                  label="Nos universités"
                ></NavItemNoMenu>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

NavBar.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NavBar);

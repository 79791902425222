import React from "react";
import { InputText } from "primereact/inputtext";
import Modal from "../Modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./ModalForgotPassword.scss";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Loader from "Components/Loader/loader";
import { updateAuth } from "Redux/Actions/authActions";
import { showError, showSuccess } from "Services/functions";
import { fetchPost } from "Services/api";

const ModalForgotPassword = (props) => {
  const [error, setError] = useState(null);

  const defaultValues = {
    email: "",
    resetKey: "",
    password: "",
  };

  // variables du formulaire
  const {
    getValues,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async () => {
    const data = getValues();

    if (data.email !== "") {
      await sendMail({ email: data.email });
      setError(null);
    } else {
      setError("Certaines informations ne sont pas remplies");
    }
  };

  const sendMail = async (data) => {
    const response = await fetchPost(`/api/forgot-password/`, data, "short");
    if (response.error) {
      showError("Une erreur s'est produite lors de l'envoi du mail");
      return;
    }

    showSuccess(response.data || 'Un email vous a été envoyé pour réinitialiser votre mot de passe.', null, 20000);
    props.setVisible(false);
  };

  return (
    <Modal
      header="Connexion"
      visible={props.visible}
      setVisible={props.setVisible}
      width={"30rem"}
    >
      <form
        className="login__form reset__password"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="login__form__field">
          <h4 htmlFor="email">Adresse email</h4>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                placeholder="Adresse email"
                className="login__form__field-email"
                type="email"
              />
            )}
          />
        </div>
        {error && <small className="p-error">{error}</small>}
        <span className="forgot__password__information">
          Renseignez l&apos;email de votre compte, un email avec la clé de
          réinitialisation de votre mot de passe va vous être envoyé
        </span>
        <div className="login__form__button">
          {isSubmitting ? <Loader /> : <BtnBleu>Envoyer</BtnBleu>}
        </div>
      </form>
    </Modal>
  );
};

ModalForgotPassword.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalForgotPassword);

import React, { useState, useEffect, useRef } from "react";
import "./ActualitesManagement.scss";
import Actualite from "../Actualite/Actualite";
import ActualiteManagerCard from "./ActualiteManagementCard/ActualiteManagementCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatDateFrenchLocale } from "Services/functions";
import { updateNews } from "Redux/Actions/newsActions";
import Management from "Components/Management/Management";
import Loader from "Components/Loader/loader";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";
import { Link } from "react-router-dom";
/**
 * Permet d'afficher la page de gestion des actus
 * Accessible pour les administrateurs, alumni et personnels
 */
const ActualitesManagement = (props) => {
  const [url, setUrl] = useState(null);
  useFetchGetPagination(url, props.handleUpdateNews);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************

  const [states, setStates] = useState([]);
  const [lastNews, setLastNews] = useState(null);

  const actualitesContainerRef = useRef(null);
  const newsMngRef = useRef(null);

  useEffect(() => {
    return () => {
      props.handleUpdateNews({ dataFromPagination: null });
    };
  }, []);

  useEffect(() => {
    /// Récupérer les statuts, et les filtrer par ordre alphabétique
    if (props.news.etats && props.news.etats.length > 0) {
      let tempArray = props.news.etats.map((state) => {
        return { ...state };
      });
      tempArray = tempArray.sort((a, b) =>
        a.statusLabel.localeCompare(b.statusLabel)
      );
      tempArray.forEach((state) => {
        if (
          state.statusLabel.lastIndexOf("é") ===
          state.statusLabel.length - 1
        ) {
          state.statusLabel = state.statusLabel + "e";
        }
      });
      setStates(tempArray);
    }
  }, [props.news.etats]);

  useEffect(() => {
    // Charge la dernière offre en fonction de l'emetteur choisi
    url?.href?.includes("page=1") &&
      setLastNews(
        props.news.dataFromPagination?.data &&
          props.news.dataFromPagination?.data[0]
      );
  }, [props.news.dataFromPagination?.data]);

  return (
    <>
      <div className="gestion_evenements" ref={newsMngRef}>
        <div className="gestion_evenements__title">
          <h1 className="h1_trait_dessus">{"Gérer mes actus"}</h1>
          <Management
            states={states}
            actualPage={"actu"}
            updateFunction={setUrl}
          ></Management>
        </div>
        <div className="gestion_evenements__body">
          <div className="gestion_evenements__body__left">
            <h3>Dernière actu créée</h3>
            {lastNews !== null && lastNews !== undefined && (
              <Link
                to={{
                  pathname: `/actu-detail/${lastNews.id}`,
                  search: "?r=true",
                }}
              >
                <Actualite
                  categorie={lastNews.category?.label || "Autres"}
                  title={lastNews.title}
                  chapeau={lastNews.description}
                  publicationDate={formatDateFrenchLocale(
                    lastNews.modifiedAt || lastNews.publishedAt
                  )}
                  newsDetails={lastNews}
                  url={lastNews.imageUrl ? lastNews.imageUrl : "img1"}
                />
              </Link>
            )}
          </div>
          <div
            className="gestion_evenements__body__right"
            ref={actualitesContainerRef}
          >
            {props.news.dataFromPagination !== null &&
            props.news.dataFromPagination?.loaded ? (
              props.news.dataFromPagination.data?.length > 0 ? (
                props.news.dataFromPagination.data
                  .filter(
                    (actu) =>
                      actu.state.statusLabel !== "Brouillon" ||
                      actu.createdBy["@id"].split("/")[3] ==
                        props.auth.userConnected.id
                  )
                  .sort(
                    (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)
                  )
                  .map((news, index) => (
                    <ActualiteManagerCard
                      titre={news.title ? news.title : "Titre non renseigné"}
                      date={formatDateFrenchLocale(
                        news.modifiedAt || news.publishedAt
                      )}
                      statut={news.state}
                      image={news.imageUrl ? news.imageUrl : "img1"}
                      key={index}
                      id={news.id}
                      feedback={news.feedback?.feedback}
                      universityName={news.university?.name ?? "Inconnue"}
                    />
                  ))
              ) : (
                <span>Aucune actualité ne correspond à votre recherche</span>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
        <TempPagination
          result={props.news.dataFromPagination}
          refItemParent={actualitesContainerRef}
          rows={20}
        />
      </div>
    </>
  );
};

ActualitesManagement.propTypes = {
  auth: PropTypes.object,
  news: PropTypes.object,
  handleUpdateNews: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => {
    dispatch(updateNews(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualitesManagement);

import React from "react";
import "./modal.scss";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";

const Modal = (props) => {
  const RenderFooter = ({ footer }) => {
    return <div>{footer}</div>;
  };

  return (
    <Dialog
      header={props.header}
      visible={props.visible}
      breakpoints={{ "960px": "75vw" }}
      style={{
        width: props.width ? props.width : "fit-content",
        height: props.height ? props.height : "fit-content",
      }}
      onHide={props.setVisible}
      footer={<RenderFooter footer={props.footer} />}
      className={`modal ${props.className}`}
      closable={props.closable}
    >
      {props.children}
    </Dialog>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  header: PropTypes.string,
  footer: PropTypes.any,
  children: PropTypes.any,
  closable: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

Modal.defaultProps = {
  closable: true,
};

export default Modal;

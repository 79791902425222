import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import React from "react";
import { useState } from "react";
// import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./PlanDuSite.scss";

const PlanDuSite = () => {
  const [loginVisible, setLoginVisible] = useState(false);

  function LiSitemapUniversities() {
    const universities = [
      {
        label: "Université Paris 1 Panthéon Sorbonne",
        universityUrl: "https://www.pantheonsorbonne.fr/",
      },
      {
        label: "Université Paris Nanterre",
        universityUrl: "https://www.parisnanterre.fr/",
      },
      {
        label: "Université Sorbonne Paris Nord (Ex Paris 13)",
        universityUrl: "https://www.univ-paris13.fr/",
      },
      {
        label: "UPEC (Université Paris-Est Créteil)",
        universityUrl: "https://www.u-pec.fr/",
      },
      {
        label: "Université Paris-Panthéon-Assas",
        universityUrl: "https://www.u-paris2.fr/fr",
      },
      {
        label: "Université Sorbonne Nouvelle - Paris 3",
        universityUrl: "http://www.univ-paris3.fr/",
      },
      {
        label: "Université des Antilles",
        universityUrl: "http://www.univ-ag.fr/",
      },
    ];
    return (
      <ol>
        {universities.map((university) => (
          <li key={university}>
            <a
              href={university.universityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {university.label}
            </a>
          </li>
        ))}
      </ol>
    );
  }
  function LiSitemapInfos() {
    const infos = [
      { label: "Contactez-nous", path: "/contact" },
      { label: "Mentions légales", path: "/mentions-legales" },
      { label: "Plan du site", path: "/plan-du-site" },
    ];
    return (
      <ol>
        {infos.map((info, index) => (
          <li
            className="liInfos"
            key={index}
            onClick={() => {
              navigate(info.path);
            }}
          >
            {info.label}
          </li>
        ))}
      </ol>
    );
  }

  const navigate = useNavigate();

  function LiSitemapServices() {
    const services = [
      { label: "Toutes les actualités", path: "/actualites" },
      { label: "Tous les évènements", path: "/evenements" },
      { label: "Toutes les offres", path: "/offres" },
      { label: "Cvtheque", path: null },
      { label: "Groupes", path: null },
      { label: "Annuaire", path: null },
      { label: "Profil", path: null },
      { label: "Nos universités", path: "/universites" },
      { label: "Comment faire partie de Réseau Pro ?", path: "/universites" },
      { label: "Presentation de nos universités", path: "/universites" },
      { label: "Espace Recruteur", path: "/espace-recruteurs" },
      { label: "Comment rejoindre Réseau Pro", path: "/espace-recruteurs" },
      { label: "Les entreprises Réseau Pro", path: "/espace-recruteurs" },
    ];
    return (
      <ol>
        {services.map((service, index) => (
          <li
            className="liServices"
            key={index}
            onClick={() => {
              service.path ? navigate(service.path) : setLoginVisible(true);
            }}
          >
            {service.label}
          </li>
        ))}
      </ol>
    );
  }

  return (
    <div>
      <Header />
      <div className="sitemapContainer">
        <h1 className="h1_trait_dessus">Plan du site de Réseau Pro</h1>
        <div className="sitemapContainer__global">
          <div className="sitemapContainer__global__left">
            <div className="sitemapContainer__global__container">
              <h2 className="h2_sitemap"> Les universités du réseau</h2>
              <ul className="ul_sitemap">{LiSitemapUniversities()}</ul>
            </div>

            <div className="sitemapContainer__global__container">
              <h2 className="h2_sitemap">Pour en savoir plus</h2>
              <ul>{LiSitemapInfos()}</ul>
            </div>
          </div>
          <div className="sitemapContainer__global__container">
            <h2 className="h2_sitemap">Les services de Réseau Pro</h2>
            <ul>{LiSitemapServices()}</ul>
          </div>
        </div>
      </div>
      {loginVisible && (
        <HeaderUserLogin
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
        />
      )}
      <Footer />
    </div>
  );
};

// PlanDuSite.propTypes = {
//   toto: PropTypes.func,
//   test: PropTypes.object,
// };

// const mapStateToProps = (state) => ({
//   test: state.universities,
// });

// const mapDispatchToProps = (dispatch) => ({
//   toto: (value) => dispatch(updateUniversities(value)),
// });

export default PlanDuSite;

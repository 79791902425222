import "./Management.scss";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import { fr } from "date-fns/locale";
import "../Evenements/EvenementsManagement/EvenementsManagement.scss";
import { Link } from "react-router-dom";
import { addDayToDate, formatDateDatabase } from "Services/functions";
import Filters from "Components/Filters/Filters";
import Loader from "Components/Loader/loader";

registerLocale("fr", fr);
const Management = (props) => {
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************

  const [filtreStatus, setFiltreStatus] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreDate, setFiltreDate] = useState(null);
  const [isKeywordFiltering, setIsKeywordFiltering] = useState(false);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************

  // Hook qui se déclenche au changement de statut d'un des filtres
  useEffect(() => {
    if (
      (filtreDate && filtreDate[0] && filtreDate[1]) ||
      props.states?.length > 0
    ) {
      handleFilter();
    }
  }, [filtreStatus, filtreDate, isKeywordFiltering, props.states]);

  const setFilters = (url) => {
    url.searchParams.set("itemsPerPage", 20);
    if (props.actualPage === "offre") {
      url.searchParams.append("createdByUser[]", props.auth.userConnected.id);
    } else {
      url.searchParams.append("createdBy[]", props.auth.userConnected.id);
    }
    if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
    if (filtreStatus?.id) url.searchParams.set("state", filtreStatus.id);
    else {
      props?.states
        ?.filter((state) => state.statusLabel !== "Supprimé")
        .forEach((state) => {
          url.searchParams.append("state[]", state.id);
        });
    }
    if (filtreDate && filtreDate[0])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "startingAt[after]"
          : "createdAt[after]",
        formatDateDatabase(filtreDate[0])
      );
    if (filtreDate && filtreDate[1])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "startingAt[before]"
          : "createdAt[before]",
        formatDateDatabase(addDayToDate(filtreDate[1]))
      );
    return url;
  };
  // Fonction globale de filtre
  const handleFilter = () => {
    let url = null;

    if (props.actualPage === "offre") {
      url = setFilters(new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`));
      // Si pas de filtre createdAt[after] ou createdAt[before] on trie par date de modification par défaut
      if (
        !url.searchParams.get("createdAt[after]") &&
        !url.searchParams.get("createdAt[before]")
      ) {
        url.searchParams.set("order[modifiedAt]", "desc");
      } // Sinon on trie par date de création
      else url.searchParams.set("order[createdAt]", "desc");
    } else if (props.actualPage === "événement") {
      url = setFilters(new URL(`${process.env.REACT_APP_BASE_URL_API}/events`));
      url.searchParams.set("order[startingAt]", "asc");
      url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
    } else if (props.actualPage === "actu") {
      url = setFilters(new URL(`${process.env.REACT_APP_BASE_URL_API}/news`));
    }
    props.updateFunction(url);
  };

  return (
    <div className="management_new_event_offer_filtres__top">
      {props.states?.length > 0 ? (
        <Filters
          filtreMotsCles={filtreMotsCles}
          setFiltreMotsCles={setFiltreMotsCles}
          filtreDate={filtreDate}
          setFiltreDate={setFiltreDate}
          filtreDropdown={filtreStatus}
          setFiltreDropdown={setFiltreStatus}
          dataDropdown={props.states}
          setIsKeywordFiltering={setIsKeywordFiltering}
          filterFunction={handleFilter}
          placeholder="Filtrer par statut"
          dropdownOption="statusLabel"
        ></Filters>
      ) : (
        <Loader></Loader>
      )}
      {props.actualPage === "événement" && (
        <div className="management_new_event_offer_filtres__top__createBtn">
          <Link to={{ pathname: `/evenements` }}>
            <BtnBlancLeft
              btnTexte="Proposer un événement"
              btnAction={() => {
                props.handleMenu({
                  activeItemEvenement: props.items.itemsEvenement.findIndex(
                    (v) => v.label == "Proposer un événement"
                  ),
                });
              }}
            ></BtnBlancLeft>
          </Link>
        </div>
      )}
      {props.actualPage === "actu" && (
        <div className="management_new_event_offer_filtres__top__createBtn">
          <BtnBlancLeft
            btnTexte="Proposer une actu"
            btnAction={() =>
              props.handleMenu({
                activeItemActu: props.items.itemsActu.findIndex(
                  (v) => v.label == "Proposer une actu"
                ),
              })
            }
          >
            <FaPen />
          </BtnBlancLeft>
        </div>
      )}
    </div>
  );
};

Management.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  offers: PropTypes.object,
  handleMenu: PropTypes.func,
  updateFunction: PropTypes.func,
  states: PropTypes.array,
  actualPage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);

import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import Modal from "Components/Modal/Modal";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useFetchGet, useFetchGetConditional } from "Services/api";
import { debounce } from "Services/debounce";
import { retrieveCountries } from "assets/countries";
import axios from "axios";
import DOMPurify from "dompurify";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import "./ModalCreationCompte.scss";

const CompteEntreprise = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [visibleModalCharte, setVisibleModalCharte] = useState(false);

  const [isPartner, setIsPartner] = useState(false);
  const [verified, setVerified] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [companyCategory, setCompanyCategory] = useState("France");

  // Liste des entreprises internationales trouvées par le nom
  const [autocompleteCompanies, setAutocompleteCompanies] = useState([]);

  // Debounce pour la recherche des entreprises internationales par nom
  const debounceCompanyName = useCallback(
    debounce(searchInternationalCompanyWithName, 700)
  );

  const structuresData = useFetchGet("/company_structures");
  const effectifData = useFetchGet("/company_total_staffs");
  const typeEntrepriseData = useFetchGet("/company_types", props.auth.token);
  const sectorData = useFetchGetConditional(
    "/sector_of_offers",
    props.auth.token,
    props.secondaryTables.sectorOfOffersData
  );

  // ? Rendu initial de la page (pour éviter le message d'erreur au chargement de la page)
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    sectorData.loaded &&
      props.handleUpdateSecondaryTables({
        sectorOfOffersData: sectorData.data,
      });
  }, [sectorData.loaded]);

  // constantes de l'utilisateur
  let defaultValues = {
    surname: "",
    firstname: "",
    email: "",
    telephone: "+33",
    description: "",
    siret: "",
    siren: "",
    companyUrl: "",
    rna: "",
    duns: "",
    companyName: "",
    size: "",
    structure: "",
    role: "",
    sector: "",
    country: "France",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    // watch,
  } = useForm({ defaultValues });

  const onSubmit = () => {
    const data = getValues();
    setFormData(data);
    submitRequest(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const togglePartner = () => {
    setAutocompleteCompanies([]);
    setIsPartner(true);
    setVerified(false);
    setNotFound(false);
  };

  const togglerVerified = () => {
    setAutocompleteCompanies([]);
    setIsPartner(false);
    setVerified(true);
    setNotFound(false);
  };

  useMemo(() => {
    if (verified === false && isPartner === false) {
      setValue("companyName", "");
      setValue("sector", "");
      setValue("size", "");
      setValue("companyUrl", "");
    }
  }, [verified, isPartner]);

  const verifyNumber = async (value) => {
    if (!value || value.length === 0) {
      setInitialRender(true);
    }
    let underScoreOccurence = value.split("_").length - 1;
    let formattedValue = value.replace(/\s/g, "");

    if (
      (companyCategory === "France" &&
        formattedValue.length == 14 &&
        underScoreOccurence === 0) ||
      (companyCategory !== "France" &&
        formattedValue.length == 9 &&
        underScoreOccurence === 0)
    ) {
      //? Si on est encore en rendu initial, on le set à false
      initialRender && setInitialRender(false);

      let company = await searchCompanyWithIdentificationNumber(formattedValue);
      if (company) {
        togglePartner();
        setValue("companyName", company.name);
        setValue("description", DOMPurify.sanitize(company.description));
        setValue("companyUrl", company.companyUrl);
        company.structure &&
          setValue(
            "structure",
            structuresData.data.filter(
              (structure) => structure.id === company.structure.id
            )[0]
          );
        company.totalStaff &&
          setValue(
            "size",
            effectifData.data.filter(
              (effectif) => effectif.id === company.totalStaff.id
            )[0]
          );
        company.sector &&
          setValue(
            "sector",
            sectorData.data.filter(
              (secteur) => secteur.id === company.sector.id
            )[0]
          );
      } else {
        setIsPartner(false);
        const companyInfos = await searchCompanyIdentificationNumberApi(
          formattedValue
        );

        companyInfos?.structure &&
          setValue("structure", companyInfos.structure);
        companyInfos?.companyName &&
          setValue("companyName", companyInfos.companyName);
        companyInfos?.siret &&
          getValues("siret", companyInfos.siret) === "" &&
          setValue("siret", companyInfos.siret);
        companyInfos?.siren &&
          getValues("siren", companyInfos.siren) === "" &&
          setValue("siren", companyInfos.siren);

        if (!companyInfos) {
          setNotFound(true);
        }
      }
    } else {
      setIsPartner(false);
      setVerified(false);
      setNotFound(true);
    }
  };

  const civilityData = [
    { label: "M", id: 1, code: "Monsieur" },
    { label: "Mme", id: 2, code: "Madame" },
  ];

  /**
   * Remplissage des informations de l'entreprise internationale (API Recruteur)
   * @param {Number} idhttps://www.youtube.com/
   */
  async function fillInternationalCompanyInfos(id) {
    var url = new URL(`${process.env.REACT_APP_BASE_URL_API}/companies/${id}`);
    try {
      let response = await axios.get(url);
      let data = response.data;
      setValue("companyName", data.name);
      setValue("description", DOMPurify.sanitize(data.description));
      setValue("companyUrl", data.companyUrl);
      data.structure &&
        setValue(
          "structure",
          structuresData.data.filter(
            (structure) => structure.id === data.structure.id
          )[0]
        );
      data.totalStaff &&
        setValue(
          "size",
          effectifData.data.filter(
            (effectif) => effectif.id === data.totalStaff.id
          )[0]
        );
      data.sector &&
        setValue(
          "sector",
          sectorData.data.filter((secteur) => secteur.id === data.sector.id)[0]
        );
    } catch (error) {
      props.auth.toast?.current?.show({
        severity: "error",
        summary: "Erreur",
        detail: error?.response?.data?.detail || "Une erreur est survenue",
        life: 5000,
      });
    }
  }

  /**
   * Recherche des entreprises internationale par un nom (API Recruteur)
   *
   * @param {string} name - Nom de l'entreprise renseigné par l'utilisateur
   * @returns {void}
   */
  async function searchInternationalCompanyWithName(name) {
    var url = new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`);
    url.searchParams.set("name", name);
    // Filtre sur les entreprises internationales
    url.searchParams.set(
      "type",
      typeEntrepriseData.data?.find(
        (type) => type.label === "Entreprise internationale"
      )?.id
    );
    try {
      let response = await axios.get(url);
      setAutocompleteCompanies(response.data["hydra:member"] || []);
    } catch (error) {
      props.auth.toast?.current?.show({
        severity: "error",
        summary: "Erreur",
        detail:
          error?.response?.data?.detail ||
          "Une erreur est survenue lors de la récupération des entreprises",
        life: 5000,
      });
    }
  }

  /**
   * Recherche une entreprise par son numéro d'identification (API Recruteur)
   *
   * @param {string} identificationNumber - Numéro d'identification de l'entreprise renseigné par l'utilisateur
   * @returns {object} - Données de l'entreprise
   */
  const searchCompanyWithIdentificationNumber = async (
    identificationNumber
  ) => {
    const type = getIdentificationNumberType();
    if (type === "duns" || type === "rna") return null;

    var url = new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`);

    url.searchParams.set(type, identificationNumber);
    try {
      let response = await axios.get(url);
      return response.data["hydra:member"][0];
    } catch (error) {
      props.auth.toast?.current?.show({
        severity: "error",
        summary: "Erreur",
        detail: error?.response?.data?.detail || "Une erreur est survenue",
        life: 5000,
      });
      return error;
    }
  };

  /**
   * Recherche de l'entreprise par son SIRET (API sirene)
   *
   * (Uniquement si l'entreprise n'est pas déjà partenaire de Réseau Pro)
   *
   * @param {string} identificationNumber - Numéro d'identification de l'entreprise renseigné par l'utilisateur
   * @returns {object} - Données de l'entreprise
   */
  const searchCompanyIdentificationNumberApi = async (identificationNumber) => {
    var url = new URL(
      `${process.env.REACT_APP_BASE_URL_API}/companies/identificationNumber/${identificationNumber}`
    );

    const companyData = await axios.get(url);

    if (companyData.status === 200 && companyData.data) {
      togglerVerified();
      return {
        companyName: companyData.data.name,
        structure:
          structuresData.data.find(
            (structure) => structure.label === companyData.data.structure
          ) || "",
        siret: companyData.data?.siret,
        siren: companyData.data?.siren,
      };
    } else {
      setVerified(false);
    }
  };

  const formatData = (data) => {
    let tempCompanyType = null;
    let formatData = {
      siret: data.siret ? data.siret.replace(/ /g, "") : null,
      siren: data.siren ? data.siren.replace(/ /g, "") : null,
      rna: data.rna ? data.rna.replace(/ /g, "") : null,
      companyUrl: data.companyUrl,
      companyName: data.companyName?.name || data.companyName,
      identificationNumber: data.companyName?.identificationNumber || undefined,
      companyDescription: data.description,
      companySector: data.sector
        ? `api/sector_of_offers/${data.sector.id}`
        : undefined,
      recruiterFunction: data.role,
      firstname: data.firstname,
      surname: data.surname,
      email: data.email,
      telephone: data.telephone,
      recruiterCivility: data?.civility?.label || "",
      country: data.country || "",
    };

    // Si le siret est renseigné, on en déduit le siren
    if (formatData.siret && formatData.siret.length === 14) {
      formatData.siren = formatData.siret.substring(0, 9);
    }

    // Check si le format du SIRET est valide
    if (
      companyCategory === "France" &&
      ((formatData.siret && formatData.siret.length !== 14) ||
        (formatData.siren && formatData.siren.length !== 9))
    ) {
      props.auth.toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Le numéro de SIRETest invalide (14 chiffres)",
        life: 4000,
      });
      return null;
    }

    // Check si le format du RNA est valide
    if (
      companyCategory === "Association" &&
      ((formatData.rna && formatData.rna.length !== 9) ||
        formatData.rna.includes("_"))
    ) {
      props.auth.toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail: "Le numéro de RNA est invalide (9 chiffres)",
        life: 4000,
      });
      return null;
    }

    if (data.sector && data.sector.length > 0) {
      formatData.companySector = `api/sector_of_offers/${data.sector.id}`;
    }

    switch (companyCategory) {
      case "France":
        tempCompanyType = typeEntrepriseData.data.filter(
          (type) => type.label === "Entreprise située en France"
        )[0].id;
        formatData.companyType = `api/company_types/${tempCompanyType}`;
        formatData.companyTotalStaff = `api/company_total_staffs/${data.size.id}`;
        formatData.companyStructure = `api/company_structures/${data.structure.id}`;
        formatData.country = "France";
        break;
      case "International":
        tempCompanyType = typeEntrepriseData.data.filter(
          (type) => type.label === "Entreprise internationale"
        )[0].id;
        formatData.companyType = `api/company_types/${tempCompanyType}`;
        break;
      case "Association":
        tempCompanyType = typeEntrepriseData.data.filter(
          (type) => type.label === "Association"
        )[0].id;
        formatData.companyType = `api/company_types/${tempCompanyType}`;
        formatData.companyTotalStaff = `api/company_total_staffs/${data.size.id}`;
        formatData.companyStructure = `api/company_structures/${
          structuresData.data.filter(
            (entreprise) => entreprise.label === "Association"
          )[0].id
        }`;
        break;
      default:
        break;
    }

    return formatData;
  };

  /**
   * Fonction d'envoi de la demande de création de compte avec les données du formulaire
   * @param {{}} data - Données du formulaire
   * @returns {void}
   */
  const submitRequest = (data) => {
    data = formatData(data);
    if (!data) return;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/recruiter_membership_requests`,
        data,
        {
          accept: "application/json",
        }
      )
      .then(() => {
        setShowMessage(true);
        setIsPartner(false);
        setVerified(false);
      })
      .catch((error) => {
        let errorMessage =
          "Une erreur est survenue lors de la création du compte";
        if (error.response && error.response.status === 422) {
          const violations = error.response.data.violations;
          if (violations && violations.length > 0) {
            errorMessage = violations[0]?.message || errorMessage;
          }
        }
        props.auth.toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: errorMessage,
          life: 4000,
        });
      })
      .finally(() => reset());
  };

  const getIdentificationNumberType = () => {
    if (companyCategory === "France") return "siret";
    if (companyCategory === "International") return "duns";
    if (companyCategory === "Association") return "rna";
  };

  /**
   * Vide les informations de l'entreprise (nom, description, site internet, secteur, taille, structure)
   * ainsi que les états de vérification et de partenariat
   * @returns {void}
   */
  const emptyInfos = () => {
    //? Champs de l'entreprise
    setValue("companyName", "");
    setValue("description", "");
    setValue("companyUrl", "");
    setValue("structure", "");
    setValue("size", "");
    setValue("sector", "");
    setValue("rna", "");
    setValue("duns", "");
    setValue("siret", "");
    setValue("siren", "");
    //? Etats de vérification et de partenariat
    setVerified(false);
    setIsPartner(false);
    //? Liste des entreprises internationales trouvées
    setAutocompleteCompanies([]);
  };

  return (
    <div className="form-demo">
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="field">
          <h2>Vous êtes *</h2>

          <div className="checkboxes">
            <div className="field checkbox">
              <Checkbox
                className="checkbox_french"
                checked={companyCategory === "France"}
                onChange={() => {
                  emptyInfos();
                  setCompanyCategory("France");
                  setValue("country", "France");
                }}
              />
              <label htmlFor="french">Entreprise située en France</label>
              {getFormErrorMessage("french")}
            </div>
            <div className="field checkbox">
              <Checkbox
                className="checkbox_international"
                checked={companyCategory === "International"}
                onChange={() => {
                  emptyInfos();
                  setCompanyCategory("International");
                  setValue("country", "");
                }}
              />
              <label htmlFor="international">
                Une entreprise internationale
              </label>
              {getFormErrorMessage("international")}
            </div>
            <div className="field checkbox">
              <Checkbox
                className="checkbox_association"
                checked={companyCategory === "Association"}
                onChange={() => {
                  emptyInfos();
                  setCompanyCategory("Association");
                  setValue("country", "");
                }}
              />
              <label htmlFor="association">Une association</label>
              {getFormErrorMessage("association")}
            </div>
          </div>
        </div>

        <>
          {companyCategory !== "International" && (
            <div className="field">
              <h2>
                {companyCategory === "Association"
                  ? "N° RNA de l'association *"
                  : "N° de SIRET de l'entreprise *"}
              </h2>
              <Controller
                name={getIdentificationNumberType()}
                control={control}
                rules={{
                  required: "Le numéro d'identification est requis",
                }}
                render={({ field, fieldState }) => (
                  <InputMask
                    id={field.name}
                    mask={
                      companyCategory === "France" &&
                      companyCategory === "International"
                        ? "99 999 9999"
                        : "999 999 999 99999"
                    }
                    value={field.value}
                    placeholder={
                      companyCategory === "France" &&
                      companyCategory === "International"
                        ? "99 999 9999"
                        : "999 999 999 99999"
                    }
                    autoClear={false}
                    onChange={(e) => {
                      field.onChange(e.value);
                      verifyNumber(e.value);
                    }}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage(getIdentificationNumberType())}
            </div>
          )}

          <div className="field">
            <h2>
              {companyCategory === "Association"
                ? "Nom de l'association *"
                : "Nom de l'entreprise *"}
            </h2>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: "Le nom est requis" }}
              render={({ field, fieldState }) => (
                <Dropdown
                  id={field.name}
                  tooltip={
                    autocompleteCompanies.length > 0
                      ? `${autocompleteCompanies.length} entreprise(s) trouvée(s)`
                      : null
                  }
                  tooltipOptions={{
                    position: "top",
                  }}
                  readOnly={isPartner}
                  showClear
                  placeholder="Actimage ..."
                  {...field}
                  disabled={isPartner}
                  autoFocus
                  editable
                  options={autocompleteCompanies}
                  optionLabel="name"
                  onChange={async (e) => {
                    field.onChange(e.target.value);
                    // Si le type d'identification est DUNS :
                    if (getIdentificationNumberType() === "duns") {
                      //on recherche les entreprises internationales avec le nom (uniquement si le champ est une string)
                      typeof e.target.value === "string" &&
                        debounceCompanyName(e.target.value);
                      // sinon on remplit les champs avec les données de l'entreprise
                      typeof e.target.value === "object" &&
                        e.target.value?.id &&
                        (await fillInternationalCompanyInfos(
                          e.target.value.id
                        ));
                    }
                  }}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                    "options-available": autocompleteCompanies.length > 0,
                  })}
                />
              )}
            />
            {getFormErrorMessage("companyName")}
          </div>

          {(isPartner === true || verified === true) && (
            <div className="company_icon already_partner">
              <BsCheck2Circle />
              <span>
                {isPartner
                  ? "Votre entreprise est déjà partenaire de Réseau Pro !"
                  : verified
                  ? "Votre entreprise a été trouvée !"
                  : ""}
              </span>
            </div>
          )}
          {
            //! Affichage du message d'erreur si l'entreprise n'est pas trouvée
            //! (ne s'affiche pas lors du rendu initial)
            !initialRender &&
              !isPartner &&
              !verified &&
              notFound &&
              companyCategory === "France" && (
                <div className="company_icon not_found">
                  <BsXCircle />
                  <span>
                    Votre entreprise n&apos;a pas été trouvée. Veuillez vérifier
                    le numéro de SIRET/SIREN.
                  </span>
                </div>
              )
          }
          <>
            <div className="field">
              <h2>
                {companyCategory === "Association"
                  ? "Description de l'association *"
                  : "Description de l'entreprise *"}
              </h2>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: !isPartner ? "La description est requise" : false,
                }}
                render={({ field, fieldState }) => (
                  <ReactQuill
                    id={field.name}
                    {...field}
                    theme="snow"
                    readOnly={isPartner}
                    modules={QuillOptions.modules}
                    formats={QuillOptions.formats}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    style={{
                      width: "50vw",
                    }}
                    rows={5}
                  />
                )}
              />
              {getFormErrorMessage("description")}
            </div>

            <div className="field">
              <h2>
                {companyCategory === "Association"
                  ? "Site internet de l'association *"
                  : "Site internet de l'entreprise *"}
              </h2>
              <Controller
                name="companyUrl"
                control={control}
                rules={{
                  required: !isPartner ? "Le site internet est requis" : false,
                  validate: (value) =>
                    // Check value is not empty
                    value.trim().length > 0
                      ? true
                      : "Le site internet est requis",
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    readOnly={isPartner}
                    disabled={isPartner}
                    placeholder="https://..."
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("companyUrl")}
            </div>

            <div className="field">
              <h2>
                Pays de l&apos;entreprise
                {companyCategory === "International" && " *"}
              </h2>
              <Controller
                name="country"
                control={control}
                rules={{
                  // Seules les entreprises internationales ont besoin de renseigner le pays
                  required:
                    companyCategory === "International"
                      ? "Le pays est requis"
                      : false,
                }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    options={
                      getIdentificationNumberType() === "duns"
                        ? retrieveCountries().filter(
                            (country) => country !== "France"
                          )
                        : retrieveCountries()
                    }
                    placeholder={
                      companyCategory === "International"
                        ? "Allemagne, Belgique..."
                        : "France, Allemagne, ..."
                    }
                    disabled={companyCategory === "France"}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("country")}
            </div>

            <div className="ask_connexion_company">
              <div className="field">
                <h2>Structure de l&apos;entreprise *</h2>
                <Controller
                  name="structure"
                  control={control}
                  rules={{
                    required:
                      //* Seules les entreprises situées en France ont besoin de renseigner la structure
                      companyCategory === "France"
                        ? "La structure de l'entreprise est requise"
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      optionLabel="label"
                      readOnly={isPartner}
                      options={structuresData?.data?.filter(
                        (structure) => structure.label !== "Association"
                      )}
                      placeholder="PME, TPE, ..."
                      disabled={
                        companyCategory === "Association" ||
                        companyCategory === "International" ||
                        (isPartner && companyCategory === "France")
                      }
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("structure")}
              </div>
              <div className="field">
                <h2>Effectif *</h2>
                <Controller
                  name="size"
                  control={control}
                  rules={{
                    required:
                      //* Seules les entreprises situées en France ont besoin de renseigner l'effectif
                      companyCategory === "France"
                        ? "L'effectif est requis"
                        : false,
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      readOnly={isPartner}
                      optionLabel="label"
                      options={effectifData?.data}
                      disabled={
                        companyCategory === "International" || isPartner
                      }
                      placeholder="Nombre de salariés"
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("size")}
              </div>
            </div>
          </>

          <div className="field">
            <h2>Secteur de l&apos;entreprise</h2>
            <Controller
              name="sector"
              control={control}
              render={({ field, fieldState }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  placeholder="Programmation, publicité..."
                  optionLabel="label"
                  showClear
                  filter
                  disabled={isPartner}
                  filterBy="label"
                  options={sectorData?.data?.sort((a, b) =>
                    a?.label.localeCompare(b?.label)
                  )}
                  onChange={(e) => field.onChange(e.value)}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                    "dropdown-s": true,
                  })}
                />
              )}
            />
            {getFormErrorMessage("sector")}
          </div>

          <div className="field">
            <h2>Civilité</h2>
            <Controller
              name="civility"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  optionLabel="label"
                  options={civilityData}
                  placeholder="Monsieur, Madame"
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />

            {getFormErrorMessage("civility")}
          </div>

          <div className="ask_connexion_name">
            <div className="field">
              <h2>Prénom *</h2>
              <Controller
                name="firstname"
                control={control}
                rules={{ required: "Le prénom est requis" }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    placeholder="Marie, Arthur..."
                    //   autoFocus
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("firstname")}
            </div>
            <div className="field">
              <h2>Nom *</h2>
              <Controller
                name="surname"
                control={control}
                rules={{ required: "Le nom est requis" }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    placeholder="Dupont..."
                    {...field}
                    autoFocus
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("surname")}
            </div>
          </div>

          <div className="field">
            <h2>
              {companyCategory === "Association"
                ? "Fonction au sein de l'association *"
                : "Fonction au sein de l'entreprise *"}
            </h2>
            <Controller
              name="role"
              control={control}
              rules={{ required: "La fonction est requise" }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  placeholder="PDG, chargé de communication ..."
                  {...field}
                  autoFocus
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            {getFormErrorMessage("role")}
          </div>

          <div className="ask_connexion_coordinates">
            <div className="field">
              <h2>Adresse mail professionnelle *</h2>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "L'adresse mail est requise",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse mail invalide",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    placeholder="hello123@actimage.com"
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
          <div className="phone-number">
            <div className="field">
              <h2 style={{ marginBottom: "3svh" }}>Numéro de téléphone *</h2>
              <PhoneInputWithCountry
                name="telephone"
                control={control}
                inputComponent={InputText}
                defaultValue={"+33"}
                rules={{
                  required: "Le numéro de téléphone est requis.",
                  // On utilise isPossiblePhoneNumber pour vérifier si le numéro est bien formé
                  validate: (value) =>
                    isPossiblePhoneNumber(value)
                      ? true
                      : "Le numéro de téléphone est invalide",
                  pattern: {
                    // Valeur doit commencer par + ou être au format FR
                    value: /^(\+?[1-9]\d{1,14}|0\d{9})$/,
                    message: "Le numéro de téléphone est invalide",
                  },
                }}
                placeholder={"+33 9 99 99 99 99"}
                defaultCountry="FR"
              />
              {getFormErrorMessage("telephone")}
            </div>
          </div>
        </>
        <BtnBlanc
          btnTexte="Envoyer"
          btnAction={
            async (e) => {
              e.preventDefault();
              // On désactive temporaiement la vérification de l'entreprise pour une entreprise française (vérification manuelle par les admins)
              // if (verified || isPartner || companyCategory !== "France") {
              const result = await trigger();
              if (result) {
                onSubmit();
                setVisibleModalCharte(!visibleModalCharte);
              }
            }
            // else {
            //   props.auth.toast.current.show({
            //     severity: "error",
            //     summary: "Erreur",
            //     detail: "Le numéro de SIRET/SIREN est invalide",
            //     life: 4000,
            //   });
            // }
          }
        />
      </form>
      {visibleModalCharte && (
        <ModalCharte
          visibleModal={visibleModalCharte}
          setVisibleModal={setVisibleModalCharte}
          btnRefuse={() => {
            setVisibleModalCharte(false);
            props.setAuthEntrepriseVisible(false);
          }}
          btnAction={() => {
            setVisibleModalCharte(false);
            onSubmit();
          }}
        >
          <div className="description">
            <ModalCharte />
          </div>
        </ModalCharte>
      )}
      <Modal
        visible={showMessage}
        setVisible={setShowMessage}
        header="Succès"
        blockScroll
        height="auto"
        width="30vw"
        className="modal recruiter__request"
      >
        <div className="recruiter__request__container">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "3rem", color: "var(--green-500)" }}
          ></i>
          <h5>Création de compte terminée !</h5>
          <p style={{ lineHeight: 1.5 }}>
            <br />
            Votre compte est enregistré sous le nom de{" "}
            <b>{formData?.firstname}</b> <b>{formData?.surname}</b>.
            <br />
            <br />
            Un mail vous sera envoyé à <b>{formData?.email}</b> une fois votre
            compte activé.
          </p>
          <Button
            label="OK"
            className="p-button-text"
            style={{
              //? Centrer le bouton
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            onClick={() => {
              setShowMessage(false);
              props.setAuthEntrepriseVisible(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

CompteEntreprise.propTypes = {
  setAuthEntrepriseVisible: PropTypes.func,

  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompteEntreprise);

import React, { useState, useEffect } from "react";
import "./ActualitesCreation.scss";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { fetchGet, fetchPost, useFetchGet } from "Services/api";
import Loader from "Components/Loader/loader";
import { isJsonParsable, isNotEmptyArray, showError } from "Services/functions";
import Modal from "Components/Modal/Modal";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { Link } from "react-router-dom";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import Brouillon from "Components/Brouillons/Brouillon";
import { updateMenu } from "Redux/Actions/menuActions";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { updateNews } from "Redux/Actions/newsActions";
import UniversitiesPanel from "Components/UniversitiesPanel/UniversitiesPanel";

/**
 * Permet d'afficher le formulaire de création d'une actualité
 * Les admins ont la possibilité de choisir entre créer un conseil ou une actu
 * Les alumni et les personnels ont la possibilité de proposer une actu
 */
const ActualitesCreation = (props) => {
  // Liste des catégories d'actus
  const categoriesData = useFetchGet("/news_categories");

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // ID de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");

  // ------- VARIABLES GESTION FORMULAIRE ----------
  // catégorie de l'actu choisie
  const [categories, setCategories] = useState([]);
  // Liste des liens du formulaire
  const [urlActu, setUrlActu] = useState([""]);
  // Nombre de champs liens du formulaire
  const [countForm, setCountForm] = useState(0);
  // Défini si l'actualité a bien été publiée / soumise ou non
  const [isSubmitted, setSubmitted] = useState(false);
  const [listUniversities, setListUniversities] = useState([]);
  const [currentActu, setCurrentActu] = useState(null);
  const [isLoader, setIsLoader] = useState(true);

  let defaultValues = {
    title: "",
    category: null,
    chapo: "",
    description: "",
    auteur: false,
    public: true,
    image: "",
    publishInMyName: true,
  };

  const [isModifying, setIsModifying] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [waitingLoader, setWaitingLoader] = useState(false);

  useEffect(async () => {
    if (!categoriesData.loaded || categoriesData?.data?.length === 0) return;
    // Récupérer les catégories, et les filtrer par ordre alphabétique
    let tempArray = [];
    categoriesData.data.forEach((category) => tempArray.push(category));
    tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
    setCategories(tempArray);
    setValue("visiblity", true);

    if (props.value) {
      // On récupère les informations de la news qu'on veut modifier
      const newsItem = await fetchGet(`/news/${props.value.id}`);
      if (newsItem.error) return;
      setCurrentActu(newsItem.data);
      fillFormFromDraft(newsItem.data);
      setIsModifying(true);
      // Une fois les catégories chargées, on les stocke dans un tableau :
      setCurrentCategory(
        categoriesData.data !== null &&
          categoriesData.data.find(
            (category) => category.label === props.value.category.label
          )
      );
    }
    setIsLoader(false);
  }, [categoriesData.loaded, categoriesData.data]);

  useEffect(() => {
    props.value && setPhototheque(false);
  }, []);

  /**
   * Fonction permettant de convertir les données du formulaire
   * en format {@link FormData} et des les envoyer au serveur
   * @param {Object} data données du formulaire
   */
  const sendRequest = async (data) => {
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) {
      if (key === "concernedUniversities") {
        dataForm.append(key, JSON.stringify(data[key]));
      } else {
        dataForm.append(key, data[key]);
      }
    }

    // On envoie le formData au serveur
    const formResponse = await fetchPost("/news/request/validation", dataForm);
    setWaitingLoader(false);
    if (formResponse.error) {
      formResponse.error?.statusText === "Unprocessable Entity" &&
        showError("L'image doit être au format paysage");
      return;
    }
    reset();
    setUrlActu([""]);
    setSubmitted(true);
  };

  const putForm = async (data) => {
    // Variable qui stockera la réponse du serveur
    let url = null;

    if (currentActu.state.statusLabel === "Brouillon") {
      url = `/news/${currentActu.id}/edit/draft`;
    } else if (currentActu.state.statusLabel === "Refusé") {
      url = `/news/${currentActu.id}/request/validation`;
    } else {
      url = `/news/${currentActu.id}/edit/publish`;
    }
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) dataForm.append(key, data[key]);

    // On envoie le formData au serveur
    const formResponse = await fetchPost(url, dataForm);
    setWaitingLoader(false);
    if (formResponse.error) {
      formResponse.error?.statusText === "Unprocessable Entity" &&
        showError("L'image doit être au format paysage");
      return;
    }
    reset();
    setUrlActu([""]);
    setSubmitted(true);
    if (isModifying) props.setVisible(false);
    if (props.news.dataFromPagination?.data) {
      let tempArray = props.news.dataFromPagination;
      tempArray.data = tempArray.data.map((news) => {
        if (news.id === formResponse.data.id) return formResponse.data;
        else return news;
      });
      props.handleUpdateNews({ dataFromPagination: tempArray });
    } else {
      props.handleUpdateNews({
        dataFromPagination: {
          data: [formResponse.data],
          loaded: true,
          page: 1,
          setPage: null,
          totalItems: 1,
        },
      });
    }
  };

  /**
   * Fonction permettant d'ajouter un lien à la liste des liens du formulaire
   */
  const addUrl = () => {
    const newUrl = "";
    setCountForm(countForm + 1);
    const newArray = [...urlActu];
    newArray.push(newUrl);
    setUrlActu(newArray);
  };

  /**
   * Fonction permettant d'éditer un des liens du formulaire
   * @param {Event} e event contenant le contenu du champ édité
   * @param {int} i index du champ à éditer
   */
  const majUrl = (e, i) => {
    const newArray = [...urlActu];
    newArray[i] = e.target.value;
    setUrlActu(newArray);
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.category) data.category = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.category.id)
      data.category = "/api/news_categories/" + data.category.id;
    /* 
    Sinon, on cherche si la catégorie existe déjà :
    // Si oui : on met l'id de la catégorie
     Si non : on spécifie qu'elle doit être créée
     */ else {
      const category = categories.find(
        (category) => category.label === data.category
      );
      if (category) data.category = "/api/news_categories/" + category.id;
      else data.category = JSON.stringify({ label: data.category });
    }
    return data;
  };

  // ? How to check if variable is stringified?

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  const setFields = (data, formType = "multipart") => {
    // Association temporaire de la news à un utilisateur test
    let createdByUser = "/api/users/" + props.auth.userConnected.id;
    data.description = DOMPurify.sanitize(data.description);
    // Création d'un object qui va contenir toutes les clés du formulaire soumis
    data = fillCategory(data);
    data = fillImage(data);
    // La news est-elle privée ou publique ?
    data.public = data.public ? true : false;
    // Insertion des liens du formulaire non vide dans la key links
    data.links = JSON.stringify(urlActu.filter((element) => element !== ""));
    // La news est-elle visible pour toutes les universités ?
    data.forAllUniversities = true;
    data.createdBy = createdByUser;
    // Suppression de la key links si vide
    if ((urlActu.at(0) == [""] && urlActu.length === 1) || !urlActu)
      delete data.links;
    // Suppression des keys non utilisées
    delete data.auteur, delete data.urlActu;
    if (formType === "json") {
      data.category = isJsonParsable(data.category)
        ? JSON.parse(data.category)
        : data.category;
      data.links = isJsonParsable(data.links) ? JSON.parse(data.links) : [];
    }
    data.concernedUniversities = listUniversities.map((university) => {
      return `/api/universities/${university.id}`;
    });
    return data;
  };

  const fillFormFromDraft = (draft) => {
    reset({
      title: draft.title,
      chapo: draft.chapo,
      description: draft.description,
      public: draft.public,
      publishInMyName: draft.publishInMyName,
      category: draft.category
        ? categoriesData.data.find((object) => object.id === draft.category.id)
        : undefined,
    });
    if (
      isNotEmptyArray(draft.links) &&
      urlActu.length === 1 &&
      urlActu.at(0) == [""]
    ) {
      setUrlActu(draft.links);
    }
  };

  // variables du formulaire
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  /**
   * Fonction permettant d'assigner les données du formulaire
   * à une variable
   * @param {Object} data données du formulaire
   */
  const onSubmit = () => {
    var data = getValues();
    data = setFields(data);
    setWaitingLoader(true);
    if (isModifying) {
      putForm(data);
    } else {
      sendRequest(data);
    }
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div className="actu_formulaire">
      {isLoader ? (
        <Loader></Loader>
      ) : (
        <div className="actu_formulaire__container">
          <div className="actu_formulaire__title">
            <h1 className="h1_trait_dessus">
              {!isModifying ? "Ajouter une actualité" : ""}
            </h1>
            <Brouillon
              path="/news"
              create="/create"
              formValues={getValues}
              setFields={setFields}
              fillFormFromDraft={fillFormFromDraft}
              className="btn-bleu"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="top_actu">
              <div className="title_actu">
                <h2
                  htmlFor="title"
                  className={classNames({ "p-error": errors.title })}
                >
                  Titre de l&apos;actu *
                </h2>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Le titre est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      placeholder="Les nouvelles méthodes de recrutement"
                      autoFocus
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_actu")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("title")}
              </div>
              <div className="category_actu">
                <h2
                  htmlFor="category"
                  className={classNames({ "p-error": errors.category })}
                >
                  Catégorie de l&apos;actu
                </h2>
                {categories.length > 0 ? (
                  <Controller
                    name="category"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        id={field.label}
                        value={
                          isModifying ? currentCategory : getValues().category
                        }
                        onChange={(e) => {
                          setCurrentCategory(e.target.value);
                          field.onChange(e.value);
                        }}
                        editable
                        optionLabel="label"
                        options={categories}
                        placeholder="Conseil, international, témoignage"
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_actu")
                        }
                      />
                    )}
                  />
                ) : (
                  <Loader />
                )}
                {getFormErrorMessage("category")}
              </div>
            </div>
            <div className="middle_actu">
              <div className="chapo_actu">
                <h2
                  htmlFor="chapo"
                  className={classNames({ "p-error": errors.chapo })}
                >
                  Chapô de l&apos;actu *
                </h2>
                <Controller
                  name="chapo"
                  control={control}
                  rules={{
                    required: "Le chapô est requis",
                    minLength: {
                      message: "Le chapô doit faire au moins 12 caractères",
                      value: 12,
                    },
                    maxLength: {
                      message:
                        "Le chapô ne doit pas faire plus de 220 caractères",
                      value: 220,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={2}
                      cols={15}
                      placeholder="Chapô de l'actu..."
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_actu")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("chapo")}
              </div>
              <div className="description_actu">
                <h2
                  htmlFor="description"
                  className={classNames({ "p-error": errors.description })}
                >
                  Description de l&apos;actu *
                </h2>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "La description est requise",
                    minLength: {
                      message:
                        "La description doit faire au moins 12 caractères",
                      value: 12,
                    },
                  }}
                  render={({ field }) => (
                    <ReactQuill
                      id={field.name}
                      {...field}
                      theme="snow"
                      modules={QuillOptions.modules}
                      formats={QuillOptions.formats}
                      rows={5}
                    />
                  )}
                />
                {getFormErrorMessage("description")}
              </div>
            </div>
            <div className="url_container">
              {urlActu.map((item, indexUrl) => (
                <div className="url_actu" key={indexUrl}>
                  <h2> Ajouter un lien URL </h2>
                  <InputText
                    type="url"
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Entrez une url de type https://myurl.com (ou http)"
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    value={item}
                    placeholder="https://"
                    onChange={(e) => majUrl(e, indexUrl)}
                    className="input_actu"
                  />
                </div>
              ))}

              <div className="form_bouton_add" onClick={() => addUrl()}>
                <i className="pi pi-plus-circle" size="2rem" />
                <label>Ajouter un lien</label>
              </div>
            </div>
            <ChargementImage
              image={image}
              setImage={setImage}
              imageFile={imageFile}
              setImageFile={setImageFile}
              setImageId={setImageId}
              phototheque={phototheque}
              setPhototheque={setPhototheque}
              path="news"
              currentImage={props.value ? getValues("image") : null}
            />
            <div className="image_actu__buttons">
              <div className="image_actu__checkbox">
                <Controller
                  name="public"
                  control={control}
                  render={({ field }) => (
                    <React.Fragment>
                      <div className="private">
                        <Checkbox
                          checked={field.value == false}
                          onChange={() => field.onChange(false)}
                        />
                        <label htmlFor="actuPrivée">
                          <i className="pi pi-lock" /> Actu privée
                        </label>
                      </div>
                      <div className="public">
                        <Checkbox
                          checked={field.value == true}
                          onChange={() => field.onChange(true)}
                        />
                        <label htmlFor="actuPublique">
                          <i className="pi pi-lock-open" /> Actu publique
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="image_actu__buttons">
              <div className="image_actu__checkbox">
                <Controller
                  name="publishInMyName"
                  control={control}
                  render={({ field }) => (
                    <div className="prive">
                      <Checkbox
                        {...field}
                        id="publishInMyName"
                        checked={field.value == true}
                        onChange={() => field.onChange(!field.value)}
                      />
                      <label htmlFor="actuPublique">Publier en mon nom</label>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="bottom_actu">
              <h2
                htmlFor="concernedUniversities"
                className={classNames({
                  "p-error": errors.concernedUniversities,
                })}
              >
                Vers quel(s) établissement(s) souhaitez-vous diffuser
                l&apos;actualité ? *
              </h2>
              <Controller
                name="concernedUniversities"
                control={control}
                rules={{
                  validate: () =>
                    listUniversities.length > 0 ||
                    "Veuillez sélectionner au moins une université",
                }}
                render={() => (
                  <UniversitiesPanel
                    listUniversities={listUniversities}
                    setListUniversities={setListUniversities}
                    editUnivId={currentActu?.university?.id || null}
                  ></UniversitiesPanel>
                )}
              />
              {getFormErrorMessage("concernedUniversities")}
            </div>
            <div className="buttons_bottom">
              {isSubmitted && (
                <Modal
                  visible={isSubmitted}
                  setVisible={setSubmitted}
                  header={"Votre actualité a bien été soumise"}
                >
                  <i className="pi pi-send"></i>
                  <div className="description">
                    <p>
                      <center>
                        {
                          "Votre actualité a bien été enregistrée et envoyée à l'équipe Réseau Pro."
                        }
                      </center>
                    </p>
                    <>
                      <p>
                        <center>Elle sera publiée après validation</center>
                      </p>
                    </>
                  </div>
                  <div className="modals-buttons">
                    <BtnBleu
                      btnTexte="Déposer une nouvelle actualité"
                      btnAction={() => window.location.reload()}
                    />
                    <Link to={{ pathname: `/actualites` }}>
                      <BtnBlanc
                        btnTexte="Voir toutes mes actualités"
                        btnAction={() => {
                          props.handleMenu({
                            activeItemActu: props.items.itemsActu.findIndex(
                              (v) => v.label == "Gérer mes actus"
                            ),
                          });
                        }}
                      />
                    </Link>
                  </div>
                </Modal>
              )}
              {!waitingLoader ? (
                isModifying ? (
                  <button
                    className="btn-bleu publier_button"
                    type="Submit"
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit(getValues());
                    }}
                  >
                    {currentActu?.state?.statusLabel === "Refusé"
                      ? "Renouveler la demande"
                      : "Modifier l'actu"}
                  </button>
                ) : (
                  <button className="btn-bleu publier_button" type="Submit">
                    {"Soumettre l'actu"}
                  </button>
                )
              ) : (
                <button className="waiting_button btn-bleu">
                  <Loader></Loader>
                </button>
              )}
              <button
                className="btn-blanc annuler_button"
                onClick={() => {
                  isModifying
                    ? props.setVisible(false)
                    : (reset({
                        title: "",
                        category: "",
                        chapo: "",
                        description: "",
                      }),
                      setUrlActu([""]),
                      setImage(""),
                      setImageFile(""),
                      setImageId(""),
                      setPhototheque(true));
                }}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

ActualitesCreation.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  auth: PropTypes.object,
  value: PropTypes.object,
  setVisible: PropTypes.func,
  handleMenu: PropTypes.func,
  news: PropTypes.object,
  handleUpdateNews: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  news: state.news,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => dispatch(updateNews(value)),
  handleMenu: (value) => dispatch(updateMenu(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActualitesCreation);

import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  blacklist: ["offers", "users", "news", "events"],
  whitelist: ["items", "auth", "states", "secondaryTables", "universities"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(persistedReducer, composeWithDevTools());
export let persistor = persistStore(store);

import React from "react";
import "./ProfilBarShowApercu.scss";
import {
  FaMapMarkerAlt,
  FaRegCalendar,
  FaRegEnvelope,
  FaRegAddressBook,
  FaHandsHelping,
  FaCheck,
} from "react-icons/fa";
import facebook_logo from "assets/logo_facebook.webp";
import linkedin_logo from "assets/logo_linkedin.webp";
import twitter_logo from "assets/logo_twitter.webp";
import instagram_logo from "assets/logo_instagram.webp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "Components/Loader/loader";
import {
  formatDate,
  showError,
  showSuccess,
  truncate,
} from "Services/functions";
import neutralUser from "assets/neutralUser.png";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchPost, fetchPut, useFetchGet } from "Services/api";

const profilBarShowApercu = (props) => {
  const currentUser = props.user;

  // Si la photo vient de la photothèque on récupère les photos dans la base sinon rien
  const imageStocksData = currentUser.imageStockId
    ? useFetchGet("/image_stocks/users")
    : null;

  const onUpdate = async () => {
    //? Remove id de l'objet props.user
    let userObj = { ...props.user };
    delete userObj.id;

    const response = await fetchPut(
      `/recruiters/${props.auth.userConnected.id}`,
      userObj
    );
    if (response.error) {
      showError("Une erreur s'est produite lors de la modification du profil");
      return;
    }
    if (props.imageFile) {
      putPicture(props.imageFile);
    } else if (props.user.imageStockId) {
      putImageStock(props.user.imageStockId);
    } else {
      showSuccess("Votre profil a bien été modifié");
      props.handleUpdateAuth({ userConnected: { ...response.data } });
      window.location.reload(false);
    }
  };

  const putPicture = async (imageSrc) => {
    let formData = new FormData();
    formData.append("imageFile", imageSrc);

    const response = await fetchPost(
      `/recruiters/${props.auth.userConnected.id}/update_picture`,
      formData
    );
    if (response.error) {
      showError("Une erreur s'est produite lors de la modification du profil");
      return;
    }
    showSuccess("Votre profil a bien été modifié");
    props.handleUpdateAuth({ userConnected: response.data });
    window.location.reload(false);
  };

  const putImageStock = async (imageId) => {
    const response = await fetchPost(
      `/recruiters/${props.auth.userConnected.id}/update_image_stock`,
      { imageStockId: imageId }
    );
    if (response.error) {
      showError("Une erreur s'est produite lors de la modification du profil");
      return;
    }
    showSuccess("Votre profil a bien été modifié");
    props.handleUpdateAuth({ userConnected: response.data });
    window.location.reload(false);
  };

  const findImage = () => {
    // Si la photo vient de la photothèque on la récupère depuis les données de la base
    if (currentUser.imageStockId && imageStocksData.data) {
      let tempUrl = imageStocksData.data?.find(
        (x) => x.id === currentUser.imageStockId
      )?.resourceUrl;
      return tempUrl || neutralUser;
    }

    // Si la photo est une nouvelle photo depuis le PC
    if (props.imageFile?.objectURL?.includes("blob"))
      return props.imageFile.objectURL;

    // Si pas de changement on met la photo actuelle de l'utilisateur
    if (currentUser.imageUrl) return currentUser.imageUrl;

    return neutralUser;
  };
  return (
    <>
      {currentUser ? (
        <>
          <div className="profilBarShowApercu">
            <div className="profilBarShowApercu__top">
              <div className="profilBarShowApercu__top__image">
                {(!currentUser.imageStockId || imageStocksData.loaded) && (
                  <img src={findImage()} />
                )}
              </div>
              <div className="profilBarShowApercu__top__middle">
                <h2>
                  {currentUser?.firstname} {currentUser?.surname}
                </h2>
                <h3>{currentUser?.profilTitle}</h3>
                <p>
                  {currentUser.profilDescription && (
                    <>{truncate(currentUser?.profilDescription, 350)}</>
                  )}
                </p>
                {currentUser.mentorAccept && (
                  <div className="profilBarShowApercu__top__middle__parrain">
                    <p>
                      <FaHandsHelping /> Parrain/Marraine
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="profilBarShowApercu__rightApercu">
              <div className="profilBarShowApercu__rightApercu__item">
                <label>
                  <FaRegCalendar />
                  Date de naissance :
                </label>
                <p>
                  {currentUser.birthday
                    ? formatDate(currentUser.birthday)
                    : "Non renseignée"}
                </p>
              </div>
              <div className="profilBarShowApercu__rightApercu__item">
                <label>
                  <FaMapMarkerAlt />
                  Adresse postale :
                </label>
                <p>
                  {currentUser.address ? currentUser.address : "Non renseignée"}
                  , {currentUser.city ? currentUser.city : "Non renseignée"},{" "}
                  {currentUser.country ? currentUser.country : "Non renseigné"}
                </p>
              </div>
              <div className="profilBarShowApercu__rightApercu__item">
                <label>
                  <FaRegEnvelope />
                  Adresse mail :
                </label>
                <p>
                  {currentUser.email ? currentUser.email : "Non renseignée"}
                </p>
              </div>
              <div className="profilBarShowApercu__rightApercu__item">
                <label>
                  <FaRegAddressBook />
                  Tel :
                </label>
                <p>
                  {currentUser.telephone
                    ? currentUser.telephone
                    : "Non renseigné"}
                </p>
              </div>
              <div className="profilBarShowApercu__rightApercu__logos">
                {currentUser.facebookAccount && (
                  <a
                    href={
                      // On vérifie si le lien commence par http ou https
                      currentUser.facebookAccount.startsWith("http")
                        ? currentUser.facebookAccount
                        : "https://" + currentUser.facebookAccount
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook_logo} />
                  </a>
                )}
                {currentUser.linkedinAccount && (
                  <a
                    href={
                      // On vérifie si le lien commence par http ou https
                      currentUser.linkedinAccount.startsWith("http")
                        ? currentUser.linkedinAccount
                        : "https://" + currentUser.linkedinAccount
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin_logo} />
                  </a>
                )}
                {currentUser.twitterAccount && (
                  <a
                    href={
                      // On vérifie si le lien commence par http ou https
                      currentUser.twitterAccount.startsWith("http")
                        ? currentUser.twitterAccount
                        : "https://" + currentUser.twitterAccount
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter_logo} />
                  </a>
                )}
                {currentUser.instagramAccount && (
                  <a
                    href={
                      // On vérifie si le lien commence par http ou https
                      currentUser.instagramAccount.startsWith("http")
                        ? currentUser.instagramAccount
                        : "https://" + currentUser.instagramAccount
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagram_logo} />
                  </a>
                )}
              </div>
            </div>
          </div>
          <button
            className="btn-blanc profilBarShowApercu__button"
            onClick={() => onUpdate()}
          >
            <FaCheck />
            <span>Valider</span>
          </button>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

profilBarShowApercu.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  handleUpdateAuth: PropTypes.func,
  imageFile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(profilBarShowApercu);

/**
 * Récupère la liste des pays en français
 * grâce au package i18n-iso-countries (singleton)
 * @link https://www.npmjs.com/package/i18n-iso-countries
 * @type {function(): *}
 * @returns {[string]} Liste des pays
 */
export let retrieveCountries = (() => {
  let countries = null;

  function setup() {
    try {
      const countriesPackage = require("i18n-iso-countries");
      countriesPackage.registerLocale(
        require("i18n-iso-countries/langs/fr.json")
      );
      // Only get the value of the key instead of an object
      return Object.values(
        countriesPackage.getNames("fr", { select: "official" })
      ).sort((a, b) => a.localeCompare(b));
    } catch (e) {
      console.error("Error loading countries : ", e);
      return [];
    }
  }

  return () => {
    if (!countries) {
      countries = setup();
    }
    return countries;
  };
})();

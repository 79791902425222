import React, { useState, useEffect, useRef } from "react";
import "./EvenementsManagement.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Evenement from "../Evenement/Evenement";
import EvenementManagementCard from "./EvenementManagementCard/EvenementManagementCard";
import { updateEvents } from "Redux/Actions/eventsActions";
import { formatDateFrenchLocale } from "Services/functions";
import Management from "Components/Management/Management";
import Loader from "Components/Loader/loader";
import { Link } from "react-router-dom";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";
/**
 * Permet d'afficher la page de gestion des événements
 */
const EvenementsManagement = (props) => {
  const [url, setUrl] = useState(null);
  useFetchGetPagination(url, props.handleUpdateEvents);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const EventMngRef = useRef(null);

  const [states, setStates] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const evenementsContainerRef = useRef(null);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************
  useEffect(() => {
    return () => {
      props.handleUpdateEvents({ dataFromPagination: null });
    };
  }, []);

  // UseEffect de chargement
  useEffect(() => {
    /// Récupérer les states, et les filtrer par ordre alphabétique
    if (props.events.etats && props.events.etats.length > 0) {
      setStates([]);
      let tempArray = [];
      props.events.etats.forEach((state) => tempArray.push(state));
      tempArray = tempArray
        .filter((state) => state.statusLabel !== "Supprimé")
        .sort((a, b) => a.name?.localeCompare(b.name));
      setStates(tempArray);
    }
  }, [props.events.etats]);

  // Hook qui se déclenche quand la liste des offres change
  useEffect(() => {
    // Charge la dernière offre en fonction de l'emetteur choisi
    url?.href?.includes("page=1") &&
      setLastEvent(props.events.dataFromPagination?.data[0]);
  }, [props.events.dataFromPagination?.data]);

  return (
    <div className="gestion_evenements" ref={EventMngRef}>
      <div className="gestion_evenements__title">
        <h1 className="h1_trait_dessus">{"Gérer mes événements"}</h1>
        <Management
          states={states}
          actualPage={"événement"}
          updateFunction={setUrl}
        ></Management>
      </div>
      <div className="gestion_evenements__body">
        <div className="gestion_evenements__body__left">
          <h3>Dernier événement créé</h3>
          {/* Affichage du dernier evenement */}
          {lastEvent !== null && lastEvent !== undefined && (
            <Link
              to={{
                pathname: `/event-detail/${lastEvent.id}`,
                search: "?r=true",
              }}
            >
              <Evenement
                categorie={lastEvent.category}
                debutDate={formatDateFrenchLocale(lastEvent.startingAt)}
                finDate={formatDateFrenchLocale(lastEvent.endingAt)}
                inscription={lastEvent.fulfilled}
                title={lastEvent.title}
                chapeau={lastEvent.description}
                eventDetails={lastEvent}
                url={lastEvent?.imageUrl ? lastEvent.imageUrl : "event1"}
                registerEnd={lastEvent.registerEnd}
              />
            </Link>
          )}
        </div>
        <div
          className="gestion_evenements__body__right"
          ref={evenementsContainerRef}
        >
          {/* Tant que la data n'est pas chargé on affiche un loader */}
          {props.events.dataFromPagination?.loaded > 0 ? (
            props.events.dataFromPagination.data?.length > 0 ? (
              props.events.dataFromPagination.data
                .filter(
                  (event) =>
                    event.state.statusLabel !== "Brouillon" ||
                    event.createdBy.split("/")[3] == props.auth.userConnected.id
                )
                .map((event, index) => (
                  <EvenementManagementCard
                    titre={event.title}
                    dateDebut={formatDateFrenchLocale(event.startingAt)}
                    dateFin={formatDateFrenchLocale(event.endingAt)}
                    participants={event.eventParticipants}
                    statut={event.state}
                    url={event?.imageUrl ? event.imageUrl : "event2"}
                    key={index}
                    id={event.id}
                    feedback={event.feedback?.feedback}
                    universityName={event.university?.name ?? "Inconnue"}
                  />
                ))
            ) : (
              <span>Aucun événement ne correspond à votre recherche</span>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <TempPagination
        result={props.events.dataFromPagination}
        refItemParent={evenementsContainerRef}
        rows={20}
      />
    </div>
  );
};

EvenementsManagement.propTypes = {
  auth: PropTypes.object,
  events: PropTypes.any,
  handleUpdateEvents: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementsManagement);

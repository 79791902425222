import { UPDATE_MENU } from "../Actions/menuActions";

const INITIAL_STATE = {
  itemsProfil: [
    {
      label: "Informations personnelles",
      icon: "pi pi-fw pi-user-edit",
    },
    {
      label: "Paramètres de confidentialité",
      icon: "pi pi-fw pi-lock",
    },
  ],
  activeItemProfil: 0,
  itemsCVTheque: [
    {
      label: "Cvthèque",
      icon: "pi pi-fw pi-users",
    },
    {
      label: "Cv sauvegardés",
      icon: "pi pi-fw pi-bookmark",
    },
    {
      label: "Paniers de cv",
      icon: "pi pi-fw pi-calendar",
    },
  ],
  activeItemCVTheque: 0,
  itemsOffre: [
    {
      label: "Déposer une offre",
      icon: "pi pi-fw pi-briefcase",
    },
    // ------------- évolution
    // {
    //   label: "Déposer un projet tutoré",
    //   icon: "pi pi-fw pi-users",
    // },
    {
      label: "Gérer mes offres",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Candidatures reçues",
      icon: "pi pi-fw pi-user-plus",
    },
  ],
  activeItemOffre: 0,

  itemsEvenement: [
    {
      label: "Tous les événements",
      icon: "pi pi-fw pi-calendar",
    },
    {
      label: "Proposer un événement",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer mes événements",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemEvenement: 0,

  itemsActu: [
    {
      label: "Toutes les actus",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Proposer une actu",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer mes actus",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemActu: 0,
};

const menuReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_MENU: {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
};

export default menuReducer;

import React from "react";
import PropTypes from "prop-types";

/**
 * Composant custom pour générer des marges
 * en fonction de props qui lui sont passées ou non
 *
 * @return Une div avec un className qui rajoutera le style
 * grâce à Stats.scss
 *
 */

const Margin = (props) => {
  var className =
    "stats_m" +
    (props.position === "top" ? "t" : "b") +
    (props.size === "" ? "" : "-" + props.size);

  return <div className={className}></div>;
};

Margin.defaultProps = {
  position: "top",
  size: "",
};

Margin.propTypes = {
  position: PropTypes.string,
  size: PropTypes.string,
};

export default Margin;

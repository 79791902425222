import { updateUsers } from "Redux/Actions/usersActions";
import { useFetchGet } from "Services/api";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Chiffre from "../Chiffre/Chiffre";
import "./ChiffreContainer.scss";

/*
 * Permet d'afficher les chiffres de Réseau Pro qui sont sur la page d'accueil avant connexion
 */
const ChiffreContainer = () => {
  const [data, setData] = useState([{ title: "", description: "" }]);

  const companiesQuery = useFetchGet("/companies/count_total");
  const recruitersQuery = useFetchGet("/recruiters/count_total");

  useEffect(() => {
    // var publishedOffersNumber = 0;
    var companiesNumber = 0;
    var membersNumber = 0;

    if (companiesQuery.loaded) companiesNumber = companiesQuery.data;
    if (recruitersQuery.loaded) membersNumber = recruitersQuery.data;

    setData([
      {
        title: membersNumber?.toString() || "0",
        description: "RECRUTEURS",
      },
      {
        title: companiesNumber?.toString() || "0",
        description: "ENTREPRISES",
      },
    ]);
  }, [companiesQuery.loaded, recruitersQuery.loaded]);

  return (
    <div className="chiffres" id="chiffresCles">
      <div className="chiffres__column">
        {data.map((chiffre) => (
          <Chiffre
            key={chiffre.description}
            title={chiffre.title}
            description={chiffre.description}
          />
        ))}
      </div>
    </div>
  );
};

ChiffreContainer.propTypes = {
  users: PropTypes.any,
  offers: PropTypes.any,
  states: PropTypes.any,
  companies: PropTypes.any,
  handleUpdateUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  users: state.users,
  offers: state.offers,
  companies: state.companies,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChiffreContainer);

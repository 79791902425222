export const HTMLParseOptions = {
  // On transforme les balises <a> pour qu'elles ouvrent dans un nouvel onglet
  replace: (domNode) => {
    if (domNode.name === "a") {
      domNode.attribs.target = "_blank";
      domNode.attribs.rel = "noopener noreferrer";
      // On ajoute la classe "underline" pour les liens
      domNode.attribs.class = "underline";
      // On rajoute http:// ou https:// si besoin
      if (
        !domNode.attribs.href.startsWith("http://") &&
        !domNode.attribs.href.startsWith("https://")
      )
        domNode.attribs.href = `http://${domNode.attribs.href}`;
    }
  },
};

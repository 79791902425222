import React from "react";
import "./btn-blanc.scss";
import PropTypes from "prop-types";

const BtnBlanc = (props) => {
  return (
    <button className="btn-blanc" onClick={props.btnAction}>
      {props.btnTexte}
      {props.children}
    </button>
  );
};

BtnBlanc.propTypes = {
  btnAction: PropTypes.func,
  btnTexte: PropTypes.string,
  children: PropTypes.any,
};

export default BtnBlanc;

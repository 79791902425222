import { UPDATE_UNIVERSITIES } from "Redux/Actions/universitiesActions";

const INITIAL_STATE = {
  universitiesData: [],
};

const universitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_UNIVERSITIES: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default universitiesReducer;

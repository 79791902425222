import Header from "Components/Header/Header";
import React, { useRef, useEffect } from "react";
import "./ActualiteDetail.scss";
import event1 from "assets/event1.webp";
import Footer from "Components/Footer/Footer";
import EventActuConseilRandom from "Components/EventActuConseilRandom/EventActuConseilRandom";
import { useParams } from "react-router-dom";
import { formatDate, isNotEmptyArray } from "Services/functions";
import Loader from "Components/Loader/loader";
import { useFetchGet } from "Services/api";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { QuillNoToolbarOptions } from "Components/QuillOptions/QuillOptions";
import { sanitize } from "dompurify";

/**
 * Permet d'afficher la page de détail d'une actualité
 */
const ActualiteDetail = () => {
  const paramRecruteur = new URLSearchParams(window.location.search).get("r");
  const isMine = paramRecruteur === "true";

  let { id } = useParams();
  const currentNews = useFetchGet(
    `/news/${id}`,
    null,
    isMine ? "univ" : "nexus"
  );

  // remplissage de Redux avec les données des actualités
  const newsDetail = useRef(null);
  useEffect(() => {
    if (newsDetail.current !== null) {
      window.scroll({
        top: newsDetail.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [currentNews.loaded]);

  return (
    <div className="actualite_detail_container">
      <Header />
      <h1 className="actualite_detail_container__title h1_trait_dessus">
        Détail de l&apos;actu
      </h1>
      {currentNews && currentNews.loaded ? (
        currentNews.error ? (
          <div className="actualite_detail_container-error">
            <h1> Vous n&apos;avez pas les droits de voir cette actualité.</h1>
          </div>
        ) : (
          <div className="actualite_detail_container__top" ref={newsDetail}>
            <div className="actualite_detail_container__top__body">
              <h5 className="actualite_detail_container__top__body__categorie">
                Catégorie : {currentNews?.data?.category?.label}
              </h5>
              <h1>{currentNews?.data.title}</h1>
              <h5 className="actualite_detail_container__top__body__chapo">
                {parse(currentNews?.data.chapo)}
              </h5>
              {currentNews.data.imageUrl ? (
                <img src={currentNews.data.imageUrl} alt="Fond news" />
              ) : (
                <img src={event1} alt="Fond news" />
              )}
              <div className="actualite_detail_container__top__body__texte">
                <h5 className="actualite_detail_container__top__body__texte__date">
                  Le {formatDate(currentNews.data.publishedAt, "/")}{" "}
                  {currentNews.data?.publishInMyName &&
                    currentNews.data?.firstnameAndLastnameToDisplay &&
                    `par ${currentNews.data?.firstnameAndLastnameToDisplay}`}
                </h5>
                <p className="actualite_detail_container__top__body__texte__description">
                  <ReactQuill
                    value={sanitize(currentNews.data.description)}
                    readOnly={true}
                    theme="snow"
                    modules={QuillNoToolbarOptions.modules}
                    formats={QuillNoToolbarOptions.formats}
                  />
                </p>
                {isNotEmptyArray(currentNews.data.links) && (
                  <h5 className="actualite_detail_container__top__body__texte__liens">
                    <span>Liens:</span>
                    {currentNews.data.links.map((link) => (
                      <>
                        <br />
                        <a
                          className="actualite_detail_container__top__body__texte__liens-url"
                          href={
                            // On regarde si le lien commence par http ou https
                            link.startsWith("https")
                              ? link
                              : link.startsWith("http")
                              ? link
                              : `https://${link}`
                          }
                          target="_blank"
                          rel="noreferrer"
                          key={link}
                        >
                          {link}
                        </a>
                      </>
                    ))}
                  </h5>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <Loader />
      )}

      <div className="actualite_detail_container__bottom">
        <div className="actualite_detail_container__bottom__title">
          <h1>Autres éléments que vous pouvez consulter</h1>
        </div>
        <EventActuConseilRandom new={currentNews.data} />
        <div className="actualite_detail_container__bottom__event"></div>
      </div>
      <Footer />
    </div>
  );
};

ActualiteDetail.propTypes = {
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ActualiteDetail);

import React, { useState, useEffect, useRef } from "react";
import "./EvenementsContainer.scss";
import Evenement from "../Evenement/Evenement";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { FaChevronRight } from "react-icons/fa"; //FaRegCalendarCheck
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDateDatabase, formatDateFrenchLocale } from "Services/functions";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateAuth } from "Redux/Actions/authActions";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import Filters from "Components/Filters/Filters";
registerLocale("fr", fr);

const EvenementsContainer = (props) => {
  const navigate = useNavigate();
  const [loginVisible, setLoginVisible] = useState(false);
  const [clickedEventId, setClickedEventId] = useState(null);
  const [clickedUniversityId, setClickedUniversityId] = useState(null);

  const evenementContainerRef = useRef(null);
  const categoriesData = useFetchGet("/event_categories");
  const [categories, setCategories] = useState([]);
  const [filtreCategory, setFiltreCategory] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreDate, setFiltreDate] = useState(null);
  const [isKeywordFiltering, setIsKeywordFiltering] = useState(false);
  const [eventsUrl, setEventsUrl] = useState("");

  const eventsQuery = useFetchGetPagination(
    eventsUrl,
    props.handleUpdateEvents
  );

  useEffect(() => {
    return () => {
      props.handleUpdateEvents({
        dataFromPagination: null,
      });
    };
  }, []);

  useEffect(() => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_NEXUS_URL_API}/events`);
      url.searchParams.set("public", 1);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      props.pageEvents === "accueil" && url.searchParams.set("itemsPerPage", 3);
      url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
      url.searchParams.set("order[startingAt]", "asc");
      setEventsUrl(url);
    }
  }, [props.states.statesData.length]);

  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (categoriesData.loaded) {
      setCategories([]);
      let tempArray = [];
      categoriesData?.data.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [categoriesData.loaded]);

  const handleFilter = () => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_NEXUS_URL_API}/events`);
      url.searchParams.set("public", 1);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
      url.searchParams.set("order[startingAt]", "asc");
      if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
      if (filtreCategory) url.searchParams.set("category", filtreCategory.id);
      if (filtreDate && filtreDate[0])
        url.searchParams.set(
          "startingAt[after]",
          formatDateDatabase(filtreDate[0])
        );
      if (filtreDate && filtreDate[1])
        url.searchParams.set(
          "endingAt[before]",
          formatDateDatabase(filtreDate[1])
        );

      setEventsUrl(url);
    }
  };

  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [filtreCategory, filtreDate, isKeywordFiltering]);

  return (
    <div className="evenements_container">
      <div className="evenements_all">
        <div className="evenements_container__top">
          {props.pageEvents == "accueil" ? (
            // si on est dans la page d'accueil on n'affiche pas les filtres
            <h1 className="h1_trait_dessus">
              {props.title ? props.title : "les événements"}
            </h1>
          ) : (
            <div>
              {/* si on est sur la page Evenements on affiche les filtres */}
              <div className="evenements_container__top__title">
                <h1 className="h1_trait_dessus">
                  {props.title ? props.title : "Tous les événements"}
                </h1>
                <div className="evenements_container__top__title__visible">
                  {categories.length > 0 ? (
                    <Filters
                      filtreMotsCles={filtreMotsCles}
                      setFiltreMotsCles={setFiltreMotsCles}
                      filtreDate={filtreDate}
                      setFiltreDate={setFiltreDate}
                      filtreDropdown={filtreCategory}
                      setFiltreDropdown={setFiltreCategory}
                      dataDropdown={categories}
                      setIsKeywordFiltering={setIsKeywordFiltering}
                      filterFunction={handleFilter}
                    ></Filters>
                  ) : (
                    <Loader></Loader>
                  )}
                </div>
                <Accordion
                  className="accordion_events"
                  expandIcon="pi pi-sliders-h"
                  collapseIcon="pi pi-sliders-h"
                >
                  <AccordionTab header="Filtres de recherche">
                    <Filters
                      filtreMotsCles={filtreMotsCles}
                      setFiltreMotsCles={setFiltreMotsCles}
                      filtreDate={filtreDate}
                      setFiltreDate={setFiltreDate}
                      filtreDropdown={filtreCategory}
                      setFiltreDropdown={setFiltreCategory}
                      dataDropdown={categories}
                      setIsKeywordFiltering={setIsKeywordFiltering}
                      filterFunction={handleFilter}
                    ></Filters>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          )}
        </div>

        <div className="evenements_container__body" ref={evenementContainerRef}>
          {eventsQuery?.loaded && categories?.length > 0 ? (
            eventsQuery?.data?.length > 0 ? (
              (props.nbEvents && props.nbEvents != 20
                ? eventsQuery.data.slice(0, props.nbEvents)
                : eventsQuery.data
              ).map((event, index) =>
                props.auth.userConnected || event.public ? (
                  <Link
                    to={{
                      pathname: `/event-detail/${event.id}`,
                      params: event.id,
                    }}
                    key={index}
                  >
                    <Evenement
                      key={index}
                      categorie={
                        event.eventCategoryLabel
                          ? { label: event.eventCategoryLabel }
                          : event.category
                      }
                      inscription={event.fulfilled}
                      debutDate={formatDateFrenchLocale(
                        event.startingAt.date || event.startingAt
                      )}
                      finDate={formatDateFrenchLocale(
                        event.endingAt.date || event.endingAt
                      )}
                      title={event.title}
                      chapeau={event.description}
                      eventDetails={event}
                      url={event?.imageUrl ? event.imageUrl : "event1"}
                      registerEnd={event.registerEnd}
                    />
                  </Link>
                ) : (
                  <Evenement
                    key={index}
                    categorie={
                      event.eventCategoryLabel
                        ? { label: event.eventCategoryLabel }
                        : event.category
                    }
                    inscription={event.fulfilled}
                    debutDate={formatDateFrenchLocale(
                      event.startingAt.date || event.startingAt
                    )}
                    finDate={formatDateFrenchLocale(
                      event.endingAt.date || event.endingAt
                    )}
                    title={event.title}
                    chapeau={event.description}
                    eventDetails={event}
                    url={event?.imageUrl ? event.imageUrl : "event1"}
                    onClick={() => {
                      setLoginVisible(true);
                      setClickedUniversityId(event.university.id);
                      setClickedEventId(event.id);
                    }}
                    registerEnd={event.registerEnd}
                  />
                )
              )
            ) : (
              <span style={{ textAlign: "center" }}>
                {props.pageEvents
                  ? "Aucun événement disponible"
                  : "Aucun événement ne correspond à votre recherche"}
              </span>
            )
          ) : (
            <Loader></Loader>
          )}
        </div>
        {loginVisible && (
          <HeaderUserLogin
            key={loginVisible + clickedEventId}
            loginVisible={loginVisible}
            setLoginVisible={setLoginVisible}
            redirect={clickedEventId ? `/event-detail/${clickedEventId}` : null}
            universityId={clickedUniversityId}
          />
        )}
        <div className="evenements_container__button">
          {props.pageEvents == "accueil" ? (
            <BtnBleu
              btnTexte="Voir tous les événements"
              btnAction={() => navigate("/evenements")}
            >
              <FaChevronRight />
            </BtnBleu>
          ) : (
            <TempPagination
              result={eventsQuery}
              refItemParent={evenementContainerRef}
              rows={50}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EvenementsContainer.propTypes = {
  auth: PropTypes.object,
  nbEvents: PropTypes.number,
  pageEvents: PropTypes.string,
  filtres: PropTypes.array,
  events: PropTypes.any,
  title: PropTypes.string,
  registerEnd: PropTypes.string,
  handleUpdateEvents: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  items: PropTypes.object,
  handleUpdateMenu: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  pagination: state.pagination,
  items: state.items,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementsContainer);

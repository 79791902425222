import React from "react";
import "./EntreprisesCards.scss";
import PropTypes from "prop-types";
import logoOffre1 from "assets/logoOffre1.webp";
import logoOffre2 from "assets/logoOffre2.webp";
import logoOffre3 from "assets/logoOffre3.webp";

import entrepriseFond1 from "assets/entrepriseFond1.webp";
import entrepriseFond2 from "assets/entrepriseFond2.webp";
import entrepriseFond3 from "assets/entrepriseFond3.webp";
import { FaSuitcase, FaUsers } from "react-icons/fa";
import "primeicons/primeicons.css";

const EntreprisesCards = (props) => {
  return (
    <div className="entreprises_cards">
      <div className="entreprises_cards__top">
        {props.imageFond == "entrepriseFond1" && (
          <img src={entrepriseFond1} alt="Fond d'entreprise" />
        )}
        {props.imageFond == "entrepriseFond2" && (
          <img src={entrepriseFond2} alt="Fond d'entreprise" />
        )}
        {props.imageFond == "entrepriseFond3" && (
          <img src={entrepriseFond3} alt="Fond d'entreprise" />
        )}
        <div className="entreprises_cards__top__logo">
          {props.imageLogo == "entreprise1" && (
            <img src={logoOffre1} alt="Logo d'entreprise" />
          )}
          {props.imageLogo == "entreprise2" && (
            <img src={logoOffre2} alt="Logo entreprise" />
          )}
          {props.imageLogo == "entreprise3" && (
            <img src={logoOffre3} alt="Logo entreprise" />
          )}
        </div>
      </div>
      <div className="entreprises_cards__corps">
        <h3>{props.nomEntreprise}</h3>
        <p>
          <i className="pi pi-building"></i>
          {props.type}
        </p>
        <p>
          <FaUsers />
          {props.taille}
        </p>
      </div>
      <div className="entreprises_cards__bottom">
        {props.offresDisponibles && (
          <button className="btn_card">
            <FaSuitcase /> Voir les offres disponibles
          </button>
        )}
      </div>
    </div>
  );
};

EntreprisesCards.propTypes = {
  nomEntreprise: PropTypes.string,
  type: PropTypes.string,
  taille: PropTypes.string,
  imageLogo: PropTypes.string,
  imageFond: PropTypes.string,
  offresDisponibles: PropTypes.bool,
};

export default EntreprisesCards;

import React, { useEffect, useState } from "react";
import "./Actualites.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ActualitesContainer from "Components/Actualités/ActualitesContainer/ActualitesContainer";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ActualitesManagement from "Components/Actualités/ActualitesManagement/ActualitesManagement";
import { updateNews } from "Redux/Actions/newsActions";
import ActualitesCreation from "Components/Actualités/ActualitesCreation/ActualitesCreation";

const Actualites = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);
  const [filtre, setFiltre] = useState(null);
  const filtres = [
    { label: "Tous", value: "Tous" },
    { label: "Conseil", value: "Conseil" },
    { label: "Accompagnement", value: "En attente" },
    { label: "International", value: "International" },
  ];
  useEffect(() => {
    if (props.states?.statesData?.length > 0) {
      const dataNewsStatusModified = props.states.statesData.map((state) => {
        return {
          id: state.id,
          statusLabel: state.statusLabel,
          name: state.statusLabel,
        };
      });
      const listOfStatus = ["Brouillon", "Refusé", "En Attente", "Publié"];

      props.handleUpdateNews({
        etats: dataNewsStatusModified.filter(
          (state) => listOfStatus.indexOf(state.name) !== -1
        ),
      });
    }
  }, [props.states?.statesData?.length]);

  return (
    <div className="actus">
      {props.auth.isConnected ? (
        <Header noImage={false}>
          <NavBarTab
            select="actu"
            activeIndex={props.items.activeItemActu}
            items={props.items.itemsActu}
          />
        </Header>
      ) : (
        <Header noImage={true}>
          <ButtonsBarDisconnected />
        </Header>
      )}

      {!props.auth.isConnected && (
        <ActualitesContainer
          filtre={filtre}
          setFiltre={setFiltre}
          filtres={filtres}
        />
      )}
      {props.auth.isConnected && (
        <>
          {props.items.activeItemActu ==
            props.items.itemsActu.findIndex(
              (v) => v.label == "Toutes les actus"
            ) && (
            <ActualitesContainer
              activeIndex={props.items.itemsActu.findIndex(
                (v) => v.label == "Toutes les actus"
              )}
              setActiveIndex={setActiveIndex}
              items={props.items.itemsActu}
            />
          )}
          {props.items.activeItemActu ==
            props.items.itemsActu.findIndex(
              (v) => v.label == "Proposer une actu"
            ) && (
            <ActualitesCreation
              activeIndex={props.items.itemsActu.findIndex(
                (v) => v.label == "Proposer une actu"
              )}
              setActiveIndex={setActiveIndex}
              items={props.items.itemsActu}
            />
          )}
          {props.items.activeItemActu ==
            props.items.itemsActu.findIndex(
              (v) => v.label == "Gérer mes actus"
            ) && (
            <ActualitesManagement
              activeIndex={props.items.itemsActu.findIndex(
                (v) => v.label == "Gérer mes actus"
              )}
              setActiveIndex={setActiveIndex}
              items={props.items.itemsActu}
            />
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

Actualites.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateNews: PropTypes.func,
  states: PropTypes.object,
  news: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  news: state.news,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => {
    dispatch(updateNews(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Actualites);

import Modal from "Components/Modal/Modal";
import neutralUser from "assets/neutralUser.png";
import { ConfirmDialog } from "primereact/confirmdialog";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import CandidatureDetail from "./CandidatureDetail/CandidatureDetail";
import "./CandidaturesCard.scss";

const CandidaturesCard = (props) => {
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  return (
    <div className="card_container cardHover">
      <div className="card_container__top">
        <h2 className="h2_refe">{props.refe}</h2>
        <div className="time-trash">
          <h3 className="h3_time" title={props.time} alt={props.time}>
            {props.timeDifference}{" "}
          </h3>
          <div onClick={() => setVisibleConfirm(true)}>
            <FaTrashAlt />
          </div>
        </div>
      </div>
      <div className="card_container__body">
        {!props.image ? (
          <img src={neutralUser} alt="Fond événement" className="imgA" />
        ) : (
          <img
            src={props.image}
            alt="Fond événement"
            className="imgA"
            onError={(e) => {
              e.target.src = neutralUser;
            }}
          />
        )}
        <div className="details">
          <h2 className="h2_name">{props.name}</h2>
          <h3 className="h3_poste">{props.poste}</h3>
        </div>
      </div>
      <div className="card_container__bottom">
        <div
          className="show_candidature"
          onClick={() => setVisibleDetail(true)}
        >
          {" "}
          Voir la candidature
        </div>
      </div>
      {visibleDetail && (
        <Modal
          visible={visibleDetail}
          setVisible={setVisibleDetail}
          header="Détail de la candidature"
          width="50svh"
        >
          <CandidatureDetail
            id={props.id}
            university={props.university}
            message={props.message}
          ></CandidatureDetail>
        </Modal>
      )}
      {visibleConfirm && (
        <ConfirmDialog
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          visible={visibleConfirm}
          message="Grace à cette action, le candidat sera automatiquement informé qu'aucune suite ne sera donnée à sa candidature"
          acceptLabel="Confirmer"
          rejectLabel="Annuler"
          accept={() => props.onDelete(props.id)}
          reject={() => setVisibleConfirm(false)}
          onHide={() => setVisibleConfirm(false)}
          className="confirm_delete_candidature"
        ></ConfirmDialog>
      )}
    </div>
  );
};

CandidaturesCard.propTypes = {
  refe: PropTypes.string,
  timeDifference: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string,
  poste: PropTypes.string,
  message: PropTypes.string,
  image: PropTypes.any,
  id: PropTypes.number,
  university: PropTypes.object,
  onDelete: PropTypes.func,
};

export default CandidaturesCard;

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { CgClose } from "react-icons/cg";
import { HiSearch } from "react-icons/hi";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Filters.scss";

const Filters = (props) => {
  return (
    <div className="filters__bar">
      <div className="filters__bar__container">
        <span className="filters__bar__container__searchInput">
          <HiSearch
            className="searchBar__icon"
            onClick={(e) => {
              e.preventDefault();
              props.setIsKeywordFiltering(true);
              props.filterFunction();
            }}
          />
          <InputText
            placeholder="Rechercher un élément"
            value={props.filtreMotsCles}
            className="membership_input_text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                props.setIsKeywordFiltering(true);
                props.filterFunction();
              }
            }}
            onChange={(e) => {
              props.setFiltreMotsCles(e.target.value.toLowerCase());
            }}
          />
        </span>
        <span className="filters__bar__container__dropdown">
          {props.dataDropdown.length > 0 && (
            <Dropdown
              value={props.filtreDropdown}
              optionLabel={
                props.dropdownOption ? props.dropdownOption : "label"
              }
              showClear
              options={props.dataDropdown}
              onChange={(e) => {
                props.setFiltreDropdown(e.target.value);
              }}
              placeholder={
                props.placeholder ? props.placeholder : "Filtrer par catégorie"
              }
            />
          )}
        </span>
        {props.auth.isConnected && (
          <>
            <div
              className={
                !props.filtreDate ||
                (props.filtreDate[0] && props.filtreDate[1])
                  ? "filters__bar__container__date"
                  : "filters__bar__container__date warning"
              }
            >
              <ReactDatePicker
                placeholderText="Filtrer par date"
                selected={props.filtreDate && props.filtreDate[0]}
                onChange={(e) => {
                  props.setFiltreDate(e);
                }}
                dateFormat="dd/MM/yyyy"
                startDate={props.filtreDate && props.filtreDate[0]}
                endDate={
                  props.filtreDate && props.filtreDate[1] && props.filtreDate[1]
                }
                selectsRange
              />
              {props.filtreDate !== null && (
                <CgClose
                  onClick={() => {
                    props.setFiltreDate(null);
                  }}
                ></CgClose>
              )}
            </div>
          </>
        )}
        {(props.filtreDropdown ||
          (props.filtreDate && props.filtreDate[1]) ||
          props.filtreMotsCles) && (
          <li className="filters__bar__container__refresh">
            <i
              className="pi pi-refresh"
              style={{ fontSize: "1.5rem", fontWeight: "600" }}
              onClick={() => {
                props.setFiltreDropdown(null);
                props.setFiltreDate(null);
                props.setFiltreMotsCles("");
                props.setIsKeywordFiltering(false);
              }}
            />
          </li>
        )}
      </div>
    </div>
  );
};

Filters.propTypes = {
  auth: PropTypes.object,
  dataDropdown: PropTypes.array,
  placeholder: PropTypes.string,
  dropdownOption: PropTypes.string,

  filtreMotsCles: PropTypes.object,
  filtreDate: PropTypes.object,
  filtreDropdown: PropTypes.object,

  setFiltreDate: PropTypes.func,
  setFiltreMotsCles: PropTypes.func,
  setFiltreDropdown: PropTypes.func,
  setIsKeywordFiltering: PropTypes.func,
  filterFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

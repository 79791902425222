import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchDelete, fetchPost } from "Services/api";
import { showError, showSuccess } from "Services/functions";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import "./Universite.scss";

const Universite = (props) => {
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

  const addUniversityAsPartner = async () => {
    const response = await fetchPost("/university_partners", {
      recruiter: `/api/recruiters/${props.auth.userConnected.id}`,
      university: `/api/universities/${props.univ.id}`,
    });

    if (response.error) {
      showError(
        response.error.request.status === 422
          ? "Cette université est déjà dans vos partenaires"
          : "Une erreur est survenue"
      );
      return;
    }

    showSuccess("L'université a bien été ajoutée à vos partenaires");
    props.handleAuth({
      userConnected: {
        ...props.auth.userConnected,
        universitiesPartners: [
          ...props.auth.userConnected.universitiesPartners,
          {
            id: response.data.id,
            university: `/api/universities/${props.univ.id}`,
          },
        ],
      },
    });
  };

  const removeUniversityAsPartner = async () => {
    const relatedPartner = props.auth.userConnected.universitiesPartners.find(
      (y) => y.university.split("/")[3] == props.univ.id
    );

    if (!relatedPartner?.id) return;

    const response = await fetchDelete(
      `/university_partners/${relatedPartner.id}`
    );

    if (response.error) {
      showError("Une erreur est survenue");
      return;
    }

    showSuccess("L'université a bien été retirée de vos partenaires");
    props.handleAuth({
      userConnected: {
        ...props.auth.userConnected,
        universitiesPartners:
          props.auth.userConnected.universitiesPartners.filter(
            (x) => props.univ.id != x.university.split("/")[3]
          ),
      },
    });
  };

  return (
    <>
      <div className="universite">
        <img
          src={API_LINK + props.univ.imageTransparentAndColorUrl}
          alt={`Logo de l'université ${props.univ.name}`}
          title={`Logo de l'université ${props.univ.name}`}
        />
        <div className="universite__container">
          <p>{parse(props.univ.defaultPresentation)}</p>
          {props.connected ? (
            <div className="universite__container__buttons">
              {props.isPartner ? (
                <BtnBlanc
                  btnTexte={"Retirer de mes partenaires"}
                  btnAction={() => removeUniversityAsPartner()}
                />
              ) : (
                <BtnBleu
                  btnTexte={"Ajouter à mes partenaires"}
                  btnAction={() => addUniversityAsPartner()}
                />
              )}
            </div>
          ) : (
            <div className="universite__container__buttons">
              <BtnBleu btnTexte="Se connecter" />
              <BtnBlanc btnTexte="Créer un compte" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Universite.propTypes = {
  univ: PropTypes.object,
  auth: PropTypes.object,
  connected: PropTypes.bool,
  isPartner: PropTypes.bool,
  handleAuth: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Universite);

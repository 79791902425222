import React, { useState } from "react";
import "./headerUserConnected.scss";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import neutralUser from "assets/neutralUser.png";
import { FaAngleDown } from "react-icons/fa";
import Modal from "Components/Modal/Modal";
import { updateAuth } from "Redux/Actions/authActions";
import { connect } from "react-redux";
import "primeicons/primeicons.css";
import { disconnectUser } from "Services/Auth/authUtils";

const headerUserConnected = (props) => {
  const navigate = useNavigate();
  const [openConnexionInterface, setOpenConnexionInterface] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <div className="userConnected">
      <div className="userConnected__right">
        <div className="userConnected__right__image_button">
          <button
            onMouseEnter={() => setOpenConnexionInterface(true)}
            onMouseLeave={() => setOpenConnexionInterface(false)}
            className="userConnected__button"
          >
            <img
              src={
                props.auth.userConnected.imageUrl
                  ? props.auth.userConnected.imageUrl
                  : neutralUser
              }
              alt="Photo de profil utilisateur"
              title="Photo de profil utilisateur"
              className="userConnected__picture"
              onClick={() => navigate("/profil")}
            />
            {props.auth.userConnected.firstname}
            <FaAngleDown />
            {openConnexionInterface && (
              <div
                className="connexionInterfaceConnected"
                onMouseEnter={() => setOpenConnexionInterface(true)}
                onMouseLeave={() => setOpenConnexionInterface(false)}
              >
                <ul>
                  <li>Recruteur</li>
                  <li>
                    <Link to="/profil">Mon profil</Link>
                  </li>
                  <li onClick={() => disconnectUser()}>Se déconnecter</li>
                </ul>
              </div>
            )}
          </button>
          {visibleModal && (
            <Modal
              visible={visibleModal}
              setVisible={setVisibleModal}
              header="Se connecter"
            >
              <form action="/action_page.php">
                <label htmlFor="fname">First name:</label>
                <input type="text" id="fname" name="fname" value="John" />
                <label htmlFor="lname">Last name:</label>
                <input type="text" id="lname" name="lname" value="Doe" />
                <input type="submit" value="Submit" />
              </form>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

headerUserConnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(headerUserConnected);

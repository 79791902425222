import React, { useEffect, useState } from "react";
import "./Evenements.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EvenementsContainer from "Components/Evenements/EvenementsContainer/EvenementsContainer";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import EvenementsCreation from "Components/Evenements/EvenementsCreation/EvenementsCreation";
import EvenementsManagement from "Components/Evenements/EvenementsManagement/EvenementsManagement";
import { updateEvents } from "Redux/Actions/eventsActions";

/**
 * Permet d'afficher la page des événements
 * Avant connexion et après connexion pour les étudiants sont similaire
 * Les alumni et personnels peuvent proposer des événements et les gérer, les admins peuvent les créer et les gérer
 */
// TO DO : gestion éditoriale : intégrer la modification de titre des événements
const Evenements = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);

  // remplissage de Redux avec les données des événements
  useEffect(() => {
    if (props.states.statesData.length > 0) {
      const dataEventStatusModified = props.states.statesData.map((state) => {
        return {
          id: state.id,
          name: state.statusLabel,
          statusLabel: state.statusLabel,
        };
      });
      const listOfStatus = [
        "Brouillon",
        "Refusé",
        "En Attente",
        "Publié",
        "Cloturé",
      ];

      props.handleUpdateEvents({
        etats: dataEventStatusModified.filter(
          (state) => listOfStatus.indexOf(state.name) !== -1
        ),
      });
    }
  }, [props.states.statesData.length]);

  return (
    <div>
      {/* affichage du header  */}
      {props.auth.isConnected ? (
        <>
          <Header noImage={false}>
            <NavBarTab
              select="evenement"
              activeIndex={props.items.activeItemEvenement}
              items={props.items.itemsEvenement}
            />
          </Header>
        </>
      ) : (
        <>
          {
            <Header noImage={true}>
              <ButtonsBarDisconnected />
            </Header>
          }
        </>
      )}
      {/* affichage du reste de la page  */}
      {!props.auth.isConnected ? (
        <>{<EvenementsContainer />}</>
      ) : (
        <>
          {props.items.activeItemEvenement ==
            props.items.itemsEvenement.findIndex(
              (i) => i.label == "Tous les événements"
            ) && <EvenementsContainer />}

          {/* création d'événements  */}
          {props.items.activeItemEvenement ==
            props.items.itemsEvenement.findIndex(
              (itemIndex) => itemIndex.label == "Proposer un événement"
            ) && (
            <EvenementsCreation
              activeIndex={props.items.itemsEvenement.findIndex(
                (itemIndex) => itemIndex.label == "Proposer un événement"
              )}
              setActiveIndex={setActiveIndex}
              items={props.items.itemsEvenement}
            />
          )}
          {/* gestion d'événements  */}
          {props.items.activeItemEvenement ==
            props.items.itemsEvenement.findIndex(
              (itemIndex) => itemIndex.label == "Gérer mes événements"
            ) && (
            <EvenementsManagement
              activeIndex={props.items.itemsEvenement.findIndex(
                (itemIndex) => itemIndex.label == "Gérer mes événements"
              )}
              setActiveIndex={setActiveIndex}
              items={props.items.itemsEvenement}
            />
          )}
        </>
      )}

      <Footer />
    </div>
  );
};

Evenements.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
  events: PropTypes.object,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  events: state.events,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Evenements);

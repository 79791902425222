import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import "./CVSearchBar.scss";

const CVSearchBar = (props) => {
  const [motsclés, setMotsclés] = useState("");
  const [experience, setExperience] = useState(null);
  const [nivEtudes, setNivEtudes] = useState(null);
  const [secteurFormation, setSecteurFormation] = useState(null);
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [certification, setCertification] = useState(false);
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [reset, setReset] = useState(true);

  // récupération des données et ajout des paramètres dans l'url de recherche
  const handleSearch = () => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/resumes/cvteque`);

    // --- Nombre de CVs par page
    url.searchParams.set("itemsPerPage", 20);
    if (props.saved) {
      url = findOnlySavedResumes(url);
      // Si aucun CV n'est sauvegardé, on arrête la recherche
      if (url.searchParams.get("identifier[]") === "") {
        resetForm();
        return;
      }
    }

    if (
      !nom &&
      !prenom &&
      !motsclés &&
      !experience &&
      !nivEtudes &&
      !secteurFormation &&
      !certification &&
      !universities?.length
    ) {
      props.setUrl(url);
      setIsActiveSearch(false);
      return;
    }
    setIsActiveSearch(true);

    //Gestion des strings nom, prenom et mots clés
    if (nom !== "") url.searchParams.set("surname", nom);
    if (prenom !== "") url.searchParams.set("firstname", prenom);
    if (motsclés !== "") url.searchParams.set("keyword", motsclés);

    if (universities.length > 0)
      url.searchParams.set("univ[]", universities.map((x) => x.id).join(","));

    //Gestion du bolléen certification
    if (certification) url.searchParams.set("certified", certification ? 1 : 0);

    //Gestion des inputs de type Array[Object]
    // ----- On renvoie pour les expériences tous les ids séparés par des virgules
    if (experience?.length > 0) {
      url.searchParams.set(
        "seniority[]",
        experience.map((x) => x.id).join(",")
      );
    }
    // ----- On renvoie pour les niveaux d'études tous les ids séparés par des virgules
    if (nivEtudes?.length > 0) {
      url.searchParams.set("study[]", nivEtudes.map((x) => x.id).join(","));
    }
    // ----- On renvoie pour les domaines tous les ids séparés par des virgules
    if (secteurFormation?.length > 0) {
      url.searchParams.set(
        "domain[]",
        secteurFormation.map((x) => x.id).join(",")
      );
    }

    props.setUrl(url);
  };

  const findOnlySavedResumes = (url) => {
    const savedResumes = props.auth.userConnected.savedResumes;

    let cvs = "";
    savedResumes.forEach(
      (item) => (cvs += `${item.university.split("/")[3]}-${item.resume},`)
    );

    url.searchParams.set("identifier[]", cvs);

    return url;
  };

  /**
   * Fonction de réinitialisation des champs de recherche
   */
  function resetForm() {
    setMotsclés("");
    setExperience(null);
    setNivEtudes(null);
    setSecteurFormation(null);
    setPrenom("");
    setNom("");
    setCertification(false);
    setUniversities(null);
    setReset(true);
  }

  useEffect(() => {
    // On effectue une recherche par défaut au chargement de la page
    if (reset) {
      handleSearch();
      setReset(false);
    }
  }, [reset]);

  return (
    <div className="cv_search_bar">
      {/* <h2 className="cv_search_bar__title">Trouvez un étudiant</h2> */}
      <div className="cv_search_bar__container">
        <div className="cv_search_bar__container__top">
          <div className="cv_search_bar__container__top__boxes">
            <div className="cv_search_bar__container__top__boxes__box">
              <label>UNIVERSITÉS</label>
              <MultiSelect
                value={universities}
                options={props.universities.universitiesData.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                onChange={(e) => setUniversities(e.value)}
                optionLabel="name"
                maxSelectedLabels={6}
                selectedItemsLabel={`${universities?.length} options sélectionnées`}
                placeholder="Université"
                className="box__input"
              />
            </div>
            <Divider layout="vertical" />
            <div className="cv_search_bar__container__top__boxes__box">
              <label>MOTS CLÉS</label>
              <span className="cv_p-input-icon-left">
                <InputText
                  value={motsclés}
                  onChange={(e) => setMotsclés(e.target.value)}
                  placeholder="Voir les champs"
                  className="box__input"
                />
              </span>
            </div>
            <Divider layout="vertical" />

            <div className="cv_search_bar__container__top__boxes__box">
              <label>NIVEAU D&apos;EXPÉRIENCE</label>
              <MultiSelect
                value={experience}
                options={props.secondaryTables.levelOfExperiencesData.sort(
                  (a, b) => a.label.localeCompare(b.label)
                )}
                onChange={(e) => setExperience(e.value)}
                optionLabel="label"
                placeholder="Junior"
                className="box__input"
                maxSelectedLabels={3}
                selectedItemsLabel={`${experience?.length} options sélectionnées`}
              />
            </div>
            <Divider layout="vertical" />

            <div className="cv_search_bar__container__top__boxes__box">
              <label>NIVEAU D&apos;ÉTUDES</label>
              <MultiSelect
                value={nivEtudes}
                options={props.secondaryTables.levelOfEducationsData}
                onChange={(e) => setNivEtudes(e.value)}
                optionLabel="label"
                placeholder="Bac"
                className="box__input"
                maxSelectedLabels={3}
                selectedItemsLabel={`${nivEtudes?.length} options sélectionnées`}
              />
            </div>
            <Divider layout="vertical" />

            <div className="cv_search_bar__container__top__boxes__box">
              <label>SECTEUR DE FORMATION</label>
              <MultiSelect
                value={secteurFormation}
                options={props.secondaryTables.domainsData}
                onChange={(e) => setSecteurFormation(e.value)}
                optionLabel="label"
                placeholder="Informatique"
                className="box__input"
                maxSelectedLabels={3}
                selectedItemsLabel={`${secteurFormation?.length} options sélectionnées`}
              />
            </div>
          </div>
          <div
            className="cv_search_bar__container__top__inputSearch"
            onClick={() => handleSearch()}
          >
            <FaSearch />
          </div>
        </div>

        <div className="cv_search_bar__container__bottom">
          <div className="cv_search_bar__container__bottom__boxes__box">
            <label>Prénom:</label>
            <InputText
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              placeholder="Alex, Emma"
              className="box__input"
            />
          </div>
          <div className="cv_search_bar__container__bottom__boxes__box">
            <label>Nom:</label>
            <InputText
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              placeholder="Lebon, Dupont"
              className="box__input"
            />
          </div>
          <div className="cv_search_bar__container__bottom__boxes__checkbox">
            <Checkbox
              id="cb1"
              onChange={(e) => setCertification(e.checked)}
              checked={certification}
            ></Checkbox>
            <label htmlFor="cb1" className="p-checkbox-label">
              Certification diplôme
            </label>
            <p htmlFor="cb1" className="p-checkbox-label">
              Certification diplôme
            </p>
          </div>
          {isActiveSearch && (
            <div className="search_bar_refresh search_bar_refresh__cvs">
              <i
                className="pi pi-refresh"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "1rem",
                }}
                onClick={() => resetForm()}
              />
            </div>
          )}
          <div
            className="cv_search_bar__container__bottom__inputSearch"
            onClick={() => handleSearch()}
          >
            <FaSearch />
          </div>
        </div>
      </div>
    </div>
  );
};

CVSearchBar.propTypes = {
  id: PropTypes.string,
  setFilteredCVs: PropTypes.func,
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  setUrl: PropTypes.func,
  url: PropTypes.object,
  universities: PropTypes.object,
  saved: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
  universities: state.universities,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CVSearchBar);

import { store } from "Redux/store";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";

export const useFetchGetPagination = (url, updateFunc = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  let response = null;
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;
  useMemo(() => {
    url?.searchParams?.set("page", page);
    url !== "" &&
      url &&
      axios
        .get(url, {
          headers: token && {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          response = res;
          if (response.data["hydra:member"] === undefined) {
            setData(response.data.data || response.data);
            setTotalItems(response.data.totalItems);
          } else {
            setData(response.data["hydra:member"]);
            setTotalItems(response.data["hydra:totalItems"]);
          }
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setLoaded(true);
          updateFunc &&
            updateFunc({
              dataFromPagination: {
                data: response.data["hydra:member"] || null,
                loaded: true,
                page,
                setPage,
                totalItems: response.data["hydra:totalItems"] || null,
              },
            });
        });
  }, [page, url]);

  return {
    data,
    error,
    loaded,
    page,
    setPage,
    totalItems,
  };
};

export const useFetchGet = (url, params = null, back = "univ") => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  const reduxStore = store.getState();
  const token = reduxStore.auth.token;

  useEffect(() => {
    if (
      back == "nexus" &&
      (!process.env.REACT_APP_NEXUS_URL_API ||
        process.env.REACT_APP_NEXUS_URL_API == undefined)
    ) {
      setData(null);
      setLoaded(true);
      return;
    }
    const apiUrl =
      back === "univ"
        ? process.env.REACT_APP_BASE_URL_API
        : process.env.REACT_APP_NEXUS_URL_API;
    axios
      .get(`${apiUrl}${url}`, {
        headers: token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            }
          : {
              accept: "application/json",
            },
        params: params,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);
  return { data, error, loaded };
};

export const useFetchGetConditional = (url, reduxData) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;
  useEffect(() => {
    if (!reduxData || reduxData.length === 0)
      axios
        .get(`${process.env.REACT_APP_BASE_URL_API}${url}`, {
          headers: token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${token}`,
              }
            : {
                accept: "application/json",
              },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoaded(true));
    else {
      setData(reduxData);
      setLoaded(true);
    }
  }, []);
  return { data, error, loaded };
};

export const fetchGet = async (url, urlOptions = "api", params = null) => {
  var data = null;
  var error = null;
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;

  let fullUrl = "";
  switch (urlOptions) {
    case "short":
      fullUrl = `${process.env.REACT_APP_BASE_URL_API_SHORT}${url}`;
      break;
    case "nexus":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API}${url}`;
      break;
    case "nexusShort":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API_SHORT}${url}`;
      break;
    case "none":
      fullUrl = url;
      break;
    default:
      fullUrl = `${process.env.REACT_APP_BASE_URL_API}${url}`;
      break;
  }

  try {
    let response = await axios.get(fullUrl, {
      headers: token
        ? {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
      params: params,
    });
    data = response.data || response;
  } catch (e) {
    error = typeof e?.toJSON === "function" ? e.toJSON() : e;
  }
  return { data, error };
};

export const fetchPost = async (
  url,
  payload = {},
  urlOptions = "api",
  params = null,
  type = null
) => {
  var data = null;
  var error = null;
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;

  let fullUrl = "";
  switch (urlOptions) {
    case "short":
      fullUrl = `${process.env.REACT_APP_BASE_URL_API_SHORT}${url}`;
      break;
    case "nexus":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API}${url}`;
      break;
    case "nexusShort":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API_SHORT}${url}`;
      break;
    case "none":
      fullUrl = url;
      break;
    default:
      fullUrl = `${process.env.REACT_APP_BASE_URL_API}${url}`;
      break;
  }
  try {
    let response = await axios.post(fullUrl, payload, {
      headers: token
        ? {
            accept: "application/json",
            "Content-Type": type ? type : "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
      withCredentials: true,
      params: params,
    });
    data = response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      error = e;
    } else error = e.toJSON();
  }
  return { data, error };
};

export const fetchPut = async (
  url,
  payload,
  urlOptions = "api",
  params = null,
  type = null
) => {
  var data = null;
  var error = null;
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;

  let fullUrl = "";
  switch (urlOptions) {
    case "short":
      fullUrl = `${process.env.REACT_APP_BASE_URL_API_SHORT}${url}`;
      break;
    case "nexus":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API}${url}`;
      break;
    case "nexusShort":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API_SHORT}${url}`;
      break;
    case "none":
      fullUrl = url;
      break;
    default:
      fullUrl = `${process.env.REACT_APP_BASE_URL_API}${url}`;
      break;
  }

  try {
    let response = await axios.put(fullUrl, payload, {
      headers: token
        ? {
            accept: "application/json",
            "Content-Type": type ? type : "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
      params: params,
    });
    data = response.data;
  } catch (e) {
    error = e.toJSON();
  }
  return { data, error };
};

export const fetchDelete = async (url, urlOptions = "api", params = null) => {
  var data = null;
  var error = null;
  const reduxStore = store.getState();
  const token = reduxStore.auth.token;

  let fullUrl = "";
  switch (urlOptions) {
    case "short":
      fullUrl = `${process.env.REACT_APP_BASE_URL_API_SHORT}${url}`;
      break;
    case "nexus":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API}${url}`;
      break;
    case "nexusShort":
      fullUrl = `${process.env.REACT_APP_NEXUS_URL_API_SHORT}${url}`;
      break;
    case "none":
      fullUrl = url;
      break;
    default:
      fullUrl = `${process.env.REACT_APP_BASE_URL_API}${url}`;
      break;
  }

  try {
    let response = await axios.delete(fullUrl, {
      headers: token
        ? {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        : {
            accept: "application/json",
          },
      params: params,
    });
    data = response.data || response;
  } catch (e) {
    error = e.toJSON();
  }
  return { data, error };
};

import CVContainer from "Components/CV/CVContainer/CVContainer";
import CVPaniers from "Components/CV/CVPaniers/CVPaniers";
import CVSauvegardes from "Components/CV/CVSauvegardes/CVSauvegardes";
import Footer from "Components/Footer/Footer";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { updateAuth } from "Redux/Actions/authActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useFetchGet, useFetchGetConditional } from "Services/api";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const CVTheque = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);
  const cvTheque = useRef(0);

  const savedResumes = useFetchGet("/saved_resumes");
  //Import des données user pour les avoir au rechargement de la page
  const domaines = useFetchGetConditional(
    "/domains",
    props.secondaryTables.domainsData
  );
  const composantes = useFetchGetConditional(
    "/education_composantes",
    props.secondaryTables.educationComposanteData
  );
  const levelOfEducation = useFetchGetConditional(
    "/level_of_educations",
    props.secondaryTables.levelOfEducationsData
  );
  const levelOfExperience = useFetchGetConditional(
    "/level_of_experiences",
    props.secondaryTables.levelOfExperiencesData
  );

  useEffect(() => {
    composantes.loaded &&
      domaines.loaded &&
      levelOfEducation.loaded &&
      levelOfExperience.loaded &&
      props.handleUpdateSecondaryTables({
        levelOfEducationsData: levelOfEducation.data,
        domainsData: domaines.data,
        levelOfExperiencesData: levelOfExperience.data,
        educationComposanteData: composantes.data,
      });
  }, [
    composantes.loaded,
    domaines.loaded,
    levelOfEducation.loaded,
    levelOfExperience.loaded,
  ]);

  useEffect(() => {
    if (savedResumes.loaded && savedResumes.data) {
      props.handleUpdateAuth({
        userConnected: {
          ...props.auth.userConnected,
          savedResumes: savedResumes.data,
        },
      });
    }
  }, [savedResumes.loaded]);

  function setActiveCVTheque(i) {
    cvTheque.current = i;
  }

  // Renvoie les différents composants en fonction de l'index actif
  function getComponentWithIndex() {
    switch (props.items.activeItemCVTheque) {
      case 0:
        return (
          <CVContainer
            activeIndex={props.items.itemsCVTheque.findIndex(
              (index) => index.label == "Cvthèque"
            )}
            setActiveIndex={setActiveIndex}
            items={props.items.itemsCVTheque}
            cvTheque={cvTheque.current}
          />
        );
      case 1:
        return (
          <CVSauvegardes
            activeIndex={props.items.itemsCVTheque.findIndex(
              (index) => index.label == "Cv sauvegardés"
            )}
            setActiveIndex={setActiveCVTheque}
            items={props.items.itemsCVTheque}
          />
        );
      case 2:
        return (
          <CVPaniers
            activeIndex={props.items.itemsCVTheque.findIndex(
              (index) => index.label == "Paniers de cv"
            )}
            setActiveIndex={setActiveIndex}
            items={props.items.itemsCVTheque}
          />
        );
    }
  }

  return (
    <div>
      <Header>
        {props.auth.isConnected ? (
          <NavBarTab
            select="cvtheque"
            activeIndex={activeIndex}
            items={props.items}
          />
        ) : (
          <ButtonsBarDisconnected />
        )}
      </Header>
      <div className="CVTheque">
        {props.auth.isConnected ? (
          getComponentWithIndex()
        ) : (
          <Navigate to={`/`} />
        )}
      </div>
      <Footer />
    </div>
  );
};

CVTheque.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  secondaryTables: PropTypes.object,
  handleUpdateSecondaryTables: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CVTheque);

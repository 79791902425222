import Loader from "Components/Loader/loader";
import { updateMenu } from "Redux/Actions/menuActions";
import { fetchGet, useFetchGetConditional } from "Services/api";
import { displayCompanyImage } from "Services/functions";
import blank from "assets/blank.png";
import neutralUser from "assets/neutralUser.png";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./ProfilBar.scss";

/**
 * @typedef CompanyItem
 * @property {{}} data
 * @property {{}} error
 * @property {number} data.id
 * @property {string} data.name
 * @property {string} data.companyUrl
 */

const ProfilBar = (props) => {
  const univData = useFetchGetConditional(
    "/universities",
    props.universities.universitiesData
  );

  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

  const [partnerUniversities, setPartnerUniversities] = useState([]);
  const [companyData, setCompanyData] = useState(
    /** @type {CompanyItem | null} */ (null)
  );

  useEffect(() => {
    if (
      univData.loaded &&
      univData.data &&
      props.auth.userConnected.universitiesPartners
    ) {
      setPartnerUniversities(
        univData.data.filter((x) =>
          props.auth.userConnected.universitiesPartners.find(
            (y) => y.university.split("/")[3] == x.id
          )
        )
      );
    }
  }, [univData.loaded, props.auth.userConnected.universitiesPartners]);

  useEffect(async () => {
    const getCompanyData = async () => {
      let companyResponse = await fetchGet(
        "/companies/" + props.auth.userConnected.company.id
      );
      setCompanyData(companyResponse);
    };
    if (props.auth.userConnected.company) {
      await getCompanyData();
    }
  }, [props.auth.userConnected.company]);

  return (
    <div className="monProfil" id={props.id}>
      <div className="monProfil__container">
        <div className="monProfil__container__header">
          <div className="monProfil__container__header__title">
            <h1>
              {props.auth.userConnected.firstname}{" "}
              {props.auth.userConnected.surname}
            </h1>
            <h2>Espace personnel - profil</h2>
          </div>
        </div>

        <div className="profilDescription">
          <div className="user__picture">
            <img
              src={
                props.auth.userConnected.imageUrl
                  ? props.auth.userConnected.imageUrl
                  : neutralUser
              }
              alt="Mon avatar"
              title="Mon avatar"
            />
          </div>
          <div className="column">
            <h1>Mon profil</h1>
            <p>Profil rempli à 70%</p>
            <ProgressBar
              value={70}
              showValue={false}
              className="profilDescription__progressBar"
            ></ProgressBar>
            <p className="column__infoApplyJob">
              Remplissez votre profil pour partager votre expérience
            </p>
            <Link
              to={{ pathname: `/profil` }}
              onClick={() =>
                props.handleMenu({
                  activeItemProfil: props.items.itemsProfil.findIndex(
                    (v) => v.label === "Informations personnelles"
                  ),
                })
              }
            >
              Modifier mon profil
            </Link>
          </div>
          <Divider className="div_horiz" />
          <Divider className="div_vert" layout="vertical" />
          <div className="column">
            {companyData?.data ? (
              <>
                <h1>Mon entreprise</h1>
                <img
                  src={displayCompanyImage(companyData.data) || blank}
                  onError={(e) => {
                    e.target.src = blank;
                    e.target.title =
                      "Veuillez vérifier l'URL de votre entreprise";
                    e.target.alt =
                      "Veuillez vérifier l'URL de votre entreprise";
                  }}
                  alt={
                    companyData.data.companyUrl
                      ? `Logo de l'entreprise ${companyData.data.name}`
                      : "Veuillez vérifier l'URL de votre entreprise"
                  }
                  title={
                    companyData.data.companyUrl
                      ? `Logo de l'entreprise ${companyData.data.name}`
                      : "Veuillez vérifier l'URL de votre entreprise"
                  }
                />
                <Link
                  to={`/entreprise-edit/${props.auth.userConnected?.company?.id}`}
                  state={{ params: props.auth.userConnected?.company?.id }}
                >
                  Modifier les infos entreprise
                </Link>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>

          <Divider className="div_horiz" />
          <Divider className="div_vert" layout="vertical" />
          <div className="column">
            <h1>Mes universités partenaires</h1>
            {partnerUniversities?.length === 0 ? (
              <span className="no_partner">
                Vous n&apos;êtes pas encore partenaire ? Découvrez les
                universités qui composent Réseau Pro !
              </span>
            ) : (
              <div className="column__groups">
                {/* TO DO : si l'entreprise n'a pas d'universités partenaires alors afficher "Vous n'êtes pas encore partenaire ? Rapprochez-vous des universités pour faire une demande !"
              Le bouton "voir tous mes partenaires" se transforme en "découvrir les universités"
              */}
                {partnerUniversities.slice(0, 4).map((x) => (
                  <div className="column__groups__item" key={x.id}>
                    <img
                      src={API_LINK + x.imageTransparentAndColorUrl}
                      alt="Logo de l'Université Panthéon Sorbonne"
                      title="Logo de l'Université Panthéon Sorbonne"
                    />
                  </div>
                ))}
              </div>
            )}
            <Link to={{ pathname: `/universites` }}>
              {partnerUniversities?.length === 0
                ? "Découvrir les universités"
                : "Voir tous mes partenaires"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
ProfilBar.propTypes = {
  id: PropTypes.string,
  auth: PropTypes.object,
  items: PropTypes.object,
  universities: PropTypes.object,
  handleMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  universities: state.universities,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => dispatch(updateMenu(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilBar);

import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";

const GestionCookies = () => {
  return (
    <div>
      <Header />
      <h1>Gestion des cookies</h1>
      <Footer />
    </div>
  );
};

export default GestionCookies;

import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";

const Aide = () => {
  return (
    <div>
      <Header />
      <h1>Aide</h1>
      <Footer />
    </div>
  );
};

export default Aide;

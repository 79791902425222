import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import Loader from "Components/Loader/loader";
import Modal from "Components/Modal/Modal";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import Universite from "Components/Universite/Universite";
import { useFetchGetConditional } from "Services/api";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./Universites.scss";

const Universites = (props) => {
  const univData = useFetchGetConditional(
    "/universities",
    props.universities.universitiesData
  );
  const [visibleListUniv, setVisibleListUniv] = useState(false);
  const [partnerUniversities, setPartnerUniversities] = useState([]);

  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  /**
   * Permet de gérer les différents state de location
   * - toFocus : Permet de scroller jusqu'à un élément (via son id)
   */
  const handleLocationState = () => {
    // Gestion du cas où on a une locationState.toFocus
    if (locationState?.toFocus) {
      // Récupération de l'élément à focus
      const element = document.getElementById(locationState.toFocus);
      // Scroll vers l'élément
      element &&
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      // Suppression de l'élément toFocus de la locationState pour éviter de scroller à chaque changement de locationState
      navigate(location.pathname, { replace: true });
    }
  };

  useEffect(() => {
    handleLocationState();
  }, [locationState]);

  useEffect(() => {
    if (
      univData.loaded &&
      univData.data &&
      props.auth.userConnected?.universitiesPartners
    ) {
      setPartnerUniversities(
        univData.data
          .filter((x) =>
            props.auth.userConnected.universitiesPartners.find(
              (y) => y.university.split("/")[3] == x.id
            )
          )
          .sort(() => Math.random() - 0.5)
      );
    }
  }, [univData.loaded, props.auth.userConnected?.universitiesPartners]);

  return (
    <div className="universites">
      <Header>{props.auth.isConnected && <ProfilBar />}</Header>
      {!props.auth.isConnected && (
        <div className="universites_container" id="fairePartieRP">
          <div className="universites_container__top">
            <div className="universites_container__top__title">
              <h1 className="h1_trait_dessus">
                Comment faire partie de Réseau Pro ?
              </h1>
            </div>
            <div>
              <p>
                Vous êtes une université, vous souhaitez faire partie de la
                communauté Réseau Pro et vous vous demandez comment faire ?
              </p>
              <p>Le processus est simple :</p>
              <ul>
                <li>
                  Faites votre demande auprès de l&apos;UNIF à cette adresse
                  mail : unif@xxx.fr
                </li>
                <li>
                  Participez à une présentation de la plateforme Réseau Pro
                </li>
                <li>Validez votre adhésion à la plateforme</li>
              </ul>
            </div>
          </div>
          <div className="universites_container__bottom" id="presentationUnivs">
            <div className="universites_container__bottom__title">
              <h1 className="h1_trait_dessus">
                Présentation de nos universités
              </h1>
            </div>
            {univData.data ? (
              univData.data
                .sort(() => Math.random() - 0.5)
                .map((x) => <Universite univ={x} key={x.id} />)
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      )}
      {props.auth.isConnected && partnerUniversities?.length === 0 && (
        <div className="universites_container">
          <div className="universites_container__bottom">
            <div className="universites_container__bottom__title">
              <h1 className="h1_trait_dessus">
                Présentation de nos universités
              </h1>
              <BtnBleu
                btnTexte="Ajouter des universités partenaires"
                btnAction={() => setVisibleListUniv(true)}
              ></BtnBleu>
            </div>
            <div className="no_partner">
              Vous n&apos;avez pas encore choisi d&apos;universités partenaires,
              c&apos;est peut-être le moment !
            </div>
          </div>
        </div>
      )}
      {props.auth.isConnected && partnerUniversities?.length > 0 && (
        <div className="universites_container">
          <div className="universites_container__bottom">
            <div className="universites_container__bottom__title">
              <h1 className="h1_trait_dessus">Mes universités partenaires</h1>
              <BtnBleu
                btnTexte="Ajouter des universités partenaires"
                btnAction={() => setVisibleListUniv(true)}
              ></BtnBleu>
            </div>
            {partnerUniversities.map((x) => (
              <Universite univ={x} key={x.id} connected isPartner />
            ))}
          </div>
        </div>
      )}
      {visibleListUniv && (
        <Modal
          visible={visibleListUniv}
          setVisible={setVisibleListUniv}
          header="Les universités de Réseau Pro"
          className="modal__partner__univ"
        >
          {univData.data ? (
            univData.data.map((x) => (
              <Universite
                univ={x}
                key={x.id}
                connected
                isPartner={partnerUniversities?.find((y) => y.id === x.id)}
              />
            ))
          ) : (
            <Loader></Loader>
          )}
        </Modal>
      )}
      <Footer />
    </div>
  );
};

Universites.propTypes = {
  auth: PropTypes.object,
  universities: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  universities: state.universities,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Universites);

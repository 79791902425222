import React from "react";
import "./GestionDepotOffreCard.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatContrat, truncate } from "Services/functions";
import GestionDepotOffreActions from "../GestionDepotOffreActions/GestionDepotOffreActions";
import { useState } from "react";
import Modal from "Components/Modal/Modal";
import OffreDetail from "Components/Offres/Offre/OffreDetail/OffreDetail";
import recruteurs from "assets/recruteurs.png";
import SizedImage from "Components/SizedImage/SizedImage";

const GestionDepotOffreCard = (props) => {
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);

  return (
    <div className="gestion_offre_container">
      <div className="gestion_offre_container__logo">
        <div className="logo__card__gestion">
          <SizedImage
            image={props.companyUrl}
            defaultImage={recruteurs}
            alt="Logo entreprise"
            height="100%"
            rounded
          />
        </div>
        <GestionDepotOffreActions
          statut={props.statut}
          id={props.id}
          offre={props.offre}
          feedback={props.feedback}
          secondClass="mobile"
        ></GestionDepotOffreActions>
      </div>
      <div
        className="gestion_offre_container__info"
        onClick={() => setIsVisibleDetail(true)}
      >
        <h5 className="title">
          {props.titre ? (
            <>{props?.titre && truncate(props?.titre, 45)}</>
          ) : (
            "Titre non renseigné"
          )}
        </h5>
        <div className="gestion_offre_container__info__bottom">
          <h2>
            <i className="pi pi-building"> </i>
            {props.entreprise}
          </h2>
          <h2>
            <i className="pi pi-map-marker"></i> {truncate(props.lieu, 27)}
          </h2>

          <h2>
            <i className="pi pi-briefcase"></i> {formatContrat(props.contrat)}
          </h2>

          <h2>
            <i className="pi pi-id-card"></i> {props.niveau}
          </h2>
          <h2>
            <i className="pi pi-home"></i> {props.universityName}
          </h2>
        </div>
        <div className="description">
          <span>{props?.description && truncate(props?.description, 170)}</span>
        </div>
      </div>
      {/* </Link> */}

      <div className="gestion_offre_container__bouton">
        <GestionDepotOffreActions
          statut={props.statut}
          id={props.id}
          offre={props.offre}
          feedback={props.feedback}
          secondClass="desktop"
        ></GestionDepotOffreActions>
        {props.statut.statusLabel == "Archivé" && (
          <span className="desactive">Archivée</span>
        )}
        {props.statut.statusLabel == "Publié" && (
          <span className="actif">Publiée</span>
        )}
        {props.statut.statusLabel == "En Attente" && (
          <span className="attente">En attente</span>
        )}
        {props.statut.statusLabel == "Refusé" && (
          <span className="refuse">Refusée</span>
        )}
        {props.statut.statusLabel == "Brouillon" && (
          <span className="brouillon">Brouillon</span>
        )}
      </div>

      {isVisibleDetail && (
        <Modal
          visible={isVisibleDetail}
          setVisible={setIsVisibleDetail}
          header={`Détail de l'offre`}
        >
          <OffreDetail id={props.id}></OffreDetail>
        </Modal>
      )}
    </div>
  );
};

GestionDepotOffreCard.propTypes = {
  titre: PropTypes.string,
  lieu: PropTypes.string,
  contrat: PropTypes.string,
  niveau: PropTypes.string,
  statut: PropTypes.object,
  description: PropTypes.string,
  image: PropTypes.string,
  entreprise: PropTypes.string,
  id: PropTypes.number,
  offre: PropTypes.object,
  feedback: PropTypes.string,
  companyUrl: PropTypes.string,
  universityName: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionDepotOffreCard);

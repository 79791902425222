import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import "./CandidaturesRecues.scss";
import OfferCard from "../OfferCard/OfferCard";
import { useFetchGetPagination } from "Services/api";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import Loader from "Components/Loader/loader";
import TempPagination from "Components/Pagination/TempPagination";
import CandidaturesContainer from "../CandidaturesContainer/CandidaturesContainer";

// Container qui affiche toutes les candidatures et toutes les offres
const CandidaturesRecues = (props) => {
  const navigate = useNavigate("");
  const offersRef = useRef(null);

  const [candidaturesData, setCandidaturesData] = useState(null);

  const candidaturesQuery = useFetchGetPagination(
    `${process.env.REACT_APP_BASE_URL_API}/candidatures`
  );
  const offersQuery = useFetchGetPagination(
    `${process.env.REACT_APP_BASE_URL_API}/offers`
  );

  useEffect(() => {
    candidaturesQuery.loaded &&
      candidaturesQuery.data &&
      setCandidaturesData(candidaturesQuery.data);
  }, [candidaturesQuery.loaded]);

  return (
    <div>
      <NavBarTab
        select="offres"
        activeIndex={props.activeIndex}
        items={props.items.itemsOffre}
      />
      <div className="candidatures_recues">
        <h1 className="h1_trait_dessus">
          {props.items.itemsOffre[props.activeIndex].label}
        </h1>
        <div className="candidatures_recues__subtitle">
          {candidaturesData && (
            <h3>
              {candidaturesData?.length !== 1
                ? `${candidaturesData.length} candidatures reçues`
                : `${candidaturesData.length} candidature reçue`}
            </h3>
          )}
          <button
            className="btn-blanc"
            onClick={() => {
              props.handleMenu({
                activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                  (v) => v.label == "Cvthèque"
                ),
              });
              navigate("/cvtheque");
            }}
          >
            Accéder à la CVthèque{" "}
          </button>
        </div>
        <div className="candidatures_recues__last">
          <h2 className="title_candidatures"> Dernières candidatures </h2>
        </div>
        <div className="candidatures_recues__body" ref={offersRef}>
          {candidaturesData ? (
            <CandidaturesContainer
              candidatures={candidaturesData}
              setCandidatures={setCandidaturesData}
              sorted
            ></CandidaturesContainer>
          ) : (
            <Loader></Loader>
          )}
        </div>
        <div className="divider"></div>
        <div className="candidatures_recues__last">
          <h2 className="title_candidatures"> Toutes les offres </h2>
        </div>
        <div className="candidature_container__recap">
          {offersQuery.loaded && candidaturesData ? (
            offersQuery.data
              .sort((a, b) => {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
              })
              .map((offer, index) => (
                <OfferCard
                  id={offer.id}
                  key={index}
                  title={offer.title}
                  location={offer.city}
                  contrat={offer.offerCategory?.label}
                  view={1000}
                  candidat={
                    candidaturesData.filter(
                      (candidature) => candidature.offer.id === offer.id
                    ).length
                  }
                />
              ))
          ) : (
            <Loader></Loader>
          )}
        </div>
        <TempPagination
          result={offersQuery}
          refItemParent={offersRef}
          rows={50}
        />
      </div>
    </div>
  );
};

CandidaturesRecues.propTypes = {
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  handleMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidaturesRecues);

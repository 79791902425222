import React, { useEffect, useRef, useState } from "react";
import "./CVPaniers.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { HiSearch } from "react-icons/hi";
import { updatePagination } from "Redux/Actions/paginationActions";
import Pagination from "Components/Pagination/Pagination";
import { fetchDelete, useFetchGet } from "Services/api";
import CVPanier from "./CVPanier/CVPanier";
import Loader from "Components/Loader/loader";
import { showError } from "Services/functions";

const CVPaniers = (props) => {
  useEffect(() => {
    //? Remise à zéro des informations de paginations pour ne pas interférer les affichages des autres pages
    return () => {
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  const [searchBasket, setSearchBasket] = useState("");
  const [basketList, setBasketList] = useState(null);

  const panierRef = useRef(null);

  const basketData = useFetchGet(
    `/basket_resumes?recruiterEmail=${props.auth.userConnected.email}`
  );

  useEffect(() => {
    if (basketData.loaded) {
      setBasketList(basketData.data);
    }
  }, [basketData.loaded]);

  const deleteBasket = async (id) => {
    const response = await fetchDelete(`/basket_resumes/${id}`);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }
    setBasketList((basketList) =>
      basketList.filter((basket) => basket.id !== id)
    );
  };

  return (
    <div>
      <div className="CVs__container">
        <div className="CVs__container__titlePanier">
          <h1 className="h1_trait_dessus CVs__container__titlePanier__title">
            {props.items[props.activeIndex].label}
          </h1>
          <div className="CVs__container__titlePanier__searchAndCreate">
            <div className="cv_paniers_searchBar">
              <HiSearch className="cv_paniers_searchBar__icon" />
              <InputText
                value={searchBasket}
                placeholder="Rechercher un panier..."
                onChange={(e) => {
                  setSearchBasket(e.target.value.toLowerCase());
                }}
                className="search_paniers"
              />
            </div>
          </div>
        </div>
        <div className="CVs__container__bouton">
          <p>Des CVs sélectionnés rien que pour vous</p>
        </div>
        <div className="CVs__container__paniers">
          {basketList ? (
            basketList.length > 0 ? (
              basketList
                .filter(
                  (basket) =>
                    basket.title.toLowerCase().indexOf(searchBasket) !== -1
                )
                .slice(
                  props.pagination.paginationBasicFirst,
                  props.pagination.paginationBasicRows *
                    props.pagination.currentPage
                )
                .map((basket) => (
                  <CVPanier
                    key={basket.id}
                    basketName={basket.title}
                    nbCV={basket.quantity}
                    basketUniversity={basket.university}
                    onDelete={() => {
                      deleteBasket(basket.id);
                    }}
                    basketItems={basket.item}
                  ></CVPanier>
                ))
            ) : (
              <span>Vous n&apos;avez aucun panier de CV</span>
            )
          ) : (
            <Loader></Loader>
          )}
        </div>

        <Pagination refItemParent={panierRef} data={basketList} />
      </div>
    </div>
  );
};

CVPaniers.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CVPaniers);

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./OffreDetail.scss";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import Loader from "Components/Loader/loader";
import { FaChevronLeft, FaRegBuilding, FaWheelchair } from "react-icons/fa";
import { IoMdDesktop, IoMdHome } from "react-icons/io";
import { BsHouseDoor, BsPeople } from "react-icons/bs";
import { updateAuth } from "Redux/Actions/authActions";
import parse from "html-react-parser";
import GestionDepotOffreActions from "Components/Offres/GestionDepotOffre/GestionDepotOffreActions/GestionDepotOffreActions";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import offre1 from "assets/offre1.webp";
import { fetchGet } from "Services/api";
import { displayCompanyImage, showError } from "Services/functions";
import recruteurs from "assets/recruteurs.png";

const OffreDetail = (props) => {
  const [loaderIsVisible, setLoaderIsVisible] = useState(false);
  const [offre, setOffre] = useState();

  const [logo, setLogo] = useState(null);

  let id = props.id ? props.id : useParams().id;

  // Va chercher dans la base l'offre que l'on veut afficher
  const getOffer = async () => {
    const response = await fetchGet(`/offers/${id}`);
    setLoaderIsVisible(false);
    if (response.error) {
      showError("Une erreur est survenue");
      return;
    }
    setOffre(response.data);
    setLogo(displayCompanyImage(response.data?.company) || recruteurs);
  };

  //  Useeffect de chargement
  useEffect(() => {
    setLoaderIsVisible(true);
    getOffer();
  }, []);

  useEffect(() => {
    // Si la carte est modifiée depuis le depot d'offre on affiche sa nouvelle valeur dans le détail
    if (props.offers?.dataFromPagination?.data) {
      const newOffer = props.offers.dataFromPagination.data.filter(
        (offer) => offer.id === id
      );
      newOffer.length > 0 && setOffre(newOffer[0]);
    }
  }, [props.offers?.dataFromPagination?.data]);

  return (
    <div>
      {!props.id && <Header />}
      {loaderIsVisible ? (
        <Loader />
      ) : (
        props.auth.isConnected &&
        offre && (
          <div className="offre_detail_container">
            {!props.id && (
              <div className="offre_detail_container__title">
                <h1 className="h1_trait_dessus">Détail de l&apos;offre</h1>
                <BtnBlanc
                  btnTexte="Revenir aux offres"
                  btnIcone={<FaChevronLeft />}
                  btnAction={() => window.history.back()}
                />
              </div>
            )}
            <div className="offre_detail_container__offre">
              <div className="offre_detail_container__offre__header">
                <img
                  src={offre?.imageUrl ? offre.imageUrl : offre1}
                  alt="Image de l'offre"
                  onError={(e) => {
                    e.target.src = offre1;
                  }}
                ></img>
              </div>
              <div className="offre_detail_container__offre__top">
                <div className="offre_detail_container__offre__top__logo">
                  <img
                    src={logo}
                    alt="Logo"
                    title="Logo entreprise"
                    onError={(e) => {
                      e.target.src = recruteurs;
                    }}
                  />
                </div>
                <div className="offre_detail_container__offre__top__buttons">
                  <GestionDepotOffreActions
                    statut={offre.state}
                    id={offre.id}
                    offre={offre}
                    feedback={offre.feedback?.feedback}
                    secondClass="desktop"
                  ></GestionDepotOffreActions>
                </div>
              </div>

              <div className="offre_detail_container__offre__description">
                <p className="offre_detail_container__offre__description__title--main">
                  <a href={offre.urlCompany}>{offre.companyName}</a>
                  <span className="offre_detail_container__offre__description__title--main__offre">{` - ${offre.title}`}</span>
                </p>
                <div className="offre_detail_container__offre__description__infoEntreprise">
                  <ul className="offre_detail_container__offre__description__infoEntreprise__list">
                    <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                      <FaRegBuilding />{" "}
                      {offre.company
                        ? offre.company.structure
                          ? offre.company.structure.label
                          : offre.company.type.label
                        : "Non renseigné"}
                    </li>
                    <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                      <BsPeople />{" "}
                      {offre.company?.totalStaff
                        ? offre.company.totalStaff.label
                        : "Non renseigné"}
                    </li>
                    {offre?.sector && (
                      <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                        <BsHouseDoor /> {offre?.sector?.label}
                      </li>
                    )}
                  </ul>
                </div>
                {offre.reference != null && (
                  <p className="offre_detail_container__offre__description__title">
                    Référence de l&apos;offre : <span>{offre.reference}</span>
                  </p>
                )}
                {offre.domains != null && (
                  <p className="offre_detail_container__offre__description__title">
                    {offre.domains.length > 1 ? "Domaines" : "Domaine"} de
                    l&apos;offre : <br />
                    <span>
                      {offre.domains.map((domain) => (
                        <>
                          {domain.label}
                          {offre.domains.indexOf(domain) !==
                          offre.domains.length - 1
                            ? ", "
                            : ""}
                        </>
                      ))}{" "}
                    </span>
                  </p>
                )}
                <p className="offre_detail_container__offre__description__title">
                  Catégorie de contrat de l&apos;offre :{" "}
                  <span>{offre.offerCategory?.label}</span>
                </p>
                <p className="offre_detail_container__offre__description__title">
                  Type de contrat de l&apos;offre :{" "}
                  <span>
                    {offre.typeOfContract?.label
                      ? offre.typeOfContract?.label
                      : offre.offerCategory?.label}
                  </span>
                </p>
                {offre?.levelOfEducations[0] !== undefined && (
                  <p className="offre_detail_container__offre__description__title">
                    {offre.levelOfEducations.length > 1 ? "Niveaux" : "Niveau"}{" "}
                    d&apos;études : <br />
                    <span>
                      {offre.levelOfEducations.map((level) => (
                        <>
                          {level.label}
                          {offre.levelOfEducations.indexOf(level) !==
                          offre.levelOfEducations.length - 1
                            ? ", "
                            : ""}
                        </>
                      ))}{" "}
                    </span>
                  </p>
                )}
                <p className="offre_detail_container__offre__description__title">
                  Lieu de l&apos;offre :{" "}
                  <span>
                    {offre.country} - {offre.department} - {offre.city}
                  </span>
                </p>
                <p className="offre_detail_container__offre__description__title">
                  Tranche de salaire :{" "}
                  <span>
                    {offre.minSalary} € - {offre.maxSalary} €
                  </span>
                </p>
                <p className="offre_detail_container__offre__description__titleWithMarge">
                  Description de l&apos;entreprise ou du service
                </p>
                <p className="offre_detail_container__offre__description__text">
                  {(offre.serviceDescription &&
                    parse(offre.serviceDescription)) ||
                    offre.serviceDescription ||
                    ""}
                </p>
                <p className="offre_detail_container__offre__description__titleWithMarge">
                  Description de l&apos;offre
                </p>
                <p className="offre_detail_container__offre__description__text">
                  {parse(offre.description, HTMLParseOptions)}
                </p>
                {offre.experience && (
                  <>
                    <p className="offre_detail_container__offre__description__titleWithMarge">
                      Description du profil recherché
                    </p>
                    <p className="offre_detail_container__offre__description__text">
                      {parse(offre.experience, HTMLParseOptions)}
                    </p>
                  </>
                )}
              </div>
              <div className="offre_detail_container__offre__description__bottom">
                <div className="offre_detail_container__offre__description__bottom--right">
                  <p className="offre_detail_container__offre__description__title">
                    INFORMATIONS COMPLEMENTAIRES :
                  </p>
                  <p className="offre_detail_container__offre__description__subtitle">
                    <FaWheelchair /> Poste handi-accueillant
                    {!offre?.accessibleForDisabled && (
                      <span className="none">: Non renseigné</span>
                    )}
                  </p>
                  <p className="offre_detail_container__offre__description__subtitle">
                    <IoMdDesktop /> Télétravail possible
                    {!offre?.remoteWorking && (
                      <span className="none">: Non renseigné</span>
                    )}
                  </p>
                  <p className="offre_detail_container__offre__description__subtitle">
                    <IoMdHome /> Hébergement possible
                    {!offre?.accommodation && (
                      <span className="none">: Non renseigné</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {!props.id && <Footer />}
    </div>
  );
};

OffreDetail.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  adminAuth: PropTypes.func,
  offers: PropTypes.object,
  id: PropTypes.number,
  url: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffreDetail);

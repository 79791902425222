import React from "react";
import "./btn-bleu.scss";
import PropTypes from "prop-types";

const BtnBleu = (props) => {
  return (
    <button className="btn-bleu" onClick={props.btnAction}>
      {props.btnTexte}
      {props.children}
    </button>
  );
};

BtnBleu.propTypes = {
  btnAction: PropTypes.func,
  btnTexte: PropTypes.string,
  children: PropTypes.any,
};

export default BtnBleu;

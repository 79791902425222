import React, { useState } from "react";
import "./headerUserDisconnected.scss";
import PropTypes from "prop-types";
import { FaRegUserCircle, FaAngleDown } from "react-icons/fa";
import Modal from "../../..//Modal/Modal";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import { updateAuth } from "../../../../Redux/Actions/authActions";
import { connect } from "react-redux";
import ModalCreationCompte from "../../../Modal/ModalCreationCompte/ModalCreationCompte";
import HeaderUserLogin from "../HeaderUserLogin/HeaderUserLogin";

const headerUserDisconnected = () => {
  const [openConnexionInterface, setOpenConnexionInterface] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCreationCompte, setVisibleModalCreationCompte] =
    useState(false);
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);
  return (
    <div className="header__bandeau__userDisconnected">
      <button
        onMouseEnter={() => setOpenConnexionInterface(true)}
        onMouseLeave={() => setOpenConnexionInterface(false)}
      >
        <FaRegUserCircle className="header__bandeau__user__icon" />
        CONNEXION
        <FaAngleDown />
      </button>
      {openConnexionInterface && (
        <div
          className="connexionInterface"
          onMouseEnter={() => setOpenConnexionInterface(true)}
          onMouseLeave={() => setOpenConnexionInterface(false)}
        >
          <ul>
            <li onClick={() => setVisibleModal(!visibleModal)}>Se connecter</li>
            <li
              onClick={() =>
                setVisibleModalCreationCompte(!visibleModalCreationCompte)
              }
            >
              Créer un compte
            </li>
          </ul>
        </div>
      )}
      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        ></HeaderUserLogin>
      )}

      {visibleModalCharte && (
        <ModalCharte
          visibleModal={visibleModalCharte}
          setVisibleModal={setvisibleModalCharte}
          btnAction={() => setvisibleModalCharte(false)}
          btnRefuse={() => setvisibleModalCharte(false)}
        >
          <div className="description">
            <ModalCharte />
          </div>
        </ModalCharte>
      )}

      {visibleModalCreationCompte && (
        <Modal
          visible={visibleModalCreationCompte}
          setVisible={setVisibleModalCreationCompte}
          header="Création de compte"
          className="modal-register"
        >
          <div className="description">
            <ModalCreationCompte
              setAuthEntrepriseVisible={setVisibleModalCreationCompte}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

headerUserDisconnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(headerUserDisconnected);

import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";
import "../FooterPage.scss";

const MentionsLegales = () => {
  return (
    <div>
      <Header />
      <div className="mentionsPage">
        <h1 className="h1_footerPage">Mentions légales</h1>
        <h3 className={"mentionsPage__title"}>
          <strong>Le « contactez-nous » :</strong>
        </h3>
        <p>Une question, un conseil, un problème ?</p>
        <p>
          Contactez les administrateurs des plateformes Réseau Pro sur ce mail
          générique :{" "}
          <a
            href="mailto:equipereseaupro@unpidf.fr"
            className={"mentionsPage__link"}
          >
            equipereseaupro@unpidf.fr
          </a>
        </p>

        <h3 className={"mentionsPage__title"}>
          <strong>Informations légales</strong>
        </h3>
        <p className="mentionsPage__contact">
          Université Numérique Île-de-France
          <br />
          Bureau 339
          <br />
          Tel : 01 55 93 93 53
          <br />
          Maison des Sciences Humaines Paris Nord
          <br />
          20, avenue Georges Sand
          <br />
          93210 La Plaine Saint-Denis
          <br />
          Service Interuniversitaire porté par l’Université Sorbonne Paris Nord
          <br />
          99, avenue Jean-Baptiste Clément
          <br />
          93430 Villetaneuse
          <br />
          Siret 199 312 380 00017 – APE 85.41Z
          <br />
          Numéro TVA Intracommunautaire : FR52 199 312 380
        </p>
        <p>
          L’Unif (ex. Unpidf.fr) est déclaré à la CNIL sous le numéro n° 710150
          délivré en application de l’article 16 de la loi du 6 Janvier 1978
          relative à l’informatique, aux fichiers et aux libertés.
        </p>
        <p>
          Ce site est protégé par la loi du 1er juillet 1998 sur la protection
          juridique des bases de données et de tous ses éléments, tel que les
          textes, les logos, les graphismes, les images et photos, qui sont la
          propriété exclusive de l’Université.
        </p>
        <p>
          Toute utilisation, y compris la reproduction même partielle, la
          modification, la distribution, la transmission, l’affichage du contenu
          ou de l’un quelconque des éléments de ce site est strictement
          interdite sans accord écrit de l’Université. Ces éléments sont
          protégés par la loi française et les lois internationales de
          protection de la propriété intellectuelle. Tout contrevenant à cette
          protection fera l’objet de poursuites.
        </p>
        <p>
          Sauf indications contraires (documents libres de droits), si vous
          souhaitez utiliser des photos ou des textes sur ce site, vous pouvez
          nous contacter à{" "}
          <a href="mailto:respadm@unif.fr" className={"mentionsPage__link"}>
            respadm@unif.fr
          </a>
          .
        </p>
        <p>
          Par ailleurs, l’utilisation des informations contenues sur le présent
          site relève de la seule responsabilité de l’utilisateur. Nous ne
          pourrions en aucun cas, et pour quelque cause que ce soit, en être
          tenus pour responsables, et ce, quelles qu’en soient les conséquences.
          Nous ne sommes responsables d’aucune erreur ou omission sur le présent
          site. De plus les bannières ou éléments de promotion quant à leur
          forme, leur contenu, leur caractère licite et leurs engagements et/ou
          conditions de promotion, sont sous la seule responsabilité de
          l’annonceur.
        </p>

        <h3 className={"mentionsPage__title"}>
          <strong>Extraits du Code de la Propriété Intellectuelle :</strong>
        </h3>
        <p>
          – Art L.335-2
          <br />
          <i>
            (Loi nº 94-102 du 5 février 1994 art. 1 Journal Officiel du 8
            février 1994)
            <br />
            (Loi nº 98-536 du 1 juillet 1998 art. 4 Journal Officiel du 2
            juillet 1998)
            <br />
            (Ordonnance nº 2000-916 du 19 septembre 2000 art. 3 Journal Officiel
            du 22 septembre 2000 en vigueur le 1er janvier 2002)
          </i>
          <br />
          Toute édition d’écrits, de composition musicale, de dessin, de
          peinture ou de toute autre production, imprimée ou gravée en entier ou
          en partie, au mépris des lois et règlements relatifs à la propriété
          des auteurs, est une contrefaçon ; et toute contrefaçon est un délit.
          La contrefaçon en France d’ouvrages publiés en France ou à l’étranger
          est punie de deux ans d’emprisonnement et de 150 000 euros d’amende.
          Seront punis des mêmes peines le débit, l’exportation et l’importation
          des ouvrages contrefaits.
        </p>
        <p>
          – Art L.335-3
          <br />
          <i>
            (Loi nº 94-361 du 10 mai 1994 art. 8 Journal Officiel du 11 mai
            1994)
            <br />
            (Loi nº 98-536 du 1 juillet 1998 art. 4 Journal Officiel du 2
            juillet 1998)
          </i>
          <br />
          Est également un délit de contrefaçon toute reproduction,
          représentation ou diffusion, par quelque moyen que ce soit, d’une
          œuvre de l’esprit en violation des droits de l’auteur, tels qu’ils
          sont définis et réglementés par la loi. Est également un délit de
          contrefaçon la violation de l’un des droits de l’auteur d’un logiciel
          définis à l’article L. 122-6.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default MentionsLegales;

import React, { useState, useEffect } from "react";
import "./Offres.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import GestionDepotOffre from "Components/Offres/GestionDepotOffre/GestionDepotOffre";
import { updateOffers } from "Redux/Actions/offersActions";
import DepotOffre from "Components/Offres/DepotOffre/DepotOffre";
import CandidaturesRecues from "Components/Candidatures/CandidaturesRecues/CandidaturesRecues";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useFetchGetConditional } from "Services/api";

/**
 * Permet d'afficher la page des offres d'emploi
 * Accessible pour tous les profils après connexion
 */
const Offres = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);

  const dataSecteurs = useFetchGetConditional(
    "/sector_of_offers",
    props.secondaryTables.sectorOfOffersData
  );
  const dataTypeOfContracts = useFetchGetConditional(
    "/type_of_contracts",
    props.secondaryTables.typeOfContractsData
  );
  const dataCategory = useFetchGetConditional(
    "/offer_categories",
    props.secondaryTables.offerCategoriesData
  );
  const dataLevelOfEducation = useFetchGetConditional(
    "/level_of_educations",
    props.secondaryTables.levelOfEducationsData
  );
  const dataDomains = useFetchGetConditional(
    "/domains",
    props.secondaryTables.domainsData
  );

  useEffect(() => {
    dataSecteurs.loaded &&
      dataTypeOfContracts.loaded &&
      dataCategory.loaded &&
      dataLevelOfEducation.loaded &&
      dataDomains.loaded &&
      props.handleUpdateSecondaryTables({
        sectorOfOffersData: dataSecteurs.data,
        typeOfContractsData: dataTypeOfContracts.data,
        offerCategoriesData: dataCategory.data,
        levelOfEducationsData: dataLevelOfEducation.data,
        domainsData: dataDomains.data,
      });
  }, [
    dataSecteurs.loaded,
    dataTypeOfContracts.loaded,
    dataCategory.loaded,
    dataLevelOfEducation.loaded,
    dataDomains.loaded,
  ]);
  // remplissage de Redux avec les données des l'api pour les offres
  useEffect(() => {
    if (props.states.statesData.length > 0) {
      const dataOfferStatusModified = props.states.statesData.map((state) => {
        return {
          id: state.id,
          statusLabel: state.statusLabel,
          name: state.statusLabel,
        };
      });
      // Adapte les différents status disponibles en fonction du rôle
      const listOfStatus = ["Brouillon", "Refusé", "En Attente", "Publié"];
      props.handleUpdateOffers({
        statusData: dataOfferStatusModified.filter(
          (state) => listOfStatus.indexOf(state.name) !== -1
        ),
      });
    }
  }, [props.states.statesData.length]);
  return (
    <div className="page_offre">
      <Header>
        {!props.auth.isConnected && <ButtonsBarDisconnected />}
        {props.auth.isConnected &&
          props.items.activeItemOffre ==
            props.items.itemsOffre.findIndex(
              (v) => v.label == "Déposer une offre"
            ) && (
            <NavBarTab
              select="offre"
              activeIndex={props.items.activeItemOffre}
              items={props.items.itemsOffre}
            />
          )}
        {props.auth.isConnected &&
          props.items.activeItemOffre ==
            props.items.itemsOffre.findIndex(
              (v) => v.label == "Gérer mes offres"
            ) && (
            <NavBarTab
              select="offre"
              activeIndex={props.items.activeItemOffre}
              items={props.items.itemsOffre}
            />
          )}
        {props.auth.isConnected &&
          props.items.activeItemOffre ==
            props.items.itemsOffre.findIndex(
              (v) => v.label == "Candidatures reçues"
            ) && (
            <NavBarTab
              select="offre"
              activeIndex={props.items.activeItemOffre}
            />
          )}
      </Header>
      {props.auth.isConnected &&
        props.items.activeItemOffre ==
          props.items.itemsOffre.findIndex(
            (v) => v.label == "Déposer une offre"
          ) && (
          <DepotOffre
            activeIndex={props.items.itemsOffre.findIndex(
              (v) => v.label == "Déposer une offre"
            )}
            setActiveIndex={setActiveIndex}
            items={props.items.itemsOffre}
          />
        )}
      {props.auth.isConnected &&
        props.items.activeItemOffre ==
          props.items.itemsOffre.findIndex(
            (v) => v.label == "Gérer mes offres"
          ) && (
          <GestionDepotOffre
            activeIndex={props.items.itemsOffre.findIndex(
              (v) => v.label == "Gérer mes offres"
            )}
            setActiveIndex={setActiveIndex}
            items={props.items.itemsOffre}
          />
        )}
      {props.auth.isConnected &&
        props.items.activeItemOffre ==
          props.items.itemsOffre.findIndex(
            (v) => v.label == "Candidatures reçues"
          ) && (
          <CandidaturesRecues
            activeIndex={props.items.itemsOffre.findIndex(
              (v) => v.label == "Candidatures reçues"
            )}
            setActiveIndex={setActiveIndex}
            items={props.items.itemsOffre}
          />
        )}
      <Footer />
    </div>
  );
};

Offres.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateOffers: PropTypes.func,
  offers: PropTypes.object,
  states: PropTypes.object,
  secondaryTables: PropTypes.object,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
  states: state.states,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Offres);

// 20220620120437
// https://raw.githubusercontent.com/primefaces/primelocale/main/fr.json
export function getFrenchLocale() {
  return {
    fr: {
      startsWith: "Commence par",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Se termine par",
      equals: "Egal à",
      notEquals: "Différent de",
      noFilter: "Aucun filtre",
      lt: "Inférieur à",
      lte: "Inférieur ou égal à",
      gt: "Supérieur à",
      gte: "Supérieur ou égal à",
      dateIs: "La date est",
      dateIsNot: "La date n'est pas",
      dateBefore: "Avant le",
      dateAfter: "Après le",
      custom: "Personnalisé",
      clear: "Effacer",
      apply: "Appliquer",
      matchAll: "Correspond à tous",
      matchAny: "Au moins un Correspond",
      addRule: "Ajouter une règle",
      removeRule: "Retirer une règle",
      accept: "Oui",
      reject: "Non",
      choose: "Choisir",
      upload: "Envoyer",
      cancel: "Annuler",
      dayNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Mar", "Mer", "Je", "Ve", "Sa"],
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jun",
        "Jui",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Aujourd'hui",
      weekHeader: "Sem",
      firstDayOfWeek: 1,
      dateFormat: "dd/mm/yy",
      weak: "Faible",
      medium: "Moyen",
      strong: "Fort",
      passwordPrompt: "Saisissez un mot de passe",
      emptyMessage: "Aucune option disponible",
      emptyFilterMessage: "Aucun résultat trouvé",
      aria: {
        trueLabel: "Vrai",
        falseLabel: "Faux",
        nullLabel: "Aucune sélection",
        pageLabel: "Page",
        firstPageLabel: "Première Page",
        lastPageLabel: "Dernière Page",
        nextPageLabel: "Page Suivante",
        previousPageLabel: "Page Précédente",
      },
    },
  };
}

import { fetchPost } from "Services/api";
import {
  formatDateFrenchLocale,
  getDateDifference,
  showError,
  showSuccess,
} from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CandidaturesCard from "../CandidaturesCard/CandidaturesCard";
import "./CandidaturesContainer.scss";

const CandidaturesContainer = (props) => {
  const [candidatures, setCandidatures] = useState(null);

  const deleteCandidature = async (id) => {
    const deleteResponse = await fetchPost(`/candidatures/${id}/not_accepted`);
    if (deleteResponse.error) {
      showError("Une erreur est survenue lors de la suppression");
      return;
    }
    showSuccess("La candidature a bien été rejetée");
    props.setCandidatures((prev) => prev.filter((x) => x.id !== id));
  };

  useEffect(() => {
    if (!props.candidatures) return;

    let tempCandidatures = props.candidatures;
    if (props.sorted) {
      tempCandidatures = tempCandidatures.sort((a, b) => {
        return new Date(b.dateOfCandidature) - new Date(a.dateOfCandidature);
      });
    }
    if (props.displayNumber) {
      tempCandidatures = tempCandidatures.slice(0, props.displayNumber);
    }

    setCandidatures(tempCandidatures);
  }, [props.candidatures]);
  return (
    <>
      {candidatures?.map((candidature) => (
        <CandidaturesCard
          key={candidature.id}
          refe={candidature.offer.title}
          timeDifference={getDateDifference(
            new Date(),
            new Date(candidature.dateOfCandidature)
          )}
          time={formatDateFrenchLocale(candidature.dateOfCandidature)}
          name={candidature.firstName + " " + candidature.secondName}
          poste=""
          message={candidature.message || null}
          image={candidature.profileImageUrl}
          id={candidature.id}
          university={candidature.university}
          onDelete={(id) => deleteCandidature(id)}
        />
      ))}
    </>
  );
};

CandidaturesContainer.propTypes = {
  sorted: PropTypes.bool,
  displayNumber: PropTypes.number,
  candidatures: PropTypes.object,
  setCandidatures: PropTypes.func,
};

export default CandidaturesContainer;

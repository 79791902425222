import React, { useEffect, useState } from "react";
import "./InformationsEntreprise.scss";
import Header from "Components/Header/Header";
import PropTypes from "prop-types";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import { connect } from "react-redux";
import Footer from "Components/Footer/Footer";
import { Controller, useForm } from "react-hook-form";
import { fetchPost, fetchPut, useFetchGet } from "Services/api";
import { InputText } from "primereact/inputtext";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";
import Loader from "Components/Loader/loader";
import {
  displayCompanyImage,
  showError,
  showSuccess,
} from "Services/functions";
import ReactQuill from "react-quill";
import QuillOptions from "../../Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";

const InformationsEntreprise = (props) => {
  /*********************************************************************/
  /*****************************    HOOKS    ***************************/
  /*********************************************************************/

  const location = useLocation();
  const id = location.state.params;

  const [companyCategory, setCompanyCategory] = useState(null);
  const [isLoader, setIsLoader] = useState(true);

  const company = useFetchGet(`/companies/${id}`);
  const structuresData = useFetchGet("/company_structures");
  const effectifData = useFetchGet("/company_total_staffs");
  const sectorData = useFetchGet("/sector_of_offers");
  const typeEntrepriseData = useFetchGet("/company_types");

  useEffect(() => {
    if (company.loaded && company.data) {
      reset({
        name: company.data.name,
        description: DOMPurify.sanitize(company.data.description),
        siret:
          company.data.type.label === "Entreprise située en France"
            ? company.data.siret
            : company.data.type.label === "Entreprise internationale"
            ? company.data.duns
            : company.data.rna,
        sector: company.data.sector,
        imageUrl: company.data.imageUrl,
        structure: company.data.structure,
        totalStaff: company.data.totalStaff,
      });
      setCompanyCategory(company.data.type);
      setIsLoader(false);
    }
  }, [company.loaded]);

  /*********************************************************************/
  /**************************    FORM SETUP    *************************/
  /*********************************************************************/

  let defaultValues = {
    name: "",
    description: "",
    siret: "",
    sector: "",
    structure: "",
    totalStaff: "",
    imageUrl: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">La valeur est requise</small>
    );
  };

  /*********************************************************************/
  /*********************    SUBMIT & DATA PROCESS    *******************/
  /*********************************************************************/

  const onSubmit = () => {
    setIsLoader(true);
    const data = getValues();

    const formattedData = formatData(data);
    modifyCompany(formattedData);
  };

  const formatData = (data) => {
    let newData = {
      name: data.name,
      description: data.description,
      sector: `/api/sector_of_offers/${data.sector.id}`,
      type: `/api/company_types/${companyCategory.id}`,
    };

    // Données à ajouter dans newData si l'entité est une entreprise française
    if (companyCategory.label === "Entreprise située en France") {
      newData.structure = `/api/company_structures/${data.structure.id}`;
      newData.totalStaff = `/api/company_total_staffs/${data.totalStaff.id}`;

      newData.siret = data.siret;
      newData.duns = null;
      newData.rna = null;
    }

    // Données à ajouter dans newData si l'entité est une entreprise internationale
    if (companyCategory.label === "Entreprise internationale") {
      newData.duns = data.siret;
      newData.rna = null;
      newData.siret = null;
      newData.structure = null;
      newData.totalStaff = null;

      setValue("structure", null);
      setValue("totalStaff", null);
    }

    // Données à ajouter dans newData si l'entité est une association
    if (companyCategory.label === "Association") {
      newData.totalStaff = `/api/company_total_staffs/${data.totalStaff.id}`;

      newData.rna = data.siret;
      newData.duns = null;
      newData.siret = null;
      newData.structure = null;

      setValue("structure", null);
    }

    return newData;
  };

  const modifyCompany = async (formattedData) => {
    const response = await fetchPut(`/companies/${id}`, formattedData);
    if (response.error) {
      showError("Une erreur est survenue lors de la modification");
      setIsLoader(false);
      return;
    }
    await updateImage();
    showSuccess("Les informations de votre entreprise ont bien été modifiées");
    setIsLoader(false);
  };

  /**
   * Fonction s'occuppant de l'upload d'une image
   */
  const uploadHandler = ({ files }) => {
    const file = files[0];
    const fileReader = new FileReader();

    if (!file.type.match("image.*")) {
      showError("Le fichier sélectionné n'est pas une image");
      return;
    }

    fileReader.onload = () => {
      setValue("imageUrl", file);
      showSuccess("Le fichier " + file.name + " a bien été téléchargé");
    };
    fileReader.readAsDataURL(file);
  };

  /**
   * Permet de mettre à jour l'image de l'entreprise, si une image a été sélectionnée
   */
  const updateImage = async () => {
    const imageUrl = getValues("imageUrl");
    // Si aucune image n'a été sélectionnée, ou si l'image est la même que celle actuelle, on ne fait rien
    if (!imageUrl || imageUrl === company.data.imageUrl) return;
    // Création d'un multipart form data avec l'image (clé 'imageFile')
    const formData = new FormData();
    formData.append("imageFile", imageUrl);

    const response = await fetchPost(
      `/companies/${company.data.id}/updatePicture`,
      formData
    );
    if (response.error)
      showError(
        "Une erreur est survenue lors de la modification de l'image de l'entreprise"
      );
  };

  //? Permet de rerender si une nouvelle image est sélectionnée
  useEffect(() => {}, [watch("imageUrl")]);

  return (
    <div className="info_entreprise">
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && <ProfilBar id="desktop" />}
        </div>
      </Header>
      <div className="info_entreprise__container">
        <h1 className="h1_trait_dessus">
          Modifier les informations entreprise
        </h1>
        {!isLoader ? (
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="block">
              <div className="field">
                <h2>Vous êtes : </h2>

                <div className="checkboxes">
                  <div className="checkbox">
                    <Checkbox
                      className="checkbox_french"
                      checked={
                        companyCategory.label === "Entreprise située en France"
                      }
                      onChange={() => {
                        setCompanyCategory(
                          typeEntrepriseData.data.filter(
                            (type) =>
                              type.label === "Entreprise située en France"
                          )[0]
                        );
                      }}
                    />
                    <label htmlFor="french">Entreprise située en France</label>
                    {getFormErrorMessage("french")}
                  </div>
                  <div className="checkbox">
                    <Checkbox
                      className="checkbox_international"
                      checked={
                        companyCategory.label === "Entreprise internationale"
                      }
                      onChange={() => {
                        setCompanyCategory(
                          typeEntrepriseData.data.filter(
                            (type) => type.label === "Entreprise internationale"
                          )[0]
                        );
                      }}
                    />
                    <label htmlFor="international">
                      Une entreprise internationale
                    </label>
                    {getFormErrorMessage("international")}
                  </div>
                  <div className="checkbox">
                    <Checkbox
                      className="checkbox_association"
                      checked={companyCategory.label === "Association"}
                      onChange={() => {
                        setCompanyCategory(
                          typeEntrepriseData.data.filter(
                            (type) => type.label === "Association"
                          )[0]
                        );
                      }}
                    />
                    <label htmlFor="association">Une association</label>
                    {getFormErrorMessage("association")}
                  </div>
                </div>
              </div>
            </div>

            <div className="block">
              <div className="field">
                <h2>
                  {companyCategory.label === "Association"
                    ? "N° RNA de l'association : "
                    : companyCategory.label === "Entreprise internationale"
                    ? "N° de DUNS de l'entreprise : "
                    : "N° de SIRET de l'entreprise : "}
                </h2>
                <Controller
                  name="siret"
                  control={control}
                  rules={{ required: "Le numéro de SIRET est requis" }}
                  render={({ field, fieldState }) => (
                    <InputMask
                      id={field.name}
                      mask={
                        companyCategory.label === "Entreprise située en France"
                          ? "999 999 999 99999"
                          : companyCategory.label ===
                            "Entreprise internationale"
                          ? "99 999 9999"
                          : "999 999 999"
                      }
                      value={field.value}
                      placeholder={
                        companyCategory.label === "Entreprise située en France"
                          ? "999 999 999 99999"
                          : companyCategory.label ===
                            "Entreprise internationale"
                          ? "99 999 9999"
                          : "999 999 999"
                      }
                      autoClear={false}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("siret")}
              </div>

              <div className="field">
                <h2>
                  {companyCategory.label === "Association"
                    ? "Nom de l'association : "
                    : "Nom de l'entreprise : "}
                </h2>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Le nom est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      placeholder="Actimage ..."
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("name")}
              </div>
            </div>

            <div className="block">
              <div className="field">
                <h2>Logo de l&apos;entreprise :</h2>
                <Image
                  className="entreprise_img"
                  src={displayCompanyImage(company.data, getValues("imageUrl"))}
                  alt="Logo de l'entreprise"
                />
                <FileUpload
                  customUpload={true}
                  uploadHandler={uploadHandler}
                  accept="image/*"
                  cancelLabel="Annuler"
                  uploadLabel="Télécharger"
                  // Max size 1 Mo
                  className="entreprise_upload"
                  maxFileSize={1000000}
                  chooseLabel="Choisir une image"
                ></FileUpload>
              </div>
            </div>

            <div className="block">
              <div className="field entreprise_description">
                <h2>
                  {companyCategory.label === "Association"
                    ? "Description de l'association : "
                    : "Description de l'entreprise : "}
                </h2>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "La description est requise" }}
                  render={({ field, fieldState }) => (
                    <ReactQuill
                      id={field.name}
                      {...field}
                      theme="snow"
                      modules={QuillOptions.modules}
                      formats={QuillOptions.formats}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      rows={5}
                    />
                  )}
                />
                {getFormErrorMessage("description")}
              </div>
            </div>

            {structuresData.loaded &&
            sectorData.loaded &&
            effectifData.loaded ? (
              <div className="block">
                {companyCategory.label === "Entreprise située en France" && (
                  <div className="field">
                    <h2>Structure de l&apos;entreprise : </h2>
                    <Controller
                      name="structure"
                      control={control}
                      rules={{
                        required:
                          "La structure de l'entreprise est requise" |
                          (companyCategory.label ===
                            "Entreprise située en France"),
                      }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          value={
                            getValues("structure") &&
                            structuresData.data.filter(
                              (structure) =>
                                structure.id === getValues("structure").id
                            )[0]
                          }
                          optionLabel="label"
                          options={structuresData.data.filter(
                            (structure) => structure.label !== "Association"
                          )}
                          placeholder="PME, TPE, ..."
                          onChange={(e) => field.onChange(e.value)}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    {getFormErrorMessage("structure")}
                  </div>
                )}
                {companyCategory.label !== "Entreprise internationale" && (
                  <div className="field">
                    <h2>Effectif : </h2>
                    <Controller
                      name="totalStaff"
                      control={control}
                      rules={{
                        required:
                          "L'effectif est requis" |
                          (companyCategory.label !==
                            "Entreprise internationale"),
                      }}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          value={
                            getValues("totalStaff") &&
                            effectifData.data.filter(
                              (effectif) =>
                                effectif.id === getValues("totalStaff").id
                            )[0]
                          }
                          optionLabel="label"
                          options={effectifData.data}
                          placeholder="Nombre de salariés"
                          onChange={(e) => field.onChange(e.value)}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    {getFormErrorMessage("totalStaff")}
                  </div>
                )}
                <div className="field">
                  <h2>
                    {companyCategory.label === "Association"
                      ? "Secteur de l'association : "
                      : "Secteur de l'entreprise : "}
                  </h2>
                  <Controller
                    name="sector"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        id={field.name}
                        value={
                          sectorData.data.filter(
                            (sector) => sector.id === getValues("sector").id
                          )[0]
                        }
                        placeholder="Programmation, publicité..."
                        optionLabel="label"
                        options={sectorData.data}
                        onChange={(e) => field.onChange(e.value)}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  {getFormErrorMessage("sector")}
                </div>
              </div>
            ) : (
              <Loader></Loader>
            )}
            <div className="info_entreprise__container-submit">
              <BtnBlanc btnTexte="Modifier" />
            </div>
          </form>
        ) : (
          <Loader></Loader>
        )}
      </div>
      <Footer />
    </div>
  );
};
InformationsEntreprise.propTypes = {
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationsEntreprise);

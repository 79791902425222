import React from "react";
import PropTypes from "prop-types";
import "./Chiffre.scss";

const Chiffre = (props) => {
  return (
    <div className="chiffre">
      <h1>{props.title}</h1>
      <center>
        <p>{props.description}</p>
      </center>
    </div>
  );
};

Chiffre.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Chiffre;

import React from "react";
import { TabMenu } from "primereact/tabmenu";
import PropTypes from "prop-types";
// import { useState } from "react";
// import Footer from "../../../Footer/Footer";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { ScrollPanel } from "primereact/scrollpanel";
import "./NavBarTab.scss";

const NavBarTab = (props) => {
  return (
    <div className="styleNavBarActive" id={props.id}>
      <div className="navBarProfil">
        {props.select == "annuaire" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsAnnuaire}
              activeIndex={props.items.activeItemAnnuaire}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "profil" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsProfil}
              activeIndex={props.items.activeItemProfil}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemProfil: props.items.itemsProfil.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "cvtheque" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsCVTheque}
              activeIndex={props.items.activeItemCVTheque}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "depotoffre" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsDepotOffre}
              activeIndex={props.items.activeItemDepotOffre}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemDepotOffre: props.items.itemsDepotOffre.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "gestionoffre" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            {" "}
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsGestionOffre}
              activeIndex={props.items.activeItemGestionOffre}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemGestionOffre:
                    props.items.itemsGestionOffre.findIndex(
                      (v) => v.label == e.value.label
                    ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "groupes" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsGroupes}
              activeIndex={props.items.activeItemGroupes}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemGroupes: props.items.itemsGroupes.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "offre" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsOffre}
              activeIndex={props.items.activeItemOffre}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemOffre: props.items.itemsOffre.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "evenement" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsEvenement}
              activeIndex={props.items.activeItemEvenement}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemEvenement: props.items.itemsEvenement.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "evenementadmin" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsEvenementAdmin}
              activeIndex={props.items.activeItemEvenementAdmin}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemEvenementAdmin:
                    props.items.itemsEvenementAdmin.findIndex(
                      (v) => v.label == e.value.label
                    ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "actu" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsActu}
              activeIndex={props.items.activeItemActu}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemActu: props.items.itemsActu.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "actusadmin" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsActuAdmin}
              activeIndex={props.items.activeItemActuAdmin}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
      </div>
    </div>
  );
};

NavBarTab.propTypes = {
  select: PropTypes.string,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  id: PropTypes.string,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarTab);

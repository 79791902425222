import React from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "../FooterPage.scss";

const Contact = () => {
  const CONTACT_EMAIL = "reseau_pro@unif.fr";

  return (
    <div>
      <Header />
      <div className="contactPage">
        <h1 className="h1_footerPage">Contactez-nous</h1>
        <div>
          <h3 className="contactPage__title">
            Une question, un conseil, un problème ?
          </h3>
          <p>
            {" "}
            Contactez les administrateurs des plateformes Réseau Pro sur ce mail
            générique :{" "}
            <span className="contactPage__email">
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </span>
          </p>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
};

export default Contact;

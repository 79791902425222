import { updateAuth } from "Redux/Actions/authActions";
import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { FaHotel } from "react-icons/fa";
import { connect } from "react-redux";
import BtnBlanc from "../..//Boutons/BtnBlanc/btn-blanc";
import HeaderUserLogin from "../HeaderTop/HeaderUserLogin/HeaderUserLogin";
import "./ButtonsBarDisconnected.scss";

const ButtonsBarDisconnected = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <div className="buttonsBarDisconnected">
      <HeaderUserLogin
        loginVisible={visibleModal}
        setLoginVisible={setVisibleModal}
      />
      <h2>ACCÉDEZ À VOTRE ESPACE</h2>
      <div className="buttons">
        <div className="bouton-visible">
          <BtnBlanc
            btnTexte="SE CONNECTER"
            btnAction={() => setVisibleModal(!visibleModal)}
          >
            <FaHotel size={"1rem"} className="icon-button" />
          </BtnBlanc>
        </div>
      </div>
    </div>
    // </div>
  );
};

ButtonsBarDisconnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonsBarDisconnected);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./CandidaturesByOffer.scss";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { Link, useLocation } from "react-router-dom";
import Pagination from "Components/Pagination/Pagination";
import Footer from "Components/Footer/Footer";
import { useFetchGet } from "Services/api";
import Loader from "Components/Loader/loader";
import CandidaturesContainer from "../CandidaturesContainer/CandidaturesContainer";

// Affiche toutes les candidatures liées à une certaine offre
const CandidaturesByOffer = (props) => {
  const offerId = useLocation().state.offerId;
  const candidaturesQuery = useFetchGet(`/candidatures?offer=${offerId}`);
  const [candidaturesData, setCandidaturesData] = useState(null);

  useEffect(() => {
    candidaturesQuery.loaded &&
      candidaturesQuery.data &&
      setCandidaturesData(candidaturesQuery.data);
  }, [candidaturesQuery.loaded]);

  return (
    <div>
      <Header>
        <NavBarTab
          select="offres"
          activeIndex={props.activeIndex}
          items={props.items}
        />
      </Header>

      <div className="candidatures_profil">
        <h1 className="h1_trait_dessus">CANDIDATURES PAR PROFIL</h1>
        <div className="candidatures_profil__subtitle">
          {candidaturesData && (
            <h3>
              Pour cette offre vous avez reçu{" "}
              <strong>{candidaturesData.length} candidature(s)</strong>
            </h3>
          )}
          <button className="btn-blanc">
            <Link to={{ pathname: `/offres` }}>Retourner aux candidatures</Link>
          </button>
        </div>
        <div className="candidatures_profil__container">
          {candidaturesData ? (
            <CandidaturesContainer
              candidatures={candidaturesData}
              setCandidatures={setCandidaturesData}
            ></CandidaturesContainer>
          ) : (
            <Loader></Loader>
          )}
        </div>
      </div>
      <Pagination />
      <Footer />
    </div>
  );
};
CandidaturesByOffer.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  nbrCandidatures: PropTypes.number,
  id: PropTypes.number,
};

export default CandidaturesByOffer;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { updateMenu } from "Redux/Actions/menuActions";
import { connect } from "react-redux";
import { Divider } from "primereact/divider";

/**
 * Construit les menus déroulant de la barre de navigation sur desktop
 */
const MenuDepliant = (props) => {
  function navigation(chemin, item) {
    if (window.location.pathname != chemin) {
      window.location.pathname = chemin;
    }
    switch (chemin) {
      case "/cvtheque":
        props.handleMenu({
          activeItemCVTheque: props.items.itemsCVTheque.findIndex(
            (v) => v.label == item
          ),
        });
        break;
      case "/offres":
        props.handleMenu({
          activeItemOffre: props.items.itemsOffre.findIndex(
            (v) => v.label == item
          ),
        });
        break;
      case "/evenements":
        props.handleMenu({
          activeItemEvenement: props.items.itemsEvenement.findIndex(
            (v) => v.label == item
          ),
        });
        break;
      case "/actualites":
        props.handleMenu({
          activeItemActu: props.items.itemsActu.findIndex(
            (v) => v.label == item
          ),
        });
        break;
      default:
        break;
    }

    return true;
  }

  return (
    <div>
      {props.item == "event" && (
        <ul>
          <li onClick={() => navigation("/evenements", "Tous les événements")}>
            <div className="annuaireBox">
              <Link to="/evenements">Tous les événements</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={() => navigation("/evenements", "Proposer un événement")}
          >
            <div className="annuaireBox">
              <Link to="/evenements" className="alumni">
                Proposer un événement
              </Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/evenements", "Gérer mes événements")}>
            <div className="annuaireBox">
              <Link to="/evenements">Gérer mes événements</Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "actu" && (
        <ul>
          <li onClick={() => navigation("/actualites", "Toutes les actus")}>
            <div className="annuaireBox">
              <Link to="/actualites">Toutes les actus</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/actualites", "Proposer une actu")}>
            <div className="annuaireBox">
              <Link to="/actualites">Proposer une actu</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/actualites", "Gérer mes actus")}>
            <div className="annuaireBox">
              <Link to="/actualites">Gérer mes actus</Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "offres" && (
        <ul>
          <li onClick={() => navigation("/offres", "Déposer une offre")}>
            <div className="annuaireBox">
              <Link to="/offres">Déposer une offre</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/offres", "Gérer mes offres")}>
            <div className="annuaireBox">
              <Link to="/offres">Gérer mes offres</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/offres", "Candidatures reçues")}>
            <div className="annuaireBox">
              <Link to="/offres">Candidatures reçues</Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "cvtheque" && (
        <ul>
          <li onClick={() => navigation("/cvtheque", "Cvthèque")}>
            <div className="annuaireBox">
              <Link to="/cvtheque">Cvthèque</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/cvtheque", "Cv sauvegardés")}>
            <div className="annuaireBox">
              <Link to="/cvtheque">Cv sauvegardés</Link>
            </div>
          </li>
          <Divider />
          <li onClick={() => navigation("/cvtheque", "Paniers de cv")}>
            <div className="annuaireBox">
              <Link to="/cvtheque">Paniers de cv</Link>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

MenuDepliant.propTypes = {
  item: PropTypes.string,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuDepliant);

import React, { useState, useEffect } from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import "./Profil.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ParamConfidentialite from "Components/EditionProfil/ParamConfidentialite/ParamConfidentialite";
import { fetchGet, useFetchGet } from "Services/api";
import { updateAuth } from "Redux/Actions/authActions";
import InformationsPerso from "Components/EditionProfil/InformationsPerso/InformationsPerso";
import { showError } from "Services/functions";

const Profil = (props) => {
  const [offres, setOffres] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loaderVisible, setLoaderVisible] = useState(false);
  const getEvents = async () => {
    const response = await fetchGet(`/offers`);
    setLoaderVisible(false);
    if (response.error) {
      showError("Une erreur est survenue");
      return;
    }
    setOffres(response.data);
  };

  useEffect(() => {
    setLoaderVisible(true);
    getEvents();
  }, []);

  const user = useFetchGet(`/recruiters/${props.auth.userConnected.id}`);
  useEffect(() => {
    if (user && user.data && user.data.length > 0)
      props.handleUpdateAuth({ userConnected: user.data });
  }, [user]);
  return (
    <div className="profilPage">
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && <ProfilBar id="desktop" />}
          {props.auth.isConnected
            ? props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Paramètres de confidentialité"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )
            : ""}
        </div>
      </Header>
      {props.auth.isConnected
        ? props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Informations personnelles"
            ) && (
            <InformationsPerso
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Informations personnelles"
              )}
              items={props.items.itemsProfil}
            />
          )
        : ""}
      {props.auth.isConnected
        ? props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Paramètres de confidentialité"
            ) && (
            <ParamConfidentialite
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Paramètres de confidentialité"
              )}
              items={props.items.itemsProfil}
              offres={offres}
            />
          )
        : ""}
      <Footer />
    </div>
  );
};

Profil.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profil);

import React, { useRef } from "react";
import "./ButtonsBarConnected.scss";
import BtnRond from "../..//Boutons/BtnRond/btn-rond";
import {
  // FaMapMarkerAlt,
  FaDownload,
  FaRegCalendarCheck,
  FaEllipsisH,
  FaUserCog,
  FaFileAlt,
  // FaPaste,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { Menu } from "primereact/menu";

const ButtonsBarConnected = (props) => {
  const menuMobileConnected = useRef(null);
  let itemsMobileConnected = [
    {
      label: "Déposer une offre d'emploi",
      command: () => {
        window.location.href = "/offres";
        props.handleMenu({
          activeItemOffre: props.items.itemsOffre.findIndex(
            (v) => v.label == "Déposer une offre"
          ),
        });
      },
    },
    {
      label: "Déposer une actu",
      command: () => {
        window.location.href = "/actualites";
        props.handleMenu({
          activeItemActu: props.items.itemsActu.findIndex(
            (v) => v.label == "Proposer une actu"
          ),
        });
      },
    },
    {
      label: "Déposer un événement",
      command: () => {
        window.location.href = "/evenements";
        props.handleMenu({
          activeItemEvenement: props.items.itemsEvenement.findIndex(
            (v) => v.label == "Tous les événements"
          ),
        });
      },
    },
    // {
    //   label: "Paniers de cv",
    //   command: () => {
    //     window.location.href = "/cvtheque";
    //     props.handleMenu({
    //       activeItemCVTheque: props.items.itemsCVTheque.findIndex(
    //         (v) => v.label == "Paniers de cv"
    //       ),
    //     });
    //   },
    // },
  ];
  return (
    <div className="buttonsBarConnected">
      <div className="buttons">
        <div className="bouton-cache-mobile">
          <Link to="/profil">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemProfil: props.items.itemsProfil.findIndex(
                    (v) => v.label == "Informations personnelles"
                  ),
                })
              }
            >
              <FaUserCog size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <Link to="/offres">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemOffre: props.items.itemsOffre.findIndex(
                    (v) => v.label == "Déposer une offre"
                  ),
                })
              }
            >
              <FaDownload size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <Link to="/actualites">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemActu: props.items.itemsActu.findIndex(
                    (v) => v.label == "Proposer une actu"
                  ),
                })
              }
            >
              <FaFileAlt size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <Link to="/evenements">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemEvenement: props.items.itemsEvenement.findIndex(
                    (v) => v.label == "Proposer un événement"
                  ),
                })
              }
            >
              <FaRegCalendarCheck size={"2rem"} />
            </BtnRond>
          </Link>
        </div>
        {/*<div className="bouton-cache-mobile">*/}
        {/*  <Link to="/cvtheque">*/}
        {/*    <BtnRond*/}
        {/*      btnAction={() =>*/}
        {/*        props.handleMenu({*/}
        {/*          activeItemCVTheque: props.items.itemsCVTheque.findIndex(*/}
        {/*            (v) => v.label == "Paniers de cv"*/}
        {/*          ),*/}
        {/*        })*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <FaPaste size={"2rem"} />*/}
        {/*    </BtnRond>*/}
        {/*  </Link>*/}
        {/*</div>*/}

        <div className="bouton-cache-mobile">
          <p>Gérer mon profil</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>Déposer une offre d&apos;emploi</p>
        </div>

        <div className="bouton-cache-mobile">
          <p>Déposer une actu</p>
        </div>

        <div className="bouton-cache-mobile">
          <p>Déposer un événement</p>
        </div>
        {/*<div className="bouton-cache-mobile">*/}
        {/*  <p>Paniers de cv</p>*/}
        {/*</div>*/}

        <div className="bouton-visible-mobile">
          <Link to="/profil">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemProfil: props.items.itemsProfil.findIndex(
                    (v) => v.label == "Informations personnelles"
                  ),
                })
              }
            >
              <FaUserCog size={"2rem"} />
            </BtnRond>
          </Link>
          <p>Gérer mon profil</p>
        </div>

        <div className="bouton-visible-mobile navigation__hamburger">
          <Menu
            model={itemsMobileConnected}
            popup
            ref={menuMobileConnected}
            style={{ textAlign: "center", width: "100%", left: "0" }}
          />
          <BtnRond
            btnAction={(event) => menuMobileConnected.current.toggle(event)}
          >
            <FaEllipsisH size={"2rem"} />
          </BtnRond>
          <p>Découvrir plus de fonctionnalités</p>
        </div>
      </div>
    </div>
  );
};

ButtonsBarConnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonsBarConnected);

import React, { useEffect } from "react";
import "./UniversitiesPanel.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const UniversitiesPanel = (props) => {
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

  //? Université actuelle (pour le cas où l'on modifie une entité)
  const [currentUniversity, setCurrentUniversity] = React.useState(null);

  useEffect(() => {
    if (props.editUnivId) {
      const univ = props.universities.universitiesData.find(
        (university) => university.id === props.editUnivId
      );
      univ && setCurrentUniversity(univ);
      univ && props.setListUniversities([univ]);
    }
  }, [props.editUnivId]);

  return (
    <div className="universities">
      {/* Dans le cas où l'on modifie une entité, on récupère l'université correspondante
   et non pas toutes les universités
   De plus l'université sélectionnée ne peut pas être désélectionnée */}
      {props.editUnivId && currentUniversity ? (
        <img
          src={API_LINK + currentUniversity.imageTransparentAndColorUrl}
          alt="Logo univ"
          className={"isSelected"}
          onClick={() => null}
        />
      ) : (
        // Sinon on affiche toutes les universités, et on peut les sélectionner ou les désélectionner
        // en cliquant dessus (comportement 'normal' du composant)
        props.universities.universitiesData.map((university, index) => (
          <img
            key={index}
            src={API_LINK + university.imageTransparentAndColorUrl}
            alt="Logo univ"
            className={
              props.listUniversities.length > 0 &&
              props.listUniversities.includes(university)
                ? "isSelected"
                : ""
            }
            onClick={() => {
              props.listUniversities.length > 0 &&
              props.listUniversities.includes(university)
                ? props.setListUniversities(
                    props.listUniversities.filter(
                      (univ) => univ.slug !== university.slug
                    )
                  )
                : props.setListUniversities([
                    ...props.listUniversities,
                    ...[university],
                  ]);
            }}
          />
        ))
      )}
    </div>
  );
};

UniversitiesPanel.propTypes = {
  universities: PropTypes.object,
  listUniversities: PropTypes.array,
  setListUniversities: PropTypes.func,
  editUnivId: PropTypes.number,
};
const mapStateToProps = (state) => ({
  universities: state.universities,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UniversitiesPanel);

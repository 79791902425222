import Quill from "quill";

// On ajoute un module pour gérer les liens dans le Quill
// Au lieu de créer un lien relatif, on ajoute le http:// ou https://
const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};

const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const QuillNoToolbarOptions = {
  modules: {
    ...quillModules,
    toolbar: false,
  },
  formats: quillFormats,
};

const QuillOptions = {
  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  modules: quillModules,
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  formats: quillFormats,
};

export default QuillOptions;

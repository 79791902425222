import { UPDATE_AUTH } from "Redux/Actions/authActions";
import { store } from "Redux/store";
import axios from "axios";
import { useEffect, useState } from "react";

const updateAuth = (value) => {
  return {
    type: UPDATE_AUTH,
    value,
  };
};

export const disconnectUser = () => {
  store.dispatch(
    updateAuth({
      isConnected: false,
      token: null,
      userConnected: null,
    })
  );
};

/**
 * Fonction qui va intercepter les requêtes axios pour vérifier si le token est toujours valide
 *
 * - En cas de 401, on tente un refresh du token, et si ça ne fonctionne pas, on déconnecte l'utilisateur
 * - En cas de succès, on met à jour le token dans le store et on relance la requête
 */
export const useAxiosInterceptors = () => {
  const [interceptorTriggered, setInterceptorTriggered] = useState(false);

  useEffect(() => {
    let refreshTokenRequest = null;
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const message = error.response?.data?.message;
        const messageHydra = error.response?.data["hydra:description"];
        const status = error.response?.status;
        if (
          status === 401 &&
          (message === "Expired JWT Token" ||
            messageHydra ===
              "Full authentication is required to access this resource.")
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;

          if (!refreshTokenRequest) {
            refreshTokenRequest = axios.post(
              `${process.env.REACT_APP_BASE_URL_API}/token/refresh`,
              {},
              { withCredentials: true }
            );
          }

          return refreshTokenRequest
            .then((res) => {
              if (res.status === 200) {
                store.dispatch(
                  updateAuth({
                    token: res.data.token,
                  })
                );
                originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
                return axios(originalRequest);
              }
            })
            .catch((err) => {
              disconnectUser();
              return Promise.reject(err);
            })
            .finally(() => (refreshTokenRequest = null));
        }
        return Promise.reject(error);
      }
    );
    setInterceptorTriggered(true);
  }, []);

  return interceptorTriggered;
};

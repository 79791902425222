import { UPDATE_OFFERS } from "../Actions/offersActions.js";

const INITIAL_STATE = {
  motsClés: "",
  secteur: [],
  domaine: [],
  lieu: "",
  typeContrat: [],
  niveauEtudes: [],
  salaireMin: 0,
  salaireMax: 0,
  offersData: [],
  offersFiltered: [],
  isSearched: false,
  secteurData: [],
  nivEtudesData: [],
  typeContratData: [],
  domaineData: [],
  statusData: [],
  dataFromPagination: {
    data: [],
    loaded: false,
    page: 1,
    totalItems: 0,
  },
};

const filterOffersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_OFFERS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default filterOffersReducer;

import React, { useRef, useState } from "react";
import "./GestionDepotOffreActions.scss";
import PropTypes from "prop-types";
import { Menu } from "primereact/menu";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import { FaEllipsisH } from "react-icons/fa";
import { connect } from "react-redux";
import Modal from "Components/Modal/Modal";
import { InputTextarea } from "primereact/inputtextarea";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { updateOffers } from "Redux/Actions/offersActions";
import DepotOffre from "../../DepotOffre/DepotOffre";
import { fetchDelete, fetchPost } from "Services/api";
import { showError } from "Services/functions";

const GestionDepotOffreCard = (props) => {
  const menu = useRef(null);
  const [openRefusInterface, setOpenRefusInterface] = useState(false);
  const [openModifyInterface, setOpenModifyInterface] = useState(false);
  const [refus, setRefus] = useState("");
  const [errorRefus, setErrorRefus] = useState(false);
  const [openMotifRefusInterface, setOpenMotifRefusInterface] = useState(false);

  const itemsPublie = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];
  const itemsBrouillon = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];
  const itemsRefuse = [
    {
      label: "Renouveler la demande",
      command: () => {
        setOpenMotifRefusInterface(!openMotifRefusInterface);
      },
    },
  ];
  const itemsAttente = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Annuler",
      command: () => {
        deleteOffer();
      },
    },
  ];
  const itemsArchive = [
    {
      label: "Supprimer",
      command: () => {
        deleteOffer();
      },
    },
  ];

  const modifyStatus = async (path) => {
    let data = {};
    if (path === "refuse") data = { feedback: refus };
    const response = await fetchPost(`/offers/${props.id}/${path}`, data);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }
    let tempVar = props.offers.dataFromPagination;
    tempVar.data = tempVar.data.map((offer) => {
      if (offer.id === response.data.id) {
        return response.data;
      } else {
        return offer;
      }
    });
    props.handleUpdateOffers({ dataFromPagination: tempVar });
  };

  const deleteOffer = async () => {
    const response = await fetchDelete(`/offers/${props.id}`);
    if (response.error) {
      showError("Une erreur est survenue.");
      return;
    }
    let tempVar = props.offers.dataFromPagination;
    tempVar.data = tempVar.data.filter((offer) => offer.id !== props.id);
    props.handleUpdateOffers({ dataFromPagination: tempVar });
  };

  const Statut = () => {
    switch (props.statut.statusLabel) {
      case "Archivé":
        return itemsArchive;
      case "En Attente":
        return itemsAttente;
      case "Publié":
        return itemsPublie;
      case "Refusé":
        return itemsRefuse;
      case "Brouillon":
        return itemsBrouillon;
      default:
        break;
    }
  };
  return (
    <div className="groupe_menu_offers">
      <div className={`groupe_menu ${props.secondClass}`}>
        <Menu
          appendTo={document.getElementById("app")}
          model={Statut()}
          popup
          ref={menu}
          style={{ textAlign: "center" }}
        />
        <BtnRond btnAction={(event) => menu.current.toggle(event)}>
          <FaEllipsisH size={"1.5rem"} />
        </BtnRond>
      </div>
      {openRefusInterface && (
        <Modal
          visible={openRefusInterface}
          setVisible={setOpenRefusInterface}
          header={`Justifiez votre refus de l'offre`}
        >
          <div className="modal_motif_refus">
            <InputTextarea
              className="modal_refus"
              rows={6}
              placeholder="Justifiez votre refus"
              value={refus}
              onChange={(e) => setRefus(e.target.value)}
            />
            {errorRefus && (
              <span className="error_refus_length">
                Le motif de refus doit faire au moins 10 caractères
              </span>
            )}
            <BtnBleu
              btnTexte="Envoyer"
              btnAction={() => {
                if (refus.length > 9) {
                  setErrorRefus(false);
                  setRefus("");
                  setOpenRefusInterface(false);
                  modifyStatus("refuse");
                } else setErrorRefus(true);
              }}
            />
          </div>
        </Modal>
      )}
      {openMotifRefusInterface && (
        <Modal
          visible={openMotifRefusInterface}
          setVisible={setOpenMotifRefusInterface}
          header={`Motif du refus`}
        >
          <div className="motif_refus_interface">
            <div className="motif_refus_interface_message">
              <span className="motif_refus_interface_message_title">
                Motif du refus :
              </span>
              <span className="motif_refus_interface_message_text">
                {props.feedback
                  ? props.feedback
                  : " Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim maiores vero totam exercitationem at asperiores quibusdam eligendi labore fuga, porro tempora dolor nihil repellat error aut distinctio et earum molestiae accusamus rem."}
              </span>
            </div>
            <div className="motif_refus_interface_bouton">
              <BtnBleu
                btnTexte="Renouveler la demande"
                btnAction={() => {
                  setOpenModifyInterface(true);
                  setOpenMotifRefusInterface(false);
                }}
              ></BtnBleu>
            </div>
          </div>
        </Modal>
      )}
      {openModifyInterface && (
        <Modal
          visible={openModifyInterface}
          setVisible={setOpenModifyInterface}
          header={`Modifiez votre offre`}
        >
          <DepotOffre
            activeIndex={props.items.itemsOffre.findIndex(
              (v) => v.label == "Déposer une offre"
            )}
            paramItems={props.items.itemsOffre}
            offerId={props.offre?.id}
            setVisible={setOpenModifyInterface}
          ></DepotOffre>
        </Modal>
      )}
    </div>
  );
};

GestionDepotOffreCard.propTypes = {
  statut: PropTypes.object,
  id: PropTypes.number,
  offre: PropTypes.object,
  feedback: PropTypes.string,
  secondClass: PropTypes.string,

  auth: PropTypes.object,
  offers: PropTypes.object,
  items: PropTypes.object,

  handleUpdateOffers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionDepotOffreCard);

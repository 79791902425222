import fond from "assets/fond-accueil.webp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import NavBar from "..//NavBar/Navbar";
import "./Header.scss";
import HeaderTop from "./HeaderTop/headerTop";

const Header = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  return (
    <header id="header">
      <HeaderTop />
      <NavBar
        navbarOpen={navbarOpen}
        handleToggle={handleToggle}
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      />
      {!(props.noImage == true && props.auth.isConnected == true) && (
        <div
          className="menu"
          style={{
            backgroundImage: props.auth.isConnected
              ? `linear-gradient(lightgrey, grey), url(${fond})`
              : `linear-gradient(#9fc5e8, #000638), url(${fond})`,
            backgroundBlendMode: props.auth.isConnected
              ? "multiply"
              : "multiply",
          }}
        >
          {props.auth.isConnected ? (
            <div className="menu__connected">{props.children}</div>
          ) : (
            <div className="menu__notConnected">{props.children}</div>
          )}
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.any,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,

  noImage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { useEffect, useState } from "react";
import "./ModalCharte.scss";
import Modal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "Redux/Actions/authActions";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaExclamationTriangle } from "react-icons/fa";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { fetchPut, useFetchGet } from "Services/api";
import Loader from "Components/Loader/loader";
import { showError } from "Services/functions";
import charteFile from "assets/Charte_RéseauPro_commune.pdf";

const ModalCharte = () => {
  const charte = useFetchGet("/chartes");

  const [charteVisible, setCharteVisible] = useState(false);

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleAuth = (value) => {
    dispatch(updateAuth(value));
  };

  /**
   * Déconnexion de l'utilisateur
   * - Met à jour le state auth
   * - Supprime les cookies
   * @returns {void}
   */
  function logout() {
    handleAuth({
      ...authState,
      isConnected: false,
      token: false,
      userConnected: null,
    });
    // delete all cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  /**
   * Acceptation de la charte
   * - Requête PUT pour mettre à jour le champ charteSigned du recruteur
   * - Met à jour le state auth
   * - Fermeture du modal
   * @returns {Promise<void>}
   */
  const acceptCharter = async () => {
    const response = await fetchPut(
      `/recruiters/${authState.userConnected.id}`,
      {
        charteSigned: true,
      }
    );
    if (response.error) {
      showError("Une erreur s'est produite lors de la validation de la charte");
      return;
    }
    handleAuth({
      userConnected: response.data,
    });
    authState.toast.current.show({
      severity: "success",
      summary: "Charte validée",
      detail: "Vous avez validé la charte Réseau Pro",
      life: 5000,
    });
    setCharteVisible(false);
  };

  /**
   * Refus de la charte
   * - Déconnexion de l'utilisateur
   * - Fermeture du modal
   * @returns {void}
   */
  const declineCharter = () => {
    logout();
    setCharteVisible(false);
    authState.toast.current.show({
      severity: "error",
      summary: "Charte refusée",
      detail: "Vous avez refusé la charte Réseau Pro et avez été déconnecté",
      life: 5000,
    });
  };

  useEffect(() => {
    if (authState.userConnected && !authState.userConnected?.charteSigned)
      setCharteVisible(true);
    else setCharteVisible(false);
  }, [authState.userConnected]);

  return (
    <div>
      <Modal
        visible={charteVisible}
        setVisible={setCharteVisible}
        header="Charte Réseau Pro"
        width="60%"
        closable={false}
      >
        {charte.loaded ? (
          charte.data &&
          Array.isArray(charte.data) &&
          charte.data[0]?.content ? (
            <div className="charte__container">
              <div className="charte__container-title">
                <p>
                  <FaExclamationTriangle /> Si vous ne validez pas la charte
                  Réseau Pro, vous ne pourrez pas avoir accès au site.{" "}
                  <FaExclamationTriangle />
                </p>
                <br />
                <p className="charte__container-text">
                  <a
                    href={charte.data[0]?.filepath || charteFile}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lire la charte Réseau Pro
                  </a>
                </p>
              </div>
              <div className="modal_charte__boutons">
                <BtnBleu
                  btnTexte="Valider la charte"
                  btnAction={() => acceptCharter()}
                />
                <BtnBlanc
                  btnTexte="Refuser la charte"
                  btnAction={() => declineCharter()}
                />
              </div>
            </div>
          ) : (
            <p>Une erreur est survenue lors du chargement de la Charte</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
    </div>
  );
};

export default ModalCharte;

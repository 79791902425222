import React from "react";
import "./Error401.scss";
import logo_rp from "assets/logo_rp.webp";
import { Link } from "react-router-dom";

const Error401 = () => {
  return (
    <div className="error401">
      <img src={logo_rp} />
      <h1>
        Erreur 401 : Accès interdit, vous cherchez à accéder à une page sans
        avoir les bonnes autorisations. Retournez à l&apos;
        <Link to="/">accueil</Link>
      </h1>
    </div>
  );
};

export default Error401;

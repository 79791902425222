import React, { useState } from "react";
import "./CVPanier.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "Components/Modal/Modal";
import CV from "Components/CV/CV/CV";
import { FaTrash } from "react-icons/fa";
import Loader from "Components/Loader/loader";

const CVPanier = (props) => {
  const [visibleModalDetail, setVisibleModalDetail] = useState(false);
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];
  const university = props.universities?.universitiesData?.find(
    (univ) => univ.id == props.basketUniversity?.split("/")[3]
  );

  return (
    <div>
      <div className="CVPanier__container cardHover">
        <div className="CVPanier">
          <div
            className="CVPanier__image"
            onClick={() => setVisibleModalDetail(!visibleModalDetail)}
          >
            <img
              src={API_LINK + university.imageTransparentAndColorUrl}
              alt="Logo de l'université"
              title="Logo de l'université"
            />
          </div>
          <div className="CVPanier__content">
            <div
              className="CVPanier__content__title"
              onClick={() => setVisibleModalDetail(!visibleModalDetail)}
            >
              <div className="CVPanier__content__title__principal">
                {props.basketName}
              </div>
              <p className="nombre_cv">{props.nbCV} CV dans ce panier </p>
            </div>
            <div className="trash" onClick={() => props.onDelete()}>
              <div className="tooltip">
                <FaTrash />
              </div>
            </div>
          </div>
        </div>
        {visibleModalDetail && (
          <Modal
            visible={visibleModalDetail}
            setVisible={setVisibleModalDetail}
            header="Voici la liste des CV présents dans ce panier"
          >
            {props.basketItems ? (
              props.basketItems.length > 0 ? (
                props.basketItems.map((resume) => (
                  <div className="basket_resume_item" key={resume.id}>
                    <CV
                      firstname={resume.firstname}
                      surname={resume.surname}
                      titre={resume.profileDescription}
                      cvId={resume.user}
                      displayFromBasket={true}
                      resumeId={resume.resume}
                      university={university}
                    />
                  </div>
                ))
              ) : (
                <div className="description_modal">
                  <p className="description_modal__empty__basket">
                    Il n&apos;y a pas de CV dans ce panier
                  </p>
                </div>
              )
            ) : (
              <Loader></Loader>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

CVPanier.propTypes = {
  auth: PropTypes.object,
  universities: PropTypes.object,

  basketName: PropTypes.string,
  nbCV: PropTypes.number,
  basketUniversity: PropTypes.string,
  onDelete: PropTypes.func,
  basketItems: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  universities: state.universities,
});

export default connect(mapStateToProps)(CVPanier);

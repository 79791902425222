import Header from "Components/Header/Header";
import React, { useState, useEffect, useRef } from "react";
import "./EvenementDetail.scss";
import event1 from "assets/event1.webp";
import Footer from "Components/Footer/Footer";
import { useParams } from "react-router-dom";
import Loader from "Components/Loader/loader";
import {
  formatDateHourFrenchLocale,
  formatDate,
  formatHourFrenchLocale,
  showSuccess,
} from "Services/functions";
import { useFetchGet } from "Services/api";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvents } from "Redux/Actions/eventsActions";
import EventActuConseilRandom from "Components/EventActuConseilRandom/EventActuConseilRandom";
import ModalQuestion from "./ModalQuestion";
import parse from "html-react-parser";
import { HTMLParseOptions } from "Services/htmlParseOptions";

const EvenementDetail = (props) => {
  const paramRecruteur = new URLSearchParams(window.location.search).get("r");
  const isMine = paramRecruteur === "true";

  const refQuestions = useRef(null);
  // récupération des événements
  let { id } = useParams();
  const event = useFetchGet(`/events/${id}`, null, isMine ? "univ" : "nexus");

  const [eventDetail, setEventDetail] = useState();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  useEffect(() => {
    if (event.loaded) {
      setEventDetail(event.data);
    }
  }, [event.loaded]);

  // Corsp du mail
  let stringFromQuestions = "Réponses aux questions : \n \n";
  eventDetail?.questions?.forEach(
    (question, index) =>
      (stringFromQuestions += `Question ${index + 1} : ${question} \n \n`)
  );
  return (
    <div className="evenement_detail_container">
      <Header />
      <h1 className="evenement_detail_container__title h1_trait_dessus">
        Détail d&apos;un événement
      </h1>
      {eventDetail ? (
        <div className="evenement_detail_container__top">
          <div className="evenement_detail_container__top__body">
            <img
              className="evenement_detail_container__top__body__img"
              src={eventDetail.imageUrl ? eventDetail.imageUrl : event1}
              alt="Image de l'event"
              onError={(e) => (e.target.src = event1)}
            ></img>
            <div className="evenement_detail_container__top__body__texte">
              <h1>{eventDetail?.title}</h1>
              <h5 className="evenement_detail_container__top__body__texte__date">
                {eventDetail.endingAt ? (
                  <>
                    Du {formatDate(eventDetail.startingAt)}
                    {eventDetail.startingHour &&
                      " à " +
                        formatHourFrenchLocale(eventDetail.startingHour)}{" "}
                    au {formatDate(eventDetail.endingAt)}
                    {eventDetail.endingHour &&
                      " à " + formatHourFrenchLocale(eventDetail.endingHour)}
                  </>
                ) : (
                  <>Le {formatDateHourFrenchLocale(eventDetail.createdAt)}</>
                )}
              </h5>
              <h5 className="evenement_detail_container__top__body__texte__categorie">
                Catégorie : {eventDetail?.category?.label || "Non renseigné"}
              </h5>
              <h5 className="evenement_detail_container__top__body__texte__categorie">
                Lieu : {eventDetail?.location || "Non renseigné"}
              </h5>
              <div
                className="evenement_detail_container__top__body__texte__inscriptionStatus"
                style={{
                  color:
                    props.events?.eventParticipants?.length ==
                    eventDetail.maxNumberOfParticipants
                      ? "#e24c4c"
                      : "#5eb6a1",
                }}
              >
                {!isMine &&
                  (eventDetail.fulfilled
                    ? "Complet"
                    : `Inscriptions ouvertes ${
                        eventDetail.registerEnd != undefined
                          ? `jusqu'au ${formatDate(eventDetail.registerEnd)}`
                          : ""
                      }`)}
              </div>
              <p className="evenement_detail_container__top__body__texte__description">
                {eventDetail.description &&
                  parse(eventDetail.description, HTMLParseOptions)}
              </p>

              {eventDetail?.questions?.length > 0 && (
                <h5
                  className="evenement_detail_container__top__body__texte__anchor"
                  onClick={() => {
                    if (refQuestions.current !== null) {
                      window.scroll({
                        top: refQuestions.current.offsetTop,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {" "}
                  Répondre aux questions
                </h5>
              )}
              {modalIsVisible && (
                <ModalQuestion
                  modalIsVisible={modalIsVisible}
                  setModalIsVisible={setModalIsVisible}
                  idEvent={
                    props.events.eventParticipants.filter(
                      (participant) =>
                        participant.user.id === props.auth.userConnected.id
                    )[0]
                  }
                  showSuccess={() =>
                    showSuccess(
                      "Votre question a bien été posée",
                      "Question envoyée : "
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {eventDetail?.questions?.length > 0 && (
        <div
          className="evenement_detail_questions_container"
          ref={refQuestions}
        >
          <div className="evenement_detail_questions_container__body">
            <h1>Questions liées à l&apos;événement :</h1>
            {eventDetail.questions.map((question, index) => (
              <span key={index}>
                Question {index + 1} : {question}
              </span>
            ))}
            <h5>Mail de réponse :</h5>
            <a
              href={`mailto:${
                eventDetail.emailCreator
              }?body=${encodeURIComponent(stringFromQuestions)}`}
              title={`Envoyer un email à ${eventDetail.emailCreator}`}
            >
              {eventDetail.emailCreator}
            </a>
          </div>
        </div>
      )}
      <div className="evenement_detail_container__bottom">
        <div className="evenement_detail_container__bottom__title">
          <h1>Autres éléments que vous pouvez consulter</h1>
        </div>
        <EventActuConseilRandom event={eventDetail} />
        <div className="evenement_detail_container__bottom__event"></div>
      </div>
      <Footer />
    </div>
  );
};
EvenementDetail.propTypes = {
  handleUpdateEvents: PropTypes.func,
  events: PropTypes.object,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  events: state.events,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementDetail);

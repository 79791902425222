import React, { useState } from "react";
import "./InformationsPerso.scss";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import { retrieveCountries } from "assets/countries";
import { Checkbox } from "primereact/checkbox";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { updateAuth } from "Redux/Actions/authActions";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaChevronLeft } from "react-icons/fa";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import axios from "axios";
import ProfilBarShowApercu from "Components/ProfilBar/ProfilBarShow/ProfilBarShowAperçu/ProfilBarShowApercu";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const InformationsPerso = (props) => {
  const [formData, setFormData] = useState(props.auth.userConnected);
  const [modified, setModified] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [phototheque, setPhototheque] = useState(
    props.auth.userConnected.imageUrl ? false : true
  );
  const [imageFile, setImageFile] = useState("");
  const [image, setImage] = useState(
    props.auth.userConnected.imageUrl ? props.auth.userConnected.imageUrl : ""
  );
  // eslint-disable-next-line no-unused-vars
  const [imageId, setImageId] = useState("");

  let defaultValues = props.auth.userConnected;
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    // reset,
  } = useForm({ defaultValues });

  const [currentCountry, setCurrentCountry] = useState(getValues("country"));
  const [isVisibleAdress, setIsVisibleAdress] = useState(false);
  const [listOfAdress, setListOfAdress] = useState([]);

  const onSubmit = (data) => {
    fillImage(data);
    setFormData(data);
    setModified(false);

    // reset();
  };

  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const getAdress = (adress) => {
    let listOfAdresses = [];
    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${adress}&type=housenumber&autocomplete=1`
      )
      .then((res) => {
        res.data.features.forEach((adress) =>
          listOfAdresses.push([
            adress.properties.label,
            adress.properties.context,
            adress.properties.city,
          ])
        );
        setListOfAdress(listOfAdresses);
      });
  };

  return (
    <div className="edit_profil">
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            select="profil"
            activeIndex={props.activeIndex}
            items={props.items}
          />

          <div className="edit_profil__title">
            <h1 className="h1_trait_dessus">
              Modifier mes {props.items[props.activeIndex].label}
            </h1>
            {!modified && (
              <BtnBlanc btnAction={() => setModified(true)}>
                <FaChevronLeft /> Mon profil
              </BtnBlanc>
            )}
          </div>
          {modified ? (
            <div className="form-demo">
              <div className="card">
                <div className="field">
                  <div className="field__photoProfil">
                    <ChargementImage
                      label="Photo de profil"
                      image={image}
                      setImage={setImage}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      setImageId={setImageId}
                      phototheque={phototheque}
                      setPhototheque={setPhototheque}
                      path="users"
                      currentImage={props.auth.userConnected.imageUrl}
                    />
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="edit_profil__informationPersoForm p-fluid"
                >
                  {/* block titre */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Poste dans l&apos;entreprise *</h2>
                      <Controller
                        name="profilTitle"
                        control={control}
                        rules={{
                          required: "Le poste dans l'entreprise est requis",
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Chargé(e) de recrutement"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      {getFormErrorMessage("profilTitle")}
                    </div>
                  </div>
                  {/* block nom / prénom */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Nom *</h2>
                      <Controller
                        name="surname"
                        control={control}
                        rules={{ required: "Le nom est requis" }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            disabled={true}
                            placeholder="Dupont..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      {getFormErrorMessage("surname")}
                    </div>
                    <div className="field">
                      <h2>Prénom *</h2>
                      <Controller
                        name="firstname"
                        control={control}
                        rules={{ required: "Le prénom est requis" }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Marie, Arthur..."
                            {...field}
                            disabled={true}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("firstname")}
                    </div>
                  </div>
                  {/* block nom d'usage / prénom d'usage */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Nom d&apos;usage</h2>
                      <Controller
                        name="useSurname"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Si différent du nom"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("useSurname")}
                    </div>
                    <div className="field">
                      <h2>Prénom d&apos;usage</h2>
                      <Controller
                        name="useFirstname"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Si différent du prénom"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />

                      {getFormErrorMessage("useFirstname")}
                    </div>
                  </div>
                  {/* block description */}
                  <div className="field">
                    <h2>Description</h2>
                    <Controller
                      name="profilDescription"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          placeholder="Je suis recruteur pour l'entité..."
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          rows={5}
                        />
                      )}
                    />
                    {getFormErrorMessage("profilDescription")}
                  </div>
                  {/* block mail / téléphone */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Adresse mail</h2>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "L'adresse mail est requise",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message:
                              "Adresse mail invalide. Ex. example@email.com",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            disabled={true}
                            id={field.name}
                            placeholder="hello123@gmail.com"
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("email")}
                      <div className="field checkbox">
                        <Controller
                          name="mailIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="mailIsPublic">
                          Rendre privée mon adresse mail
                        </label>
                        {getFormErrorMessage("mailIsPublic")}
                      </div>
                    </div>

                    <div className="field">
                      <h2>Numéro de téléphone</h2>
                      <PhoneInputWithCountry
                        name="telephone"
                        control={control}
                        inputComponent={InputText}
                        defaultValue={"+33"}
                        rules={{
                          required: "Le numéro de téléphone est requis.",
                          // On utilise isPossiblePhoneNumber pour vérifier si le numéro est bien formé
                          validate: (value) =>
                            isPossiblePhoneNumber(value)
                              ? true
                              : "Le numéro de téléphone est invalide",
                          pattern: {
                            // Valeur doit commencer par + ou être au format FR
                            value: /^(\+?[1-9]\d{1,14}|0\d{9})$/,
                            message: "Le numéro de téléphone est invalide",
                          },
                        }}
                        placeholder={"+33 9 99 99 99 99"}
                        defaultCountry="FR"
                      />
                      {getFormErrorMessage("telephone")}
                      <div className="field checkbox">
                        <Controller
                          name="telephoneIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="telephoneIsPublic">
                          Rendre privé mon numéro de téléphone
                        </label>
                        {getFormErrorMessage("telephoneIsPublic")}
                      </div>
                    </div>
                  </div>
                  {/* block pays */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Pays</h2>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            placeholder="France, Belgique..."
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                              setCurrentCountry(e.value);
                              setIsVisibleAdress(false);
                            }}
                            options={retrieveCountries()}
                          />
                        )}
                      />
                      {getFormErrorMessage("country")}
                    </div>
                  </div>
                  {/* block ville / adresse */}
                  <div className="form_columns">
                    <div className="field ville">
                      <h2>Adresse postale</h2>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="12 boulevard des coquelicots..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (currentCountry === "France") {
                                if (e.target.value.length > 2) {
                                  setIsVisibleAdress(true);
                                  getAdress(e.target.value);
                                } else {
                                  setIsVisibleAdress(false);
                                }
                              }
                            }}
                          />
                        )}
                      />
                      {isVisibleAdress && (
                        <div className="listOfAdresses">
                          {listOfAdress.map((adress, key) => (
                            <div
                              className="searchedAdress"
                              key={key}
                              onClick={() => {
                                setValue("address", adress[0]);
                                setValue("city", adress[2]);
                                setIsVisibleAdress(false);
                              }}
                            >
                              <div className="adress">{adress[0]}</div>
                              <div className="department">{adress[1]}</div>
                            </div>
                          ))}
                        </div>
                      )}

                      {getFormErrorMessage("address")}
                      <div className="field checkbox">
                        <Controller
                          name="addressIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />

                        <label htmlFor="addressIsPublic">
                          Rendre privée mon adresse postale
                        </label>

                        {getFormErrorMessage("addressIsPublic")}
                      </div>
                    </div>
                    <div className="field ville">
                      <h2>Ville</h2>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="Paris, Metz..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("city")}

                      <div className="field checkbox">
                        <Controller
                          name="cityAndCountryIsPublic"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.checked)}
                            />
                          )}
                        />
                        <label htmlFor="cityAndCountryIsPublic">
                          Rendre privés ma ville et mon pays
                        </label>
                        {getFormErrorMessage("cityAndCountryIsPublic")}
                      </div>
                    </div>
                  </div>
                  {/* block entrepreneur */}
                  <div className="field checkbox companyCreator">
                    <h3>Si vous êtes créateur d&apos;entreprise</h3>
                    <div className="companyCreator__child">
                      <Controller
                        name="companyCreator"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            id={field.name}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.checked)}
                          />
                        )}
                      />
                      <label htmlFor="companyCreator">
                        Créateur d&apos;entreprise
                      </label>
                    </div>
                    {getFormErrorMessage("companyCreator")}
                  </div>
                  <br />
                  {/* block réseaux sociaux */}
                  <div className="form_columns">
                    <div className="field">
                      <h2>Compte LinkedIn</h2>
                      <Controller
                        name="linkedinAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.linkedin.com/in/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("linkedinAccount")}
                    </div>
                    <div className="field">
                      <h2>Compte Facebook</h2>
                      <Controller
                        name="facebookAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.facebook.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("facebookAccount")}
                    </div>
                  </div>
                  <div className="form_columns">
                    <div className="field">
                      <h2>Compte Instagram</h2>
                      <Controller
                        name="instagramAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            ref={register}
                            id={field.name}
                            placeholder="www.instagram.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("instagramAccount")}
                    </div>
                    <div className="field">
                      <h2>Compte Twitter</h2>
                      <Controller
                        name="twitterAccount"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            placeholder="www.twitter.com/..."
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                          />
                        )}
                      />
                      {getFormErrorMessage("twitterAccount")}
                    </div>
                  </div>
                  <div className="form_boutons btnInfoPersoValider">
                    <input
                      type="submit"
                      value="Modifier"
                      className="btn-bleu"
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <ProfilBarShowApercu user={formData} imageFile={imageFile} />
          )}
        </div>
      )}
    </div>
  );
};

InformationsPerso.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  setChecked: PropTypes.func,
  checked: PropTypes.bool,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationsPerso);

import TempPagination from "Components/Pagination/TempPagination";
import { useFetchGetPagination } from "Services/api";
import useWindowDimensions from "Services/useWindowsDimensions";
import { Accordion, AccordionTab } from "primereact/accordion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CV from "../CV/CV";
import CVSearchBar from "../CVSearchBar/CVSearchBar";
import "./CVContainer.scss";

const CVContainer = (props) => {
  const [url, setUrl] = useState(null);
  const cvRef = useRef(null);
  const [messageCV, setMessageCV] = useState(
    "Aucun profil ne correspond à votre recherche"
  );

  const winSize = useWindowDimensions();

  const isMobile = winSize.width < 768;

  const cvsData = useFetchGetPagination(url);

  useEffect(() => {
    if (cvsData.totalItems && cvsData.totalItems > 0) {
      setMessageCV(
        cvsData.totalItems === 1
          ? cvsData.totalItems + " profil correspond à votre recherche"
          : cvsData.totalItems + " profils correspondent à votre recherche"
      );
    }
  }, [cvsData.totalItems]);

  return (
    <div>
      <div className="CVs__container">
        <div className="annuaire_container">
          <div className="CVs__container__title">
            <div>
              <h1 className="h1_trait_dessus">
                {props.items[props.activeIndex].label}
              </h1>
            </div>
            <div>
              <p>{messageCV}</p>
            </div>
          </div>
        </div>
        {!isMobile ? (
          <div className="offre_search_bar__filtres">
            <CVSearchBar url={url} setUrl={setUrl} />
          </div>
        ) : (
          <Accordion
            expandIcon="pi pi-sliders-h"
            collapseIcon="pi pi-sliders-h"
          >
            <AccordionTab header="Fitres de recherche">
              <CVSearchBar urk={url} setUrl={setUrl} />
            </AccordionTab>
          </Accordion>
        )}
        <div className="CVs__container__cvs" ref={cvRef}>
          {!cvsData.loaded ? (
            <span>
              Pour commencer votre recherche veuillez sélectionner une
              université
            </span>
          ) : cvsData.data && cvsData.totalItems > 0 ? (
            cvsData.data.map((cv) => (
              <CV
                key={cv.id}
                firstname={cv.firstname}
                document={cv.document || cv.cvReseauPro || null}
                surname={cv.surname}
                titre={cv.profileTitle}
                cvId={cv.userId}
                //educations={cv.educations}
                // roles={cv.user.userRoles}
                resumeId={cv.resumeId}
                imageUrl={cv.imageUrl ? cv.imageUrl : null}
                certified={cv.atLeastOneCertified}
                university={cv.univId}
              />
            ))
          ) : (
            <span>Aucun CV ne correspond à votre recherche</span>
          )}
        </div>
        <TempPagination result={cvsData} refItemParent={cvRef} rows={50} />
      </div>
    </div>
  );
};

CVContainer.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  pagination: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CVContainer);

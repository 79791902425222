import { combineReducers } from "redux";
import authReducer from "./Reducers/authReducer";
import menuReducer from "./Reducers/menuReducer";
import offersReducer from "./Reducers/offersReducer";
import usersReducer from "./Reducers/usersReducer";
import newsReducer from "./Reducers/newsReducer";
import eventsReducer from "./Reducers/eventsReducer";
import paginationReducer from "./Reducers/paginationReducer";
import statesReducer from "./Reducers/statesReducer";
import secondaryTablesReducer from "./Reducers/secondaryTablesReducer";
import universitiesReducer from "./Reducers/universitiesReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  items: menuReducer,
  offers: offersReducer,
  users: usersReducer,
  news: newsReducer,
  events: eventsReducer,
  pagination: paginationReducer,
  states: statesReducer,
  secondaryTables: secondaryTablesReducer,
  universities: universitiesReducer,
});

export default rootReducer;

import React from "react";
import PropTypes from "prop-types";
import "./headerTop.scss";
import HeaderUserConnected from "./HeaderUserConnected/HeaderUserConnected";
import HeaderUserDisconnected from "./HeaderUserDisconnected/headerUserDisconnected";
import logo_rp from "assets/logo_rp.webp";
import logo_unif from "assets/logo_unif.webp";
import { connect } from "react-redux";

// eslint-disable-next-line no-unused-vars
const HeaderTop = (props) => {
  return (
    <div className="header__bandeau">
      <div className="header__bandeau__logos">
        <img
          src={logo_rp}
          alt="Logo Réseau Pro"
          title="Logo Réseau Pro"
          className="logo_top"
        ></img>
        <img
          src={logo_unif}
          className="logo_unif"
          alt="Logo Université Numérique Ile de France"
          title="Logo Université Numérique Ile de France"
        ></img>
      </div>
      <div className="header__bandeau__title">
        {/* <center> */}
        <p>
          Bienvenue sur Réseau Pro, la plateforme professionnelle des
          universités
        </p>
        {/* </center> */}
      </div>
      {props.auth.isConnected ? (
        <HeaderUserConnected />
      ) : (
        <HeaderUserDisconnected />
      )}
    </div>
  );
};
HeaderTop.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);

import React, { useEffect, useState } from "react";
import "./EvenementsCreation.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { addLocale, locale } from "primereact/api";
import { getFrenchLocale } from "Services/Const";
import { Checkbox } from "primereact/checkbox";
import {
  formatDateDatabase,
  isJsonParsable,
  showError,
  showSuccess,
} from "Services/functions";
import { fetchGet, fetchPost, useFetchGet } from "Services/api";
import { Dropdown } from "primereact/dropdown";
import Loader from "Components/Loader/loader";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import { DateTime } from "luxon";
import { FaWheelchair } from "react-icons/fa";
import Brouillon from "Components/Brouillons/Brouillon";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Modal from "Components/Modal/Modal";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { Link } from "react-router-dom";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { updateMenu } from "Redux/Actions/menuActions";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { updateEvents } from "Redux/Actions/eventsActions";
import UniversitiesPanel from "Components/UniversitiesPanel/UniversitiesPanel";

registerLocale("fr", fr);

const EvenementsCreation = (props) => {
  // Utilisation du locale fr pour les components date + heures primeReact
  addLocale("fr", getFrenchLocale().fr);
  locale("fr");

  const [submitted, setSubmitted] = useState(false);
  const [listUniversities, setListUniversities] = useState([]);

  const [isPublic, setPublic] = useState(false);

  const [categories, setCategories] = useState([]);

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");
  // Id de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  const [waitingLoader, setWaitingLoader] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  // Constantes comprenant les données du formulaire de création d'un événement pour les admins et personnel
  let defaultValues = {
    title: "",
    category: null,
    startingAt: "",
    endingAt: "",
    register: false,
    registerBegin: "",
    registerEnd: "",
    startingHour: "",
    endingHour: "",
    description: "",
    location: "",
    maxNumberOfParticipants: "",
    image: "",
    link: "",
    accessibleForDisabled: false,
    emailCreator: "",
    imageUrl: "",
  };

  // Méthodes et variables de react-hook-form
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({ defaultValues });

  const categoriesData = useFetchGet("/event_categories");

  const [isModifying, setIsModifying] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(async () => {
    if (!categoriesData.loaded || categoriesData?.data?.length === 0) return;
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    let tempArray = [];
    categoriesData.data.forEach((category) => tempArray.push(category));
    tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
    setCategories(tempArray);

    if (props.value) {
      // Une fois les catégories chargées, on les stocke dans un tableau :
      setCurrentCategory(
        categoriesData.data !== null &&
          categoriesData.data.find(
            (category) => category.label === props.value.category.label
          )
      );

      setIsModifying(true);
      const eventsItem = await fetchGet(`/events/${props.value.id}`);
      if (eventsItem.error) return;
      setCurrentEvent(eventsItem.data);
      fillFormFromDraft(eventsItem.data);
      setPublic(eventsItem.data.public);
    }
    setIsLoader(false);
  }, [categoriesData.loaded]);

  useEffect(() => {
    props.value && setPhototheque(false);
  }, []);

  const sendRequest = async (data) => {
    // Variable qui stockera la réponse du serveur
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) {
      if (key === "concernedUniversities") {
        dataForm.append(key, JSON.stringify(data[key]));
      } else {
        dataForm.append(key, data[key]);
      }
    }

    const response = await fetchPost("/events/request/validation", dataForm);
    setWaitingLoader(false);
    if (response.error) {
      response.error?.statusText === "Unprocessable Entity" &&
        showError("L'image doit être au format paysage");
      return;
    }
    reset();
    setSubmitted(true);
  };

  const putForm = async (data) => {
    // URL de la requête POST
    let url = null;

    if (currentEvent.state.statusLabel === "Brouillon") {
      url = `/events/${currentEvent.id}/edit/draft`;
    } else if (currentEvent.state.statusLabel === "Refusé") {
      url = `/events/${currentEvent.id}/request/validation`;
    } else {
      url = `/events/${currentEvent.id}/edit/publish`;
    }
    // On crée un FormData qui sera envoyé au serveur
    let formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) formData.append(key, data[key]);
    // On envoie le formData au serveur
    const response = await fetchPost(url, formData);
    setWaitingLoader(false);
    if (response.error) {
      showError(
        "Une erreur s'est produite lors de la modification de l'événement"
      );
      return;
    }
    if (props.events.dataFromPagination?.data) {
      let tempArray = props.events.dataFromPagination;
      tempArray.data = tempArray.data.map((event) => {
        if (event.id === response.data.id) return response.data;
        else return event;
      });
      props.handleUpdateEvents({ dataFromPagination: tempArray });
    } else {
      props.handleUpdateEvents({
        dataFromPagination: {
          data: [response.data],
          loaded: true,
          page: 1,
          setPage: null,
          totalItems: 1,
        },
      });
    }
    showSuccess("L'événement a bien été modifié");
    props.setVisible(false);
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage des keys concernant les dates selon différentes conditions
   * Formattage avec {@link formatDateDatabase}
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillDates = (data) => {
    // Assignation des dates de création, de début et de fin de l'évènement
    data.startingAt = data.startingAt
      ? formatDateDatabase(data.startingAt)
      : null;
    data.endingAt = data.endingAt ? formatDateDatabase(data.endingAt) : null;
    // Assignation des dates de début et de fin de l'évènement si remplies dans le formulaire
    if (data.startingHour != "" && data.startingHour != null)
      data.startingHour = data.startingHour.toLocaleTimeString(
        navigator.language,
        {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
    if (data.endingHour != "" && data.endingHour != null)
      data.endingHour = data.endingHour.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    // Gestion de l'inscription obligatoire ou non à l'évènement
    data.register = false;
    return data;
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.category) data.category = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.category.id)
      data.category = "/api/event_categories/" + data.category.id;
    /* 
    Sinon, on cherche si la catégorie existe déjà :
    // Si oui : on met l'id de la catégorie
    Si non : on spécifie qu'elle doit être créée
     */ else {
      const category = categories.find(
        (category) => category.label === data.category
      );
      if (category) data.category = "/api/event_categories/" + category.id;
      else data.category = JSON.stringify({ label: data.category });
    }
    return data;
  };

  const cleanEmptyKeys = (data) => {
    // Suppression des keys qui ne sont pas remplies
    for (var key in data) if (data[key] === "" || !data[key]) delete data[key];
    return data;
  };

  const setFormData = (data, formType = "multiform") => {
    let createdByUser = "/api/recruiters/" + props.auth.userConnected.id;
    data.description = DOMPurify.sanitize(data.description);
    data.createdById = createdByUser;
    data.inscription = false;
    data = fillCategory(data);
    data = fillDates(data);
    data = fillImage(data);
    data.public = isPublic;
    data.createdBy = createdByUser;
    data.forAllUniversities = true;
    if (isNaN(data.maxNumberOfParticipants))
      delete data.maxNumberOfParticipants;
    if (data.url == "" || data.url === undefined) delete data.url;
    if (formType === "json") {
      data.category = isJsonParsable(data.category)
        ? JSON.parse(data.category)
        : data.category;
    }
    data.concernedUniversities = listUniversities.map((university) => {
      return `/api/universities/${university.id}`;
    });
    data = cleanEmptyKeys(data);
    return data;
  };

  const onSubmit = (data) => {
    setWaitingLoader(true);
    data = setFormData(data);
    if (isModifying) {
      putForm(data);
    } else {
      sendRequest(data);
    }
  };

  const fillFormFromDraft = (draft) => {
    setPublic(draft.public);
    reset({
      category: draft.category.label,
      title: draft.title,
      startingAt: draft.startingAt
        ? DateTime.fromISO(draft.startingAt).toJSDate()
        : null,
      startingHour: draft.startingHour
        ? DateTime.fromISO(draft.startingHour).toJSDate()
        : null,
      endingHour: draft.endingHour
        ? DateTime.fromISO(draft.endingHour).toJSDate()
        : null,
      endingAt: draft.endingAt
        ? DateTime.fromISO(draft.endingAt).toJSDate()
        : null,
      registerBegin: draft.registerBegin
        ? DateTime.fromISO(draft.registerBegin).toJSDate()
        : null,
      registerEnd: draft.registerEnd
        ? DateTime.fromISO(draft.registerEnd).toJSDate()
        : null,
      description: draft.description,
      url: draft.url,
      link: draft.link,
      maxNumberOfParticipants: draft.maxNumberOfParticipants,
      location: draft.location,
      accessibleForDisabled: draft.accessibleForDisabled,
      emailCreator: draft.emailCreator,
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      {isLoader ? (
        <Loader></Loader>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="evenement_formulaire">
            <div className="evenement_formulaire__title">
              <h1 className="h1_trait_dessus">
                {isModifying ? "Modifier l'événement" : "Proposer un événement"}
              </h1>
              <Brouillon
                setFields={setFormData}
                fillFormFromDraft={fillFormFromDraft}
                path="/events"
                create="/create"
                formValues={getValues}
                className="btn-bleu"
              />
            </div>
            <div className="evenement_formulaire__container">
              <div className="column_line">
                <div className="column_line__item">
                  <h2
                    htmlFor="title"
                    className={classNames({ "p-error": errors.title })}
                  >
                    Titre de l&apos;événement *
                  </h2>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "Le titre est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="Forum de l'emploi, job dating de rentrée"
                        autoFocus
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_actu")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("title")}
                </div>
                <div className="column_line__item">
                  <h2
                    htmlFor="category"
                    className={classNames({ "p-error": errors.category })}
                  >
                    Catégorie de l&apos;événement
                  </h2>
                  {categories.length > 0 ? (
                    <Controller
                      name="category"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          editable
                          // showClear
                          value={
                            isModifying ? currentCategory : getValues().category
                          }
                          onChange={(e) => {
                            setCurrentCategory(e.target.value);
                            field.onChange(e.value);
                          }}
                          optionLabel="label"
                          options={categories}
                          placeholder="After-work, forum, job dating"
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_evenement")
                          }
                        />
                      )}
                    />
                  ) : (
                    <Loader />
                  )}

                  {getFormErrorMessage("category")}
                </div>
              </div>

              <div className="column_line">
                <div className="column_line__item">
                  <h2 className={classNames({ "p-error": errors.startingAt })}>
                    Dates de l&apos;événement *
                  </h2>
                  <div className="column_line">
                    <div className="column_line__item-date">
                      <label>Date de début</label>
                      <Controller
                        name="startingAt"
                        control={control}
                        rules={{
                          required: "La date de début est requise",
                          validate: () =>
                            DateTime.fromJSDate(getValues().startingAt) <=
                            DateTime.fromJSDate(getValues().endingAt)
                              ? true
                              : "La date de début doit être avant la date de fin",
                        }}
                        render={({ field, fieldState }) => (
                          <ReactDatePicker
                            autoComplete="off"
                            {...field}
                            id={field.name}
                            selected={getValues().startingAt}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "small_item p-inputtext p-component")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("startingAt")}
                    </div>
                    <div className="column_line__item-date">
                      <label>Date de fin</label>
                      <Controller
                        name="endingAt"
                        control={control}
                        rules={{
                          required: "La date de fin est requise",
                        }}
                        render={({ field, fieldState }) => (
                          <ReactDatePicker
                            autoComplete="off"
                            {...field}
                            selected={getValues().endingAt}
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => field.onChange(e)}
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "small_item p-inputtext p-component")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("endingAt")}
                    </div>
                  </div>
                </div>
                <div className="column_line__item">
                  <h2> Horaires de l&apos;événement</h2>
                  <div className="column_line">
                    <div className="column_line__item-date">
                      <label>Heure de début</label>
                      <Controller
                        name="startingHour"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            {...field}
                            format="HH:mm"
                            showTime
                            timeOnly
                            showButtonBar
                            stepMinute={15}
                            hourFormat="24"
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "input_evenement")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("startingHour")}
                    </div>
                    <div className="column_line__item-date">
                      <label>Heure de fin</label>
                      <Controller
                        name="endingHour"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            {...field}
                            format="HH:mm"
                            showTime
                            stepMinute={15}
                            showButtonBar
                            timeOnly
                            hourFormat="24"
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "input_evenement")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("endingHour")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="column_line">
                <div className="column_line__item">
                  <h2
                    htmlFor="location"
                    className={classNames({ "p-error": errors.location })}
                  >
                    Lieu de l&apos;événement *
                  </h2>
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "Le lieu est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="Jardin du Luxembourg, Visio"
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_evenement")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("location")}
                </div>
                <div className="column_line__item">
                  <h2
                    htmlFor="maxNumberOfParticipants"
                    className={classNames({
                      "p-error": errors.maxNumberOfParticipants,
                    })}
                  >
                    Nombre maximum de participants
                  </h2>
                  <Controller
                    name="maxNumberOfParticipants"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Veuillez entrer un nombre",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="50"
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_evenement",
                          "eventNumberInput")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("maxNumberOfParticipants")}
                </div>
              </div>

              <div className="fourth_line">
                <div className="descritpion_evenement">
                  <h2
                    htmlFor="description"
                    className={classNames({ "p-error": errors.description })}
                  >
                    Description de l&apos;événement *
                  </h2>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: "La description est requise",
                      minLength: {
                        value: 12,
                        message:
                          "La description doit faire au moins 12 caractères",
                      },
                    }}
                    render={({ field }) => (
                      <ReactQuill
                        id={field.name}
                        {...field}
                        theme="snow"
                        modules={QuillOptions.modules}
                        formats={QuillOptions.formats}
                        rows={5}
                      />
                    )}
                  />
                  {getFormErrorMessage("description")}
                </div>
              </div>

              <div className="column_line">
                <div className="column_line__item">
                  <h2> Ajouter un lien URL</h2>
                  <Controller
                    name="link"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputText
                        type="url"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Entrez une url de type https://myurl.com (ou http)"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        placeholder="https://"
                        id={field.name}
                        {...field}
                        value={field.value}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_evenement")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("link")}
                </div>
              </div>
              <ChargementImage
                image={image}
                setImage={setImage}
                imageFile={imageFile}
                setImageFile={setImageFile}
                setImageId={setImageId}
                phototheque={phototheque}
                setPhototheque={setPhototheque}
                path="events"
                currentImage={props.value ? getValues("imageUrl") : null}
              />
              <div className="checkbox_line">
                <div className="checkbox_line__item">
                  <Checkbox
                    checked={!isPublic}
                    onChange={(e) => {
                      setPublic(!e.checked);
                    }}
                  />
                  <label className="label_publique">
                    <i className="pi pi-lock spaceCheckbox" /> Événement privé
                  </label>
                </div>
                <div className="checkbox_line__item">
                  <Checkbox
                    checked={isPublic}
                    onChange={(e) => {
                      setPublic(e.checked);
                    }}
                  />
                  <label className="label_publique">
                    <i className="pi pi-lock-open spaceCheckbox" /> Événement
                    public
                  </label>
                </div>
              </div>
              <div className="checkbox_line">
                <div className="checkbox_line__item">
                  <Controller
                    name="accessibleForDisabled"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        inputId={field.name}
                        onChange={(e) => field.onChange(e.checked)}
                        checked={field.value}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="accessibleForDisabled"
                    className={classNames({
                      "p-error": errors.accessibleForDisabled,
                    })}
                  >
                    <FaWheelchair className="spaceCheckbox" />
                    Événement handi-accueillant
                  </label>
                </div>
              </div>

              <div className="column_line">
                <div className="column_line__item event_universities">
                  <h2
                    htmlFor="concernedUniversities"
                    className={classNames({
                      "p-error": errors.concernedUniversities,
                    })}
                  >
                    Vers quel(s) établissement(s) souhaitez-vous diffuser
                    l&apos;événement ? *
                  </h2>
                  <Controller
                    name="concernedUniversities"
                    control={control}
                    rules={{
                      validate: () =>
                        listUniversities.length > 0 ||
                        "Veuillez sélectionner au moins une université",
                    }}
                    render={() => (
                      <UniversitiesPanel
                        listUniversities={listUniversities}
                        setListUniversities={setListUniversities}
                        editUnivId={currentEvent?.university?.id || null}
                      ></UniversitiesPanel>
                    )}
                  />
                  {getFormErrorMessage("concernedUniversities")}
                </div>{" "}
              </div>
            </div>

            <div className="evenement_formulaire__container">
              <div className="buttons_bottom">
                {submitted && (
                  <Modal
                    visible={submitted}
                    setVisible={setSubmitted}
                    header={"Votre événement a bien été soumis"}
                  >
                    <i className="pi pi-send"></i>
                    <div className="description">
                      <p>
                        <center>
                          {
                            "Votre événement a bien été enregistré. Il sera publié après validation"
                          }
                        </center>
                      </p>
                    </div>
                    <div className="modals-buttons">
                      <BtnBleu
                        btnTexte="Déposer un nouvel évènement"
                        btnAction={() => window.location.reload()}
                      />
                      <Link to="/evenements">
                        <div>
                          <BtnBlanc
                            btnTexte="Voir tous mes évènements"
                            btnAction={() => {
                              props.handleUpdateMenu({
                                activeItemEvenement:
                                  props.items.itemsEvenement.findIndex(
                                    (v) => v.label == "Gérer mes événements"
                                  ),
                              });
                            }}
                          />
                        </div>
                      </Link>
                    </div>
                  </Modal>
                )}
                {!waitingLoader ? (
                  isModifying ? (
                    <button className="btn-bleu publier_button" type="submit">
                      {currentEvent?.state?.statusLabel === "Refusé"
                        ? "Renouveler la demande"
                        : "Modifier l'événement"}
                    </button>
                  ) : (
                    <button className="btn-bleu publier_button" type="submit">
                      {"Soumettre l'événement"}
                    </button>
                  )
                ) : (
                  <button className="waiting_button btn-bleu">
                    <Loader></Loader>
                  </button>
                )}
                <Brouillon
                  setFields={setFormData}
                  fillFormFromDraft={fillFormFromDraft}
                  path="/events"
                  create="/create"
                  formValues={getValues}
                  className="btn-blanc"
                  btnText={"Enregistrer le brouillon"}
                />

                <button
                  className="btn-blanc annuler_button"
                  onClick={() => {
                    isModifying
                      ? props.setVisible(false)
                      : (reset(
                          {
                            categorie: null,
                            title: "",
                            description: "",
                            startingAt: "",
                            endingAt: "",
                            registerBegin: "",
                            registerEnd: "",
                            link: "",
                            public: false,
                            accessibleForDisabled: false,
                            location: "",
                            register: false,
                          },
                          {
                            keepErrors: true,
                            keepDirty: true,
                            keepIsSubmitted: false,
                            keepTouched: false,
                            keepIsValid: false,
                            keepSubmitCount: false,
                          }
                        ),
                        setImage(null),
                        setImageFile(null),
                        setPhototheque(true));
                  }}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

EvenementsCreation.propTypes = {
  activeIndex: PropTypes.number,
  nbevents: PropTypes.number,
  pageevents: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  boutons: PropTypes.bool,
  auth: PropTypes.object,
  value: PropTypes.object,
  setVisible: PropTypes.func,
  handleUpdateMenu: PropTypes.func,
  items: PropTypes.object,
  events: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementsCreation);

import React, { useEffect, useRef } from "react";
import "./GestionDepotOffre.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GestionDepotOffreCard from "./GestionDepotOffreCard/GestionDepotOffreCard";
import { useState } from "react";
import { displayCompanyImage, stripHtml, truncate } from "Services/functions";
import Management from "Components/Management/Management";
import Loader from "Components/Loader/loader";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";

const GestionDepotOffre = (props) => {
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const [url, setUrl] = useState(null);
  useFetchGetPagination(url, props.handleUpdateOffers);
  const depotOffreRef = useRef(null);
  const [states, setStates] = useState(null);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************
  useEffect(() => {
    return () => {
      props.handleUpdateOffers({ dataFromPagination: null });
    };
  }, []);

  // UseEffect de chargement
  useEffect(() => {
    /// Récupérer les states, et les filtrer par ordre alphabétique
    if (props.offers.statusData && props.offers.statusData.length > 0) {
      let tempArray = props.offers.statusData.map((state) => {
        return { ...state };
      });
      tempArray = tempArray.sort((a, b) => a.name.localeCompare(b.name));
      tempArray.forEach((state) => {
        if (state.name.lastIndexOf("é") === state.name.length - 1) {
          state.name = state.name + "e";
        }
      });

      setStates(tempArray);
    }
  }, [props.offers.statusData]);

  return (
    <div className="gestion_depot_offre" ref={depotOffreRef}>
      <div className="gestion_depot_offre__title">
        <h1 className="h1_trait_dessus">{"Gérer mes dépôts d'offres"}</h1>
        <Management
          states={states}
          actualPage={"offre"}
          updateFunction={setUrl}
        ></Management>
      </div>

      <div className="gestion_depot_offre__container">
        <div className="gestion_depot_offre__container__right">
          {props.offers.dataFromPagination?.loaded > 0 ? (
            props.offers.dataFromPagination.data?.length > 0 ? (
              props.offers.dataFromPagination.data
                .filter(
                  (offre) =>
                    offre.state.statusLabel !== "Brouillon" ||
                    offre.createdByUser.id === props.auth.userConnected.id
                )
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                .map((offre, index) => (
                  <GestionDepotOffreCard
                    key={index}
                    titre={offre.title}
                    entreprise={
                      offre.companyName ? offre.companyName : "Non renseigné"
                    }
                    companyUrl={displayCompanyImage(offre?.company) || null}
                    contrat={
                      offre.typeOfContract
                        ? offre.typeOfContract.label
                        : offre.offerCategory?.label
                    }
                    lieu={offre.city ? offre.city : "Non renseigné"}
                    statut={offre.state}
                    image={offre.company?.url}
                    niveau={
                      offre.levelOfEducations[0] !== undefined &&
                      offre.levelOfEducations[0]
                        ? offre.levelOfEducations[0].label
                        : "Non renseigné"
                    }
                    description={truncate(stripHtml(offre?.description), 80)}
                    id={offre.id}
                    offre={offre}
                    feedback={offre.feedback?.feedback}
                    universityName={offre?.university?.name}
                  />
                ))
            ) : (
              <span>Aucune offre ne correspond à votre recherche</span>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <TempPagination
        result={props.offers.dataFromPagination}
        refItemParent={depotOffreRef}
        rows={20}
      />
    </div>
  );
};

GestionDepotOffre.propTypes = {
  auth: PropTypes.object,
  handleUpdateOffers: PropTypes.func,
  offers: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch({ type: "UPDATE_OFFERS", value });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GestionDepotOffre);

import React from "react";
import "./btn-rond.scss";
import PropTypes from "prop-types";

const BtnRond = (props) => {
  return (
    <button className="btn-rond" onClick={props.btnAction}>
      {props.children}
    </button>
  );
};

BtnRond.propTypes = {
  btnAction: PropTypes.func,
  btnLogo: PropTypes.object,
  children: PropTypes.any,
};

export default BtnRond;

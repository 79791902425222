import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaPen } from "react-icons/fa";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./Brouillon.scss";
import { connect } from "react-redux";
import { updateUsers } from "Redux/Actions/usersActions";
import { fetchDelete, fetchGet, fetchPost } from "Services/api";
import { showError, showInfo, showWarning } from "Services/functions";

const Brouillon = (props) => {
  // Requête pour obtenir les différents brouillons selon la prop fournie
  // const [drafts, setDrafts] = useState(null);
  // Boolean pour récupérer les brouillons ou non
  const [loadingRequired, setLoadingRequired] = useState(true);
  // Dialog pour afficher l'interface du système
  let [visibleDialog, setVisibleDialog] = useState(false);

  /**
   * Fonction qui permet de vérfier si un brouillon existe déjà ou non
   * @returns {bool} Booléen si brouillon existe en db ou non pour l'user
   */
  const checkExisting = () => {
    if (props.users?.draftData?.data?.length > 0) {
      return props.users.draftData?.data[0];
    } else {
      return false;
    }
  };

  /**
   * Fonction qui récupère les entités depuis l'API en fonction de l'user connecté
   * @param {Object} params - Paramètres de la requête
   */
  const fetchDrafts = async () => {
    // On construit l'URL de la requête en fonction de l'entité
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}${props.path}`);
    url.searchParams.set(
      "state",
      props.states.statesData.filter(
        (etat) => etat.statusLabel === "Brouillon"
      )[0].id
    );
    props.path === "/offers"
      ? url.searchParams.append("createdByUser[]", props.auth.userConnected.id)
      : url.searchParams.append("createdBy[]", props.auth.userConnected.id);

    const res = await fetchGet(url, "none");
    if (res.error) {
      showError("Une erreur est survenue lors de la requête");
      return;
    }
    props.handleUpdateUsers({
      draftData: {
        data: res.data,
        loaded: true,
      },
    });
  };

  /**
   * Fonction qui vérifie le changement de la variable {@link loadingRequired}
   *
   * Si la variable est à true, on appelle la fonction {@link fetchDrafts}
   */
  useEffect(() => {
    if (loadingRequired) {
      fetchDrafts();
      setLoadingRequired(false);
    }
  }, [loadingRequired]);

  /**
   * Fonction qui affiche un toast de type info (couleur bleue)
   * @param {Object} params - Paramètres de l'alerte
   */
  const showToast = (params) => {
    props.auth.toast.current.show(params);
  };

  /**
   * Fonction permettant de remplir un FormData grâce aux donneés fournies.
   *
   *
   * Envoie ensuite la requête.
   * @param {Object} data - Données du brouillon à ajouter
   */
  const postDraft = async (data) => {
    // On crée un FormData qui sera envoyé au serveur
    var formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) formData.append(key, data[key]);
    formData["state"] = JSON.stringify({});

    // On envoie le formData au serveur
    const res = await fetchPost(`${props.path}${props.create}`, formData);
    setLoadingRequired(true);
    if (res.error) {
      showError("Une erreur est survenue lors de la requête");
      return;
    }
    showInfo("Votre brouillon a bien été créé", "Brouillon créé : ");
  };

  /**
   * Fonction permettant de modifier un brouillon existant grâce à un PUT.
   *
   * @param {Object} data - Nouvelles données du brouillon
   * @param {int} id - Id du brouillon à modifier
   */

  const putForm = async (data, id) => {
    if (props.path === "/news") data.links = JSON.stringify(data.links);
    // On crée un FormData qui sera envoyé au serveur
    let formData = new FormData();
    // On ajoute les données du formulaire au formData
    data.contacts = null;
    for (let key in data) {
      if (data[key] !== null) {
        if (typeof data[key] === "object" && data[key]?.length !== 0) {
          formData.append(key, data[key]);
        } else if (
          typeof data[key] === "string" ||
          typeof data[key] === "number" ||
          typeof data[key] === "boolean"
        ) {
          formData.append(key, data[key]);
        }
      }
    }
    // On envoie le formData au serveur
    const res = await fetchPost(`${props.path}/${id}/edit/draft`, formData);
    setLoadingRequired(true);
    if (res.error) {
      showError("Une erreur est survenue lors de la requête");
      return;
    }
    showInfo(
      "Votre brouillon a bien été mis à jour",
      "Brouillon mis à jour : "
    );
  };

  /**
   * Fonction permettant de surpprimer un brouillon existant grâce à un DELETE.
   *
   * @param {int} draftId - Id du brouillon à supprimer
   */
  const deleteDraft = async (draftId) => {
    const res = await fetchDelete(`${props.path}/${draftId}`);
    setLoadingRequired(true);
    if (res.error) {
      showError("Une erreur est survenue lors de la requête");
      return;
    }
    showWarning("Votre brouillon a bien été supprimé", "Brouillon supprimé : ");
  };

  /**
   * Fonction qui va permettre d'appeler la fonction de
   * modification / suppression / ajout ou d'import de brouillon selon
   * le statut rensigné dans la variable {@link status}
   *
   * Ces fonctions vont dépendre de l'entité concernée par la requête
   *
   * @param {Object} status - Statut de la future requête
   */
  async function handleDraft(status) {
    let draft = null;
    draft = checkExisting();
    switch (status) {
      // En cas de 'creation' on appelle la fonction de création de brouillon
      case "creation":
        if (!draft) postDraft(props.setFields(props.formValues()));
        break;
      // En cas de 'deletion' on appelle la fonction de suppression de brouillon
      case "deletion":
        if (draft && draft.id) deleteDraft(draft.id);
        break;
      case "update":
        // En cas de 'update' on appelle la fonction de modification de brouillon
        putForm(props.setFields(props.formValues(), "json"), draft.id);
        break;
      // En cas de 'import' on appelle la fonction d'import de brouillon
      case "import":
        if (draft && draft.id) {
          await props.fillFormFromDraft(draft);
          showToast({
            severity: "info",
            summary: "Brouillon importé",
            detail: "Votre brouillon a bien été importé",
            life: 3000,
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * Affichage du footer du component brouillon, à savoir
   * les boutons de création, modification, suppression et import de brouillon
   *
   * @returns {JSX} - JSX à afficher
   */
  const renderDialogFooter = () => {
    return (
      <>
        {/* Vérification si les brouillons ont été chargé ainsi que la liste des users */}
        {props.users.draftData && props.users.draftData.loaded === true && (
          <>
            {/* Si le brouillon existe, on affiche les boutons de remplacement (modif), de suppression et d'import */}
            {/* Si il n'existe pas, on affiche les boutons de création de brouillon ou d'annulation pour fermer la pop-up */}
            {checkExisting() ? (
              <div className="brouillon__container__dialog-footer">
                <Button
                  label="Supprimer"
                  type="button"
                  className="p-button-sm p-button-rounded p-button-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDraft("deletion");
                    setVisibleDialog(false);
                  }}
                />
                <Button
                  label="Remplacer"
                  type="button"
                  className="p-button-sm p-button-rounded p-button-info"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDraft("update");
                    setVisibleDialog(false);
                  }}
                />
                <Button
                  label="Importer"
                  type="button"
                  className="p-button-sm p-button-rounded p-button-success"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDraft("import");
                    setVisibleDialog(false);
                  }}
                />
              </div>
            ) : (
              <div className="brouillon__container__dialog-footer">
                <Button
                  label="Annuler"
                  type="button"
                  className="p-button-rounded p-button-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibleDialog(false);
                  }}
                />
                <Button
                  label="Créer"
                  type="button"
                  className="p-button-rounded p-button-info"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDraft("creation");
                    setVisibleDialog(false);
                  }}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setVisibleDialog(true);
        }}
        className={props.className}
      >
        <FaPen />
        {props.btnText ? props.btnText : "Brouillon"}
      </button>
      <Dialog
        header="Brouillon"
        visible={visibleDialog}
        modal={false}
        className="brouillon__container"
        onHide={() => setVisibleDialog(false)}
        breakpoints={{ "960px": "100vw" }}
        footer={renderDialogFooter}
      >
        {props.users.draftData && props.users.draftData.loaded === true && (
          <div className="brouillon__container__dialog">
            {checkExisting() ? (
              <p>
                Vous avez déjà un brouillon enregistré. <br /> Souhaitez-vous le
                remplacer par les valeurs actuelles où bien l&apos;importer dans
                le formulaire ?
              </p>
            ) : (
              <p>
                Vous n&apos;avez pas encore de brouillon enregistré. <br />
                Souhaitez-vous en créer un avec les données actuelles ?
              </p>
            )}
          </div>
        )}
      </Dialog>
    </>
  );
};

Brouillon.propTypes = {
  // path - Chemin de la requête
  path: PropTypes.string.isRequired,
  // create - Sous-chemin de la requête pour la création de brouillon
  create: PropTypes.string,
  // setFields - Fonction de remplissage grâce aux données du formulaire, propre à chaque formulaire / entité
  setFields: PropTypes.func.isRequired,
  // formValues - Fonction de récupération des valeurs du formulaire, propre à chaque formulaire / entité
  formValues: PropTypes.any.isRequired,
  // fillFormFromDraft - Fonction de remplissage du formulaire avec les données du brouillon, propre à chaque formulaire / entité
  fillFormFromDraft: PropTypes.func.isRequired,
  auth: PropTypes.object,
  // Texte dans le bouton
  btnText: PropTypes.string,
  className: PropTypes.string,
  users: PropTypes.object,
  handleUpdateUsers: PropTypes.func,
  states: PropTypes.object,
};

Brouillon.defaultProps = {
  create: "",
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Brouillon);

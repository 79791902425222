import ActualitesContainer from "Components/Actualités/ActualitesContainer/ActualitesContainer";
import ChiffreContainer from "Components/Chiffres/ChiffreContainer/ChiffreContainer";
import EvenementsContainer from "Components/Evenements/EvenementsContainer/EvenementsContainer";
import Footer from "Components/Footer/Footer";
import ButtonsBarConnected from "Components/Header/ButtonsBarConnected/ButtonsBarConnected";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import Header from "Components/Header/Header";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import Modal from "Components/Modal/Modal";
import ModalCreationCompte from "Components/Modal/ModalCreationCompte/ModalCreationCompte";
import PresentationRP from "Components/PresentationRP/PresentationRP";
import { updateStates } from "Redux/Actions/statesActions";
import { updateUniversities } from "Redux/Actions/universitiesActions";
import { useFetchGet } from "Services/api";
import useWindowDimensions from "Services/useWindowsDimensions";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./Accueil.scss";

const Accueil = (props) => {
  // récupération des données de l'api pour alimenter la page
  const dataStates = useFetchGet("/states");

  // Par défaut les candidatures sont à null car on ne les récupère que si l'utilisateur est connecté
  // const [candidaturesData, setCandidaturesData] = useState(null);
  // const offersData = useFetchGetPagination(
  //   `${process.env.REACT_APP_BASE_URL_API}/offers?itemsPerPage=4`
  // );
  const dataUniversities = useFetchGet("/universities");

  const { search, state: locationState } = useLocation();
  const navigate = useNavigate();

  // Gestion de l'ouverture des modals login et register
  const [loginModal, setLoginModal] = React.useState(false);
  const [registerModal, setRegisterModal] = React.useState(false);

  useEffect(() => {
    // Si on est déjà log, on arrête là
    if (props.auth.isConnected) return;
    // On parse les search params pour vérifier la présence de 'login' ou 'register'
    const urlSearchParams = new URLSearchParams(search);

    if (urlSearchParams.has("login")) {
      // Ouverture modal login
      setLoginModal(true);
      urlSearchParams.delete("login");
      // Suppression de 'login' de l'url (evite de réouvrir la modal à chaque changement de location)
      navigate(`${location.pathname}?${urlSearchParams.toString()}`, {
        replace: true,
      });
    } else if (urlSearchParams.has("register")) {
      // Ouverture modal register
      setRegisterModal(true);
      urlSearchParams.delete("register");
      // Suppression de 'register' de l'url (evite de réouvrir la modal à chaque changement de location)
      navigate(`${location.pathname}?${urlSearchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location]);

  /**
   * Permet de gérer les différents state de location
   * - toFocus : Permet de scroller jusqu'à un élément (via son id)
   */
  const handleLocationState = () => {
    // Gestion du cas où on a une locationState.toFocus
    if (locationState?.toFocus) {
      // Récupération de l'élément à focus
      const element = document.getElementById(locationState.toFocus);
      // Scroll vers l'élément
      element &&
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      // Suppression de l'élément toFocus de la locationState pour éviter de scroller à chaque changement de locationState
      navigate(location.pathname, { replace: true });
    }
  };

  useEffect(() => {
    handleLocationState();
  }, [locationState]);

  useEffect(() => {
    dataUniversities.loaded &&
      props.handleUpdateUniversities({
        universitiesData: dataUniversities.data,
      });
    dataStates.loaded &&
      props.handleUpdateStates({
        statesData: dataStates.data,
      });
  }, [dataStates.loaded, dataUniversities.loaded]);

  const { width } = useWindowDimensions();

  // useEffect(async () => {
  //   const fetchData = async () => {
  //     const candidatures = await fetchGet("/candidatures");
  //     setCandidaturesData(candidatures.data);
  //   };
  //   props.auth.isConnected && fetchData();
  // }, [props.auth.isConnected]);

  return (
    <div>
      <Header>
        {props.auth.isConnected ? (
          <>
            <h2 className="head__title">
              <span>BIENVENUE SUR VOTRE ESPACE RECRUTEUR ! </span>
            </h2>
            <ButtonsBarConnected />
          </>
        ) : (
          <ButtonsBarDisconnected />
        )}
      </Header>

      {!props.auth.isConnected && <PresentationRP />}
      {!props.auth.isConnected && props.states.statesData.length > 0 && (
        <ChiffreContainer />
      )}

      <div className="accueil">
        {props.auth.isConnected && (
          <>
            {/* <div className="article_candidatures">
              {candidaturesData && offersData?.loaded ? (
                <DernieresCandidaturesRecues
                  title={"LES DERNIÈRES CANDIDATURES REÇUES"}
                  candidatures={candidaturesData || []}
                  offres={offersData.data || []}
                />
              ) : (
                <Loader></Loader>
              )}
            </div> */}
          </>
        )}

        <div className="article_evenements">
          <EvenementsContainer
            pageEvents="accueil"
            nbEvents={width >= 1744 ? 3 : 6}
          />
        </div>
        <div className="article_actualites">
          <ActualitesContainer
            pageActus="accueil"
            nbActus={width >= 1744 ? 3 : 6}
          />
        </div>
      </div>

      <Footer />
      {/* Modals */}
      {registerModal && (
        <Modal
          visible={registerModal}
          setVisible={setRegisterModal}
          header="Création de compte"
          className="modal-register"
        >
          <div className="description">
            <ModalCreationCompte setAuthEntrepriseVisible={setRegisterModal} />
          </div>
        </Modal>
      )}
      <HeaderUserLogin
        loginVisible={loginModal}
        setLoginVisible={setLoginModal}
      />
    </div>
  );
};

Accueil.propTypes = {
  auth: PropTypes.object,
  states: PropTypes.object,
  handleUpdateStates: PropTypes.func,
  handleUpdateUniversities: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateStates: (value) => {
    dispatch(updateStates(value));
  },
  handleUpdateUniversities: (value) => {
    dispatch(updateUniversities(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);

import React, { useState, useEffect, useRef } from "react";
import "./ActualitesContainer.scss";
import Actualite from "../Actualite/Actualite";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { FaChevronRight } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  addDayToDate,
  formatDateDatabase,
  formatDateFrenchLocale,
} from "Services/functions";
import { updateNews } from "Redux/Actions/newsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import { updateAuth } from "Redux/Actions/authActions";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import Filters from "Components/Filters/Filters";
registerLocale("fr", fr);
/**
 * Permet d'afficher les actualités et actus/conseils
 */
const ActualitesContainer = (props) => {
  const navigate = useNavigate();
  const [loginVisible, setLoginVisible] = useState(false);
  const [clickedNewsId, setClickedNewsId] = useState(null);
  const [clickedUniversityId, setClickedUniversityId] = useState(null);
  // Fetch les catégories des actus depuis la BDD
  const categoriesData = useFetchGet("/news_categories");
  const actualitesContainerRef = useRef(null);
  //
  const [categories, setCategories] = useState([]);

  // Les différents filtres de recherche des actualités
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreCategory, setFiltreCategory] = useState(null);
  const [isKeywordFiltering, setIsKeywordFiltering] = useState(false);
  const [newsUrl, setNewsUrl] = useState("");

  const newsRef = useRef(null);

  const newsQuery = useFetchGetPagination(
    props.pageActus === "accueil"
      ? `${process.env.REACT_APP_NEXUS_URL_API}/news/homepage?itemsPerPage=3`
      : newsUrl,
    props.handleUpdateNews
  );

  useEffect(() => {
    return () => {
      props.handleUpdateNews({ dataFromPagination: null });
    };
  }, []);

  useEffect(() => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_NEXUS_URL_API}/news`);
      url.searchParams.set("public", 1);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("order[modifiedAt]", "desc");
      setNewsUrl(url);
    }
  }, [props.states.statesData.length]);

  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (categoriesData.loaded && categoriesData.data.length > 0) {
      setCategories([]);
      let tempArray = [];
      categoriesData.data.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [categoriesData.loaded]);

  // Fonction globale de filtre
  const handleFilter = () => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_NEXUS_URL_API}/news`);
      url.searchParams.set("public", 1);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("order[modifiedAt]", "desc");
      if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
      if (filtreCategory) url.searchParams.set("category", filtreCategory.id);
      if (filtreDate && filtreDate[0])
        url.searchParams.set(
          "modifiedAt[after]",
          formatDateDatabase(filtreDate[0])
        );
      if (filtreDate && filtreDate[1])
        url.searchParams.set(
          "modifiedAt[before]",
          formatDateDatabase(addDayToDate(filtreDate[1]))
        );

      setNewsUrl(url);
    }
  };

  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [filtreCategory, filtreDate, isKeywordFiltering]);
  return (
    <div
      style={{
        backgroundColor:
          props.pageActus == "accueil" ? `backgroundColor : "white"` : "",
      }}
      ref={newsRef}
    >
      <div className="actualites_container">
        <div className="actualites_container__top">
          {props.pageActus == "accueil" ? (
            // si on est dans la page d'accueil on n'affiche pas les filtres
            <h1 className="h1_trait_dessus">
              {props.title ? props.title : "Les actus"}
            </h1>
          ) : (
            <div className="actualites_container__top__title">
              {/* si on est sur la page Actualites on affiche les filtres */}
              <h1 className="h1_trait_dessus">
                {props.title ? props.title : "Toutes les actus"}
              </h1>
              <div className="actualites_container__top__title__filtres__visible">
                <Filters
                  filtreMotsCles={filtreMotsCles}
                  setFiltreMotsCles={setFiltreMotsCles}
                  filtreDate={filtreDate}
                  setFiltreDate={setFiltreDate}
                  filtreDropdown={filtreCategory}
                  setFiltreDropdown={setFiltreCategory}
                  dataDropdown={categories}
                  setIsKeywordFiltering={setIsKeywordFiltering}
                  filterFunction={handleFilter}
                ></Filters>
              </div>
              <Accordion
                className="accordion_actus"
                expandIcon="pi pi-sliders-h"
                collapseIcon="pi pi-sliders-h"
              >
                <AccordionTab header="Filtres de recherche">
                  <Filters
                    filtreMotsCles={filtreMotsCles}
                    setFiltreMotsCles={setFiltreMotsCles}
                    filtreDate={filtreDate}
                    setFiltreDate={setFiltreDate}
                    filtreDropdown={filtreCategory}
                    setFiltreDropdown={setFiltreCategory}
                    dataDropdown={categories}
                    setIsKeywordFiltering={setIsKeywordFiltering}
                    filterFunction={handleFilter}
                  ></Filters>
                </AccordionTab>
              </Accordion>
            </div>
          )}
          {/* peu importe la page on affiche le container avec les actualités */}
          <div
            className="actualites_container__body"
            ref={actualitesContainerRef}
          >
            {newsQuery?.loaded ? (
              newsQuery?.data?.length > 0 ? (
                (props.nbActus && props.nbActus != 12
                  ? newsQuery.data.slice(0, props.nbActus)
                  : newsQuery.data
                ).map((actualite, index) =>
                  props.auth.userConnected || actualite.public ? (
                    <Link
                      to={{
                        pathname: `/actu-detail/${actualite.id}`,
                        params: actualite.id,
                      }}
                      key={index}
                    >
                      <Actualite
                        key={actualite.id}
                        title={actualite.title}
                        chapeau={actualite.chapo}
                        url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                        publicationDate={formatDateFrenchLocale(
                          actualite.modifiedAt?.date || actualite.modifiedAt
                        )}
                        categorie={
                          actualite.newsCategoryLabel ||
                          actualite.category?.label
                        }
                        newsDetails={actualite}
                      />
                    </Link>
                  ) : (
                    <Actualite
                      key={actualite.id}
                      title={actualite.title}
                      chapeau={actualite.chapo}
                      url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                      publicationDate={formatDateFrenchLocale(
                        actualite.modifiedAt?.date || actualite.modifiedAt
                      )}
                      categorie={
                        actualite.newsCategoryLabel || actualite.category?.label
                      }
                      newsDetails={actualite}
                      onClick={() => {
                        setLoginVisible(true);
                        setClickedUniversityId(actualite.university.id);
                        setClickedNewsId(actualite.id);
                      }}
                    />
                  )
                )
              ) : (
                <span style={{ textAlign: "center" }}>
                  {props.pageActus
                    ? "Aucune actualité disponible"
                    : "Aucune actualité ne correspond à votre recherche"}
                </span>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
          {loginVisible && (
            <HeaderUserLogin
              key={loginVisible + clickedNewsId}
              loginVisible={loginVisible}
              setLoginVisible={setLoginVisible}
              redirect={clickedNewsId ? `/actu-detail/${clickedNewsId}` : null}
              universityId={clickedUniversityId}
            />
          )}
        </div>
        <div className="actualites_container__pagination">
          {props.pageActus == "accueil" ? (
            <BtnBleu
              btnTexte="Voir toutes les actus"
              btnAction={() => navigate("/actualites")}
            >
              <FaChevronRight />
            </BtnBleu>
          ) : (
            <TempPagination
              result={newsQuery}
              refItemParent={actualitesContainerRef}
              rows={50}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ActualitesContainer.propTypes = {
  auth: PropTypes.object,
  nbActus: PropTypes.number,
  pageActus: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  title: PropTypes.string,
  news: PropTypes.any,
  handleUpdateNews: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  states: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
  pagination: state.pagination,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => dispatch(updateNews(value)),
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualitesContainer);

import "./OfferCard.scss";
import React from "react";
import PropTypes from "prop-types";
import { FaMapMarkerAlt, FaSuitcase } from "react-icons/fa";
import { Link } from "react-router-dom";

const OfferCard = (props) => {
  return (
    <div className="container_recap">
      <div className="recap_container cardHover">
        <h2 className="h2_title">{props.title}</h2>
        <h3 className="h3_loca">
          <FaMapMarkerAlt /> {props.location}
        </h3>
        <h3 className="h3_contrat">
          <FaSuitcase /> {props.contrat}
        </h3>
        {/* <div className="number_view">
          <h2 className="number">{props.view}</h2>{" "}
          <h3 className="vues">VUES </h3>
        </div> */}
        <div className="divider"></div>
        <div className="number_candidat">
          <h2 className="number_can">{props.candidat}</h2>{" "}
          <h3 className="candidatures">
            {props.candidat > 1 ? "CANDIDATURES" : "CANDIDATURE"}{" "}
          </h3>
        </div>
      </div>
      <Link
        to={{ pathname: `/candidatures-profil/${props.id}` }}
        state={{ offerId: props.id }}
        className="link_container"
      >
        <button className="btn_candidatures">Consulter les candidatures</button>
      </Link>
    </div>
  );
};

OfferCard.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  contrat: PropTypes.string,
  view: PropTypes.number,
  candidat: PropTypes.number,
  id: PropTypes.number,
};

export default OfferCard;
